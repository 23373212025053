import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import logo from "../../../assets/img/No data-pana-min.png";
import referralCheckouts from "../../../models/referralCheckouts";

const ReferralCheckouts = () => {

    const {referrallCheckouts, loading} = useAppSelector((state: RootState) => state.referrall);

    return (
        <div className="flex-auto lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3 pt-0 pb-2">

            <div
                className="py-4 pb-0 mb-2 mt-14 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>درخواست های هدیه</span>

                </div>

            </div>

            <div className={'overflow-x-auto w-full'}>
                <table
                    className="items-center w-full mt-[76px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                            و نام خانوادگی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            موبایل
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            تعداد ثبت نام ها
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            تعداد ثبت نام های مورد نیاز
                        </th>
                    </tr>
                    </thead>
                    {

                        <tbody className={''}>
                        {
                            referrallCheckouts.map((contact, i) => <tr key={contact.id}
                                                                   className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} `}>
                                <td className="px-6 py-6 font-semibold text-start  align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                    {contact.user.f_name + ' ' + contact.user.l_name}
                                </td>
                                <td className="px-6 py-6 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{contact.user.mobile_number}</td>
                                <td className="px-6 py-6 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{contact.referral_inv_count}</td>
                                <td className="px-6 py-6 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{contact.shop_count}</td>



                            </tr>)}

                        </tbody>
                    }

                </table>
                {
                    referrallCheckouts.length === 0 && !loading ?
                        <div className={'w-full flex flex-col justify-center items-center'}>
                            <img src={logo} alt="" className={'w-[30%]'}/>
                            <span className={'text-lg'}>درخواستی وجود ندارد</span>
                        </div> : <div></div>
                }
            </div>

        </div>
    );
};

export default ReferralCheckouts;
