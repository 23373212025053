import React from 'react';
import {DefaultBooleanField, DefaultInput, DefaultSelect} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {
    FormQuestionCredentials,
    FormQuestionUpdateCredentials,
    update
} from "../../../redux/dashboard/formQuestions/formQuestionsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import FormQuestion from "../../../models/formQuestion";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";

const EditFormQuestionModal: React.FC<{ formQuestion: FormQuestion | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            formQuestion,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, formQuestions} = useAppSelector((state: RootState) => state.formQuestions);

    const categories = useAppSelector((state: RootState) => state.adminCategories.categories);
    const products = useAppSelector((state: RootState) => state.adminProducts.products);

    //edit
    const handleEditConfirm = async (formQuestion: FormQuestionUpdateCredentials) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(formQuestion))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
            <DefaultModal
                label={'editFormQuestion'}  id='editFormQuestion' isOpen={!!formQuestion} handleIsOpen={onClose} modalBoxClasses={'px-3'}>
                {formQuestion ? (
                    <Formik
                        initialValues={{
                            id: formQuestion.id,
                            title: formQuestion.title,
                            status: true,
                        }}
                        onSubmit={(values: FormQuestionUpdateCredentials, {setSubmitting}: FormikHelpers<FormQuestionUpdateCredentials>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm(values)
                                );

                                (document.querySelector("[for='editFormQuestion']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: FormQuestionUpdateCredentials) => {
                            const errors: any = {};

                            if (!values.title) {
                                errors.title = 'عنوان فرم را وارد کنید.';
                            }
                            return errors;

                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editFormQuestion'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start md:mt-4  mt-12 items-center w-full flex'>

                                <DefaultInput
                                    label='عنوان فرم سوال'
                                    name="title"
                                    type="text"
                                    placeholder='عنوان فرم سوال'
                                ></DefaultInput>

                                {/*<DefaultBooleanField*/}
                                {/*    placeholder='آیا فرم فعال است؟'*/}
                                {/*    name="status"*/}
                                {/*></DefaultBooleanField>*/}
                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editFormQuestion"
                                    className="btn hover:text-[#DE0046] bg-[#DE0046]  hover:bg-[#DE004633] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditFormQuestionModal;
