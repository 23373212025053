import React from 'react';
import {DefaultInput} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {submit} from "../../../redux/dashboard/shopPlans/shopPlansAction";


interface values {
    planName: string,
    name: string;
    description: string;
    price: number;
    end_time: number;
    readMoreTitle: string;
}


const AddShopPlan = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, plans} = useAppSelector((state: RootState) => state.shopPlans);
    //attributes
    const [attributes, setAttributes] = React.useState<{title: string, is_read_more: boolean}[]>([]);
    const [isAddingMoreOptions, setIsAddingMoreOptions] = React.useState<boolean>(false);
    //adding

    //delete attribute
    const handleDeleteAttribute = (index: number) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes);
    }
    //add attribute
    const handleAddAttribute = (is_read_more: boolean) => {
        setAttributes([...attributes, {title: '', is_read_more}]);
    }
    //change attribute
    const handleChangeAttribute = (index: number, value: string) => {
        const newAttributes = [...attributes];
        newAttributes[index].title = value;
        setAttributes(newAttributes);
    }

    return (
        <DefaultModal
            label={'addPlan'}  modalBoxClasses={`max-w-3xl px-3`} id='addPlan'>
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    price: 0,
                    end_time: 0,
                    planName: '',
                    readMoreTitle: ''
                }}
                onSubmit={
                    (values: values, {setSubmitting}: FormikHelpers<values>) => {
                        try {
                            dispatch(submit(
                                {
                                    name: values.name,
                                    description: values.description,
                                    price: values.price,
                                    properties: attributes,
                                    end_time: values.end_time,
                                    plan_name: values.planName,
                                    id: plans.length + 1,
                                    read_more_title: values.readMoreTitle,
                                }
                            ));

                            (document.querySelector("[for='addPlan']") as HTMLElement).click();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.name) {
                        errors.title = 'نام پلن را وارد کنید';
                    }
                    if (!values.description
                    ) {
                        errors.description = 'توضیحات را وارد کنید';
                    }
                    if (!values.price) {
                        errors.price = 'قیمت پلن را وارد کنید';
                    }
                    if (!values.end_time) {
                        errors.end_time = 'ماه را وارد کنید';
                    }
                    return errors;
                }}>
                <Form method="dialog transition">
                    <h3 className="font-bold text-lg">افزودن</h3>

                    <div className='flex-col justify-start items-center w-full mt-12 flex lg:grid lg:grid-cols-2 field-container'>

                        <DefaultInput
                            label='نام پلن'
                            name="planName"
                            type="text"
                            placeholder='نام پلن'
                        ></DefaultInput>
                        <DefaultInput
                            label='عنوان'
                            name="name"
                            type="text"
                            placeholder='عنوان'
                        ></DefaultInput>
                        <DefaultInput
                            label='زمان پلن(ماه)'
                            name="end_time"
                            type="text"
                            placeholder='زمان پلن(ماه)'
                        ></DefaultInput>

                        <DefaultInput
                            label='توضیحات'
                            name="description"
                            type="text"
                            placeholder='توضیحات'
                        ></DefaultInput>
                        <DefaultInput
                            label='قیمت'
                            name="price"
                            type="number"
                            placeholder='قیمت'
                        ></DefaultInput>

                    {/*    attributes*/}
                    {/*    divider*/}
                    {/*    <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>*/}


                    </div>

                    <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                    <div className="w-full ">
                        <h3 className="font-bold text-lg">ویژگی ها</h3>
                        <div className="flex flex-col w-full lg:grid lg:grid-cols-2 field-container items-end">
                            {
                                attributes.map((attribute, index) =>
                                    attribute.is_read_more ? '' :
                                    <div key={index}
                                        className="join">
                                        <div className={`join-item w-full`}>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={attribute.title}
                                                    onChange={(e) => handleChangeAttribute(index, e.target.value)}
                                                    className="input input-bordered join-item max-w-unset w-full" placeholder="ویژگی"/>
                                            </div>
                                        </div>
                                        <div className="indicator"
                                             onClick={() => handleDeleteAttribute(index)}>
                                            <div className="btn join-item text-white bg-[#DE0046]
                                            ">حذف</div>
                                        </div>
                                    </div>
                                )
                            }`
                            <div onClick={() => handleAddAttribute(false)}
                                    className="btn text-white bg-[#4192EF] mt-4">افزودن ویژگی
                            </div>
                        </div>
                    </div>
                    {/*add an button to switch isAddingMoreOptionsTrue if isAddingMoreOptionsTrue is true user add more attributes and more title*/}
                    {/*button is like a link button by an svg icon cupertino arrow*/}
                    <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                    <div className="w-full flex justify-start">
                        <div className="flex items-center text-[#4192EF] cursor-pointer">
                            <div onClick={() => setIsAddingMoreOptions(!isAddingMoreOptions)}
                                    className="">افزودن اطلاعات بیشتر
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className={`transition icon icon-tabler icon-tabler-chevron-down ml-2 ${isAddingMoreOptions ? 'transform rotate-180' : ''}`}
                                 width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="6 9 12 15 18 9"/>
                            </svg>
                        </div>
                    </div>
                    {
                        isAddingMoreOptions &&
                        <div className="w-full flex justify-start">
                            <DefaultInput
                                label='عنوان خواندن بیشتر'
                                name="readMoreTitle"
                                type="text"
                                placeholder='عنوان خواندن بیشتر'
                            ></DefaultInput>
                        </div>
                    }
                    {
                        isAddingMoreOptions &&
                        <div className="w-full ">
                            <h3 className="font-bold text-lg">ویژگی ها</h3>
                            <div className="flex flex-col w-full lg:grid lg:grid-cols-2 field-container items-end">
                                {
                                    attributes.map((attribute, index) =>
                                        attribute.is_read_more ? <div key={index}
                                            className="join">
                                            <div className={`join-item w-full`}>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={attribute.title}
                                                        onChange={(e) => handleChangeAttribute(index, e.target.value)}
                                                        className="input input-bordered join-item max-w-unset w-full" placeholder="ویژگی"/>
                                                </div>
                                            </div>
                                            <div className="indicator"
                                                 onClick={() => handleDeleteAttribute(index)}>
                                                <div className="btn join-item text-white bg-[#DE0046]
                                            ">حذف</div>
                                            </div>
                                        </div> : ''
                                    )
                                }
                                <div onClick={() => handleAddAttribute(true)}
                                        className="btn text-white bg-[#4192EF] mt-4">افزودن ویژگی
                                </div>
                            </div>
                        </div>
                    }

                    {/*divider*/}
                    <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                    <div className="modal-action w-full flex justify-start">

                        <button type="submit"
                                className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                submitLoading ? 'صبر کنید' : 'افزودن '
                            }                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addPlan"
                            className="btn text-white rounded-xl hover:scale-102 hover:shadow-soft-xs active:opacity-85 bg-[#DE0046]">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddShopPlan;
