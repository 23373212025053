import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DefaultStates} from "redux/mainSlice";
import {LaravelValidationErrorType} from "../../../utils/types";
import {blockUserFromShop, getAllUsersForSingleMainPage} from "redux/dashboard/users/UsersAction";
import User from "../../../models/user";
import {toastSuccess} from "../../../utils/funcions";
import DiscountCode from "../../../models/discountCode";

interface State extends DefaultStates {
    mainUsers: User[];
    mainUsersList: User[][],
    submitLoading: boolean;
    paginate: number;
    catId: number | null;
    loadingMore: boolean,
    isEnd: boolean,
}

const initialState: State = {
    mainUsers: [],
    mainUsersList: [],
    submitLoading: false,
    loading: false,
    paginate: 1,
    catId: null,
    loadingMore: false,
    validationErrors: null,
    isEnd: false,
};
const MainUsersSlice = createSlice({
    name: 'mainUsers',
    initialState,

    reducers: {
        setPaginate: (state) => {
            state.paginate = state.paginate + 1; // Update loadingMore state
        },

        setPaginateToStart: (state) => {
            state.paginate = 1; // Update loadingMore state
        },

        setAllStatesToTrue: (state) => {
            state.isEnd = false;
            state.paginate = 1;
            state.loadingMore = false;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllUsersForSingleMainPage.pending, (state) => {
            if (state.paginate === 1) {
                state.loading = true;
            }else {
                state.loadingMore = true;
            }
        })
        builder.addCase(getAllUsersForSingleMainPage.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            const response = action.payload;
            if (response.status === 200) {
                const users: User[] = [];
                    const title = response.data[1]
                for (let i = 0; i < response.data[0].data.length; i++) {
                    const contact = response.data[0].data[i];
                    users.push(new User({
                        l_name: contact.l_name,
                        banner: contact.banner,
                        avatar: contact.avatar,
                        description: contact.description,
                        email: contact.email,
                        f_name: contact.f_name,
                        isReseller: contact.isReseller,
                        is_blocked: contact.is_blocked,
                        user_type: contact.user_type,
                        mobile_number: contact.mobile_number,
                        username: contact.username,
                        video: contact.video,
                        wallet_amount: contact.walletAmount,
                        id: contact.id,
                        domain: contact.domain,
                        payments_count: contact.payments_count,
                        created_at: contact.created_at,
                    }));
                }


                if (response.data[0].data.length === 0 || response.data[0].data.length < 30) {
                    state.isEnd = true;
                }
                if (title && (title.title || title.price || title.product || title.category)) {
                    state.mainUsersList = [users];
                } else if (state.paginate === 1) {
                    state.mainUsersList = [users];
                } else {
                    state.mainUsersList.push(users);
                }

                // if (response.data[0].data.length == 0) {
                //     state.isEnd = true
                // }
                // if (!title) {
                //     state.mainUsersList.push(users);
                // }
                // state.mainUsers = users
                // if (state.paginate == 1) {
                //     state.mainUsersList = [users];
                // }else {
                //     state.mainUsersList.push(users);
                // }

            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getAllUsersForSingleMainPage.rejected, (state, action) => {
                state.loading = false;
            }
        )
            .addCase("setLoadingMore/pending", (state) => {
                state.loadingMore = true;
            })
            .addCase("setLoadingMore/fulfilled", (state) => {
                state.loadingMore = false;
            });

        builder.addCase(blockUserFromShop.pending, (state) => {
            state.submitLoading = true;
        });
        builder.addCase(blockUserFromShop.fulfilled, (state, action: PayloadAction<any>) => {
            state.submitLoading = false;
            const response = action.payload;

            if (response.status === 200) {
                state.mainUsersList.forEach((contactList) => {
                    const index = contactList.findIndex(contact => contact.id === response.data.data.id);
                    contactList[index] = new User(response.data.data);
                })
                    toastSuccess('وضعیت با موفقیت تغییر کرد');

                } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;

        }});

        builder.addCase(blockUserFromShop.rejected, (state) => {
            state.submitLoading = false;
        });


    },
});
export const {setPaginate, setPaginateToStart, setAllStatesToTrue}  = MainUsersSlice.actions;

export default MainUsersSlice.reducer;