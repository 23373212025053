import {createAsyncThunk, ThunkDispatch} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ProductRequests from "../../../requests/productRequests";
import {MediaType} from "../../../models/media";
import store from "redux/store";
import {remProgress, setProgress} from "../../tools/progressSlice";
import HighLightRequests from "../../../requests/highLightRequests";
import Category from "../../../models/category";
import CategoryRequests from "../../../requests/categoryRequests";
import {parseNumber} from "react-advanced-cropper";
import Store from "redux/store";
import UserRequests from "../../../requests/userRequests";

export interface AttributesProperty {
    name: string;
    key: string;
    value: string;
    quantity: string;

}
export interface ProductCredentials {
    title: string,
    categoryId?: number,
    category?: Category | string,
    description: string,
    price: number,
    old_price?: number,
    buy_price?: number,
    quantity: number,
    high_light_id?: string | null,
    mediaUrls: File[],
    mediaTypes: MediaType[],
    coverUrls: File[] | null | string,
    coverTypes: string | null,
    key?: string | null,
    value?: string | null,
    is_product_active: boolean,
    inventory_warning?: boolean,
    zero_inventory?: boolean,
    // attributes?: AttributesProperty[],
}

export interface ProductCreateCredentials extends ProductCredentials {
}

export interface ProductUpdateCredentials extends ProductCredentials {
    id: number,
    deleteMediaIds: number[],
}

export const submit = createAsyncThunk(
    'products/post',
    async (credentials: ProductCreateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ProductRequests.create(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            if (response.status === 200 || response.status === 201) {
                const data = response.data;
                return {status: response.status, data}
            } else if (response.status === 422) {
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
            return {
                status: 200,
                data: null
            }
        } catch (e) {
            // toastError();
            store.dispatch(remProgress());
            return {
                status: 500,
                data: null
            }
        }
    }
)
export const getProduct = createAsyncThunk(
    'product/getAll',
    async (data: {[key: string]: any}): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await ProductRequests.getProduct(parseNumber(data.id))
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {

            return {
                status: 500,
                data: null
            }
        }
    }
)
export interface SearchProductsCredentials {
    title: string | null,
    minPrice: string | null,
    maxPrice: string | null,
    status: string | null,
}
export const getAll = createAsyncThunk(
    'products/getAll',
    async (initial: {isReturnCache?: boolean, credentials?: SearchProductsCredentials}): Promise<DefaultResponse> => {
        try {
            // console.log('productswww', initial)
            const response: AxiosResponse = await ProductRequests.getAllProducts(store.getState().adminProducts.paginate, initial.isReturnCache ?? false, initial.credentials ?? {} as SearchProductsCredentials)

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

//remove
export const remove = createAsyncThunk(
    'products/remove',
    async (id: number): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ProductRequests.remove(id)
            if (response.status === 200) {
                return {status: response.status, data: id}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)


//update
export const update = createAsyncThunk(
    'products/update',
    async (credentials: ProductUpdateCredentials): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ProductRequests.update(credentials, (percentage: number) => {
                store.dispatch(setProgress(percentage));
            });
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            } else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            } else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        } catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
