import React from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import MediaSelect, {handleMediaSelection} from "../../../utils/media/media_select";
import {getMediaTypes, MediaType} from "../../../models/media";
import {
    defaultModal,
    getReplacementAvatar,
    getReplacementNullAvatar,
    NetworkImage,
    toastError
} from "../../../utils/funcions";
import {ProductCreateCredentials, submit} from "../../../redux/dashboard/products/productsAction";
import {BiEditAlt, BiRightArrow, BiRightArrowAlt} from "react-icons/bi";
import {IconInfoSquare} from "@tabler/icons-react";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {ShopCreateCredentials, submitShop} from "../../../redux/dashboard/addShop/addShopAction";
interface values {
    title: string;
    description: string;
    price: number;
    categoryId: number;
    highLightId: string;
    quantity: number;
    unique_name: string;
    password: string;
    // highLight: HighLight;
}

interface valuesShop {
    username: string,
    f_name: string,
    l_name: string,
    mobile_number: string,
    // is_super_admin: boolean,
    domain: string,
    plan_id: number,
    unique_name: string,
    password: string,
}

const AddShopModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminProducts);
    const categories = useAppSelector((state: RootState) => state.adminCategories.categories);
    const {plans} = useAppSelector((state: RootState) => state.plans);

    return (
        <DefaultModal
            label={'addShop'}  id='addShop' modalBoxClasses={`md:!max-w-3xl md:!w-max px-3 lg:min-w-31/100 md:h-max h-full overflow-x-hidden`}>
            <label className={'flex cursor-pointer w-max'} htmlFor={'addShop'}>
                <BiRightArrowAlt size={26}/>
                <span>بازگشت</span>
            </label>
            <Formik
                initialValues={{
                    username: '',
                    f_name: '',
                    l_name: '',
                    mobile_number: '',
                    is_super_admin: true,
                    // user_type: 'shop',
                    domain: '',
                    plan_id: 0,
                    unique_name: '',
                    password: '',
                }}
                // @ts-ignore
                onSubmit={

                    (valuesShop: valuesShop, {setSubmitting}: FormikHelpers<valuesShop>) => {

                        try {
                            const credentials: ShopCreateCredentials = {
                                ...valuesShop,
                            };
                            dispatch(submitShop(credentials));
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: valuesShop) => {
                    const errors: any = {};
                    if (!values.username) {
                        errors.username = 'نام کاربری را وارد کنید';
                    }
                    if (!values.f_name) {
                        errors.f_name = 'نام را وارد کنید';
                    }
                    if (!values.l_name) {
                        errors.l_name = 'نام خانوادگی را انتخاب کنید';
                    }
                    // if (!values.user_type) {
                    //     errors.user_type = 'نوع کاربر را وارد کنید';
                    // }
                    if (!values.plan_id) {
                        errors.plan_id = 'تعرفه را وارد کنید';
                    }
                    if (!values.mobile_number) {
                        errors.mobile_number = 'شماره موبایل را وارد کنید';
                    }
                    if (!values.domain) {
                        errors.domain = 'دامنه وبسایت را وارد کنید';
                    }
                    if (!values.unique_name) {
                        errors.unique_name = 'نام یونیک را وارد کنید';
                    }
                    // if (!media) {
                    //     toastError('لطفا یک رسانه انتخاب کنید')
                    // }
                    return errors;
                }}>
                <div className={'md:h-max h-full w-full items-center flex'}>

                    <Form method="dialog" className={'w-full h-full'}>
                        <h3 className="font-bold text-lg flex justify-center">افزودن فروشگاه جدید</h3>
                        <div className="flex md:flex-row justify-around flex-col">

                                <div className="md:w-1/2">
                                    <div className='flex-wrap justify-start items-center w-full flex'>

                                        <DefaultInput
                                            label='نام کاربری'
                                            name="username"
                                            type="text"
                                            placeholder='نام کاربری'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='نام یونیک'
                                            name="unique_name"
                                            type="text"
                                            placeholder='نام یونیک'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='نام '
                                            name="f_name"
                                            type="text"
                                            placeholder='نام '
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='نام خانوادگی'
                                            name="l_name"
                                            type="text"
                                            placeholder='نام خانوادگی'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='شماره موبایل'
                                            name="mobile_number"
                                            type="text"
                                            placeholder='شماره موبایل'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='رمز عبور'
                                            name="password"
                                            type="text"
                                            placeholder='رمز عبور'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='دامنه وبسایت'
                                            name="domain"
                                            type="text"
                                            placeholder='دامنه وبسایت'
                                        ></DefaultInput>
                                        {/*<DefaultInput*/}
                                        {/*    label='زمان پلن(ماه)'*/}
                                        {/*    name="domain"*/}
                                        {/*    type="text"*/}
                                        {/*    placeholder='دامنه وبسایت'*/}
                                        {/*></DefaultInput>*/}

                                        <DefaultSelect
                                            label='نوع تعرفه'
                                            name="plan_id"
                                            placeholder='نوع تعرفه'
                                            options={
                                            plans == null ? <div>

                                            </div>: plans.map((category) => {
                                                return {
                                                    label: category.title,
                                                    value: category.id
                                                }
                                            }) as any
                                            }
                                        ></DefaultSelect>


                                    </div>
                                    <div className="modal-action w-full flex justify-start">

                                        <label
                                            htmlFor="addShop"
                                            className="btn bg-[#DE0046] rounded-xl text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                        </label>
                                        <div className={'w-0.5 h-2'}></div>
                                        <button type="submit"
                                                className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                            {
                                                submitLoading ? 'صبر کنید' : 'افزودن '
                                            }
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </Form>
                </div>
            </Formik>
        </DefaultModal>
    );
};

export default AddShopModal;
