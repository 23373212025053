import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import UserRequests from "../../../requests/userRequests";

export interface UpdateUserBySuperAdminCredentials {
    mobile_number: string,
}

export interface UpdateUserBySuperAdminCredentialsInterface extends UpdateUserBySuperAdminCredentials {
}

//update
export const updateUserBySuperAdmin = createAsyncThunk(
    'userBySuperAdmin/update',
    async (credentials: UpdateUserBySuperAdminCredentialsInterface): Promise<DefaultResponse>=> {
        try {
            const response: AxiosResponse = await UserRequests.updateUser(credentials)
            if (response.status === 200) {
                return {status: response.status, data: response.data.data}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)