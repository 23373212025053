import React from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {update} from "../../../redux/dashboard/customers/customersAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Customer, {DiscountType} from "../../../models/customer";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";


interface values {

    username: string;
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
    email: string;
    avatar: string;
    is_from_shop: true;
    is_reseller: false;
}


const EditCustomerModal: React.FC<{ customer: Customer | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            customer,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, customers} = useAppSelector((state: RootState) => state.adminCustomers);

    //edit
    const handleEditConfirm = async (customer: Customer) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(customer))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    return (
            <DefaultModal id='editCustomer' isOpen={!!customer} handleIsOpen={onClose}>
                {customer ? (
                    <Formik
                        initialValues={{
                            f_name: customer.f_name,
                            l_name: customer.l_name,
                            mobile_number: customer.mobile_number,
                            avatar: customer.avatar,
                            username: customer.username,
                            description: customer.description,
                            email: customer.email,
                            is_from_shop: customer.is_from_shop,
                            is_reseller: false,
                        }}
                        onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm({...values, id: customer.id})
                                );
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: values) => {
                            const errors: any = {};

                            if (!values.f_name) {
                                errors.l_name = 'نام را وارد کنید';
                            }
                            if (!values.l_name) {
                                errors.l_name = 'نام خانوادگی را وارد کنید';
                            }
                            if (!values.mobile_number) {
                                errors.mobile_number = 'شماره موبایل را وارد کنید';
                            }

                            return errors;
                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editCustomer'}
                                   className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start mt-4 items-center w-full flex'>


                                <DefaultInput label='نام'
                                              type='string'
                                              className="" name='f_name' placeholder='نام'/>
                                <div className="w-5"></div>
                                <DefaultInput label='نام خانوادگی'
                                              type='string'
                                              className="" name='l_name' placeholder='نام خانوادگی'/>
                            </div>
                            {/*<DefaultInput label='نام کاربری'*/}
                            {/*    type='string'*/}
                            {/*    className="" name='username' placeholder='نام کاربری'/>*/}
                            <DefaultInput label='شماره تلفن'
                                          type='string'
                                          className="" name='mobile_number' placeholder='شماره تلفن'/>
                            <DefaultInput label='ایمیل'
                                          type='string'
                                          className="" name='email' placeholder='ایمیل'/>
                            <DefaultTextArea label='توضیحات'
                                             type='string'
                                             className="mb-4" name='description' placeholder='توضیحات'/>

                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editCustomer"
                                    className="btn hover:text-[#DE0046] bg-[#DE0046]  hover:bg-[#DE004633] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditCustomerModal;
