import React, {useEffect, useRef, useState} from "react";
import {AiOutlineDollar} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import store, {RootState} from "../../../redux/store";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import {getSettingByKey, moneyFormat, pagination} from "../../../utils/funcions";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowDown, IconArrowRight, IconChevronDown, IconFilter, IconSearch, IconX} from "@tabler/icons-react";
import {getAllResellers} from "../../../redux/dashboard/reseller/resellerAction";
import moment, {min, Moment} from 'jalali-moment'
import {setPaginate, setAllStatesToTrue, setPaginateToStart} from "../../../redux/dashboard/reseller/resellerSlice";
import ResellerChartPartial from "./resellerChartPartial";
import {useAppSelector} from "../../../redux/hooks";
import DatePicker from "react-datepicker2";
import {getAll} from "../../../redux/dashboard/discountCodes/discountCodesAction";
import logo from "../../../assets/img/No data-pana-min.png";

export const ResellerPaymentsInfo: React.FC = () => {

    const { resellerPaymentsList, paginate, loading } = useSelector((state: RootState) => state.reseller);
    const { shop, user, settings} = useAppSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    // const { loading, isEnd } = useSelector((state: RootState) => state.reseller);
    const lastSearchTimeRef = useRef(Date.now());

    const [title, setTitle] = useState<string>('');
    const [product, setProduct] = useState<string>('');
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [category, setCategory] = useState<string>('');
    const [copy, setCopy] = useState<boolean>(true);

    const handleDeleteMinPrice = () => {
        setMinPrice("");
    };


    const handleDeleteMaxPrice = () => {
        setMaxPrice("");
    };

    const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null);
    const [purchaseEndDate, setPurchaseEndDate] = useState<Moment | null>(null);
    const handleSearch = async () => {
        const currentTime = Date.now();
        if (currentTime - lastSearchTimeRef.current >= 2000) {

            let formattedDate: string | null = null;
            let formattedEndDate: string | null = null;

            if (purchaseDate) {
                formattedDate = purchaseDate.format('YYYY-MM-DD');
            }
            if (purchaseEndDate) {
                formattedEndDate = purchaseEndDate.format('YYYY-MM-DD');
            }
            const data = { title, minPrice, maxPrice, product, category, purchaseDate: formattedDate, purchaseEndDate: formattedEndDate };
            dispatch(setPaginateToStart());
            dispatch(setAllStatesToTrue());
            try {
                //@ts-ignore
                dispatch(getAllResellers(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }
            lastSearchTimeRef.current = currentTime;
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };
    const handleDeleteSearch = () => {
        setTitle(""); // Clear title
    };

    useEffect(() => {
        const data = { title, minPrice, maxPrice };


        const handlePagination = () => {
            if (!store.getState().reseller.loading &&
                !store.getState().reseller.loadingMore &&
                !store.getState().reseller.isEnd) {
                dispatch(setPaginate());
                //@ts-ignore
                dispatch(getAllResellers(data));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [title, minPrice, maxPrice]);

    useEffect(() => {
        setPaginateToStart();
    }, [dispatch]);

    // const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };

    const handleDeleteStartDate = () => {
        setPurchaseDate(null);
    };
    const handleDeleteEndDate = () => {
        setPurchaseEndDate(null);
    };

    return (
        <div className={'flex flex-col pt-4 md:mb-0 mb-20'}>
            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 px-3 z-50 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>ریسلر</span>
            </Link>
            <ResellerChartPartial/>

            <div
                className="py-4 px-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0'}>پرداخت های ریسلر</span>
                    {
                        <div className={'flex justify-end'}>
                            <div onClick={() => handleShowFilter()}
                                 className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none ml-2 px-3 rounded-xl text-white">
                                <span className='text-[15px]'>فیلتر ها</span>
                                {
                                    <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                                }
                            </div>
                        </div>
                    }
                </div>

            </div>

            {
                <div className={'flex flex-col items-start w-full'}>
                    <div
                        className={`w-full duration-300 mt-6 md:pl-6 px-3 overflow-hidden ${copy ? 'h-0' : 'md:h-16 h-48'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                        <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="search"
                                    placeholder="جستجو براساس نام"
                                    value={title}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                                />
                                {title ? (
                                    <button
                                        onClick={handleDeleteSearch}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </label>
                        <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="search"
                                    placeholder="حداقل قیمت خرید"
                                    value={minPrice}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    onChange={(e) => setMinPrice(e.target.value)}
                                    className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                                />
                                {minPrice ? (
                                    <button
                                        onClick={handleDeleteMinPrice}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </label>

                        <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                            <div className="relative w-full">
                                <input
                                    type="search"
                                    placeholder="حداکثر قیمت خرید"
                                    value={maxPrice}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                                />
                                {maxPrice ? (
                                    <button
                                        onClick={handleDeleteMaxPrice}
                                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX/>
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                                )}
                            </div>
                        </label>
                        {/*<label className="input input-bordered flex items-center p-1">*/}
                        {/*    <div className="relative w-full z-[1000000000]">*/}
                        {/*        {purchaseDate === null ? (*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                placeholder="تاریخ شروع"*/}
                        {/*                onFocus={() => setPurchaseDate(moment())} // or open DatePicker*/}
                        {/*                className="input placeholder:text-[13px] border-none pl-8 input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"*/}
                        {/*            />*/}
                        {/*        ) : (*/}
                        {/*            <DatePicker*/}
                        {/*                isGregorian={false}*/}
                        {/*                value={purchaseDate}*/}
                        {/*                onChange={setPurchaseDate}*/}
                        {/*                timePicker={false}*/}
                        {/*                inputJalaaliFormat="jYYYY/jMM/jDD"*/}
                        {/*            />*/}
                        {/*        )}*/}
                        {/*        {*/}
                        {/*            purchaseDate ? <button*/}
                        {/*                    onClick={handleDeleteStartDate}*/}
                        {/*                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                        {/*                >*/}
                        {/*                    <IconX size="24"/>*/}
                        {/*                </button> :*/}
                        {/*                <button*/}
                        {/*                    onClick={handleSearch}*/}
                        {/*                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                        {/*                >*/}
                        {/*                    <IconSearch size="24"/>*/}
                        {/*                </button>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</label>*/}
                        {/*<label className="input input-bordered flex items-center p-1">*/}
                        {/*    <div className="relative w-full z-[1000000000]">*/}
                        {/*        {purchaseEndDate === null ? (*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                placeholder="تاریخ پایان"*/}
                        {/*                onFocus={() => setPurchaseEndDate(moment())} // or open DatePicker*/}
                        {/*                className="input placeholder:text-[13px] border-none pl-8 input-primary max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"*/}
                        {/*            />*/}
                        {/*        ) : (*/}
                        {/*            <DatePicker*/}
                        {/*                isGregorian={false}*/}
                        {/*                value={purchaseEndDate}*/}
                        {/*                onChange={setPurchaseEndDate}*/}
                        {/*                timePicker={false}*/}
                        {/*                inputJalaaliFormat="jYYYY/jMM/jDD"*/}
                        {/*            />*/}
                        {/*        )}*/}
                        {/*        {*/}
                        {/*            purchaseEndDate ? <button*/}
                        {/*                    onClick={handleDeleteEndDate}*/}
                        {/*                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                        {/*                >*/}
                        {/*                    <IconX size="24"/>*/}
                        {/*                </button> :*/}
                        {/*                <button*/}
                        {/*                    onClick={handleSearch}*/}
                        {/*                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                        {/*                >*/}
                        {/*                    <IconSearch size="24"/>*/}
                        {/*                </button>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</label>*/}
                        {/* Add more labels here as needed */}
                    </div>

                    <label className={`${copy ? 'h-0' : 'h-16'} pr-3 flex items-center w-max`}>
                        <button onClick={handleSearch}
                                className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-xl hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                            <IconFilter
                                className={'group-hover:w-6 duration-300 w-0'}/>
                            <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                        </button>
                    </label>
                </div>
            }

            {
                loading ? <div><LoadingTableShimmer/></div> :

                    <>


                        {
                            loading ?
                                <div className={'flex justify-center items-center w-full'}>

                                </div> : <div className={'mt-4'}>


                                    <div>
                                        <div className={'w-full bg-[#F7F7F7] h-2'}></div>
                                        {
                                            // @ts-ignore
                                            user!.id === shop!.id ? resellerPaymentsList.map(resellerPayments => resellerPayments.map((e) =>

                                                <div
                                                    className={'py-3 px-4 flex justify-between w-full items-start border-b border-b-[#F7F7F7]'}>
                                                    <div className={'flex flex-col'}>
                                                        <span
                                                            className={'text-[14px]'}>{
                                                            e.full_name == null ? e.f_name : e.full_name}</span>

                                                        <span
                                                            className={'text-[12px] text-[#737373] font-bold'}>{
                                                            moneyFormat(parseInt(e.final_price!))} ریال </span>

                                                        <div
                                                            className='text-[14px] mt-1 text-[#737373] font-normal '>

                                                            <p>{moment(e.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={'py-[2px] px-2 flex justify-center items-center bg-[#0B8D370D] rounded-xl'}>
                                                        <span><AiOutlineDollar className={'text-[#0B8D37]'}/></span>
                                                        <span className="text-[12px] font-bold text-[#0B8D37]">
                                                            {
                                                                //@ts-ignore
                                                                moneyFormat(parseInt(e.final_price!) / settings.find(e => e.name === 'reseller_percentage').value!) + "ریال"
                                                            }
                                                        </span>


                                                    </div>
                                                </div>
                                            )) : resellerPaymentsList.map(resellerPayments => resellerPayments.map((e) => {

                                                    return e.payments ? e.payments?.map((e) => <div
                                                            className={'py-3 px-4 flex justify-between items-start border-b border-b-[#F7F7F7]'}>
                                                            <div className={'flex flex-col'}>
                                                                <span
                                                                    className={'text-[14px]'}>{e.full_name}</span>
                                                                <span
                                                                    className={'text-[12px] text-[#737373] font-bold'}>{
                                                                    moneyFormat(e.final_price)} ریال </span>
                                                                <div
                                                                    className='text-[14px] mt-1 text-[#737373] font-normal '>

                                                                    <p>{moment(e.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'py-[2px] px-2 flex justify-center items-center bg-[#0B8D370D] rounded-xl'}>
                                                                <span><AiOutlineDollar className={'text-[#0B8D37]'}/></span>
                                                                <span className="text-[12px] font-bold text-[#0B8D37]">
                                                            {
                                                                //@ts-ignore
                                                                moneyFormat(parseInt(e.final_price!) / settings.find(e => e.name === 'reseller_percentage').value!) + "ریال"
                                                            }
                                                        </span>


                                                            </div>
                                                        </div>
                                                    ) : <div></div>
                                                    // return

                                                }
                                            ))
                                        }
                                    </div>
                                    {/*<div className={'flex flex-col'}>*/}
                                    {/*                    <span*/}
                                    {/*                        className={'text-[14px]'}>{*/}
                                    {/*                        e.full_name == null ? e.f_name : e.full_name}</span>*/}

                                    {/*    <span*/}
                                    {/*        className={'text-[12px] text-[#737373] font-bold'}>{*/}
                                    {/*        moneyFormat(parseInt(e.final_price!))} ریال </span>*/}

                                    {/*    <div*/}
                                    {/*        className='text-[14px] mt-1 text-[#737373] font-normal '>*/}

                                    {/*        <p>{moment(e.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    className={'py-[2px] px-2 flex justify-center items-center bg-[#0B8D370D] rounded-xl'}>*/}
                                    {/*    <span><AiOutlineDollar className={'text-[#0B8D37]'}/></span>*/}
                                    {/*    <span className="text-[12px] font-bold text-[#0B8D37]">*/}
                                    {/*                        {*/}
                                    {/*                            //@ts-ignore*/}
                                    {/*                            moneyFormat(parseInt(e.final_price!) / settings.find(e => e.name === 'reseller_percentage').value!) + "ریال"*/}
                                    {/*                        }*/}
                                    {/*                    </span>*/}


                                    {/*</div>*/}

                                    {
                                        loading ? (
                                            <div></div>
                                        ) : !loading && resellerPaymentsList.every(e => e.length === 0) ? (
                                            <div className={'w-full flex flex-col justify-center items-center'}>
                                                <img src={logo} alt="" className={'w-[30%]'}/>
                                                <span className={'text-lg'}>پرداختی وجود ندارد</span>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )
                                    }

                                </div>
                        }
                    </>

            }

        </div>
    )
}
export default ResellerPaymentsInfo;