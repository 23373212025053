import API from "../services/api";
import {AxiosResponse} from "axios";
import {verifyCodeCredentials} from "../redux/user/verifyCode/verifyCodeAction";
import {checkUser, convertObjectOfCamelCaseToObjectOfKeyBySnakeCase} from "../utils/funcions";
import {
    UserCredentials,
    UserUpdateCredentials
} from "../redux/user/user/userAction";
import {RegisterCredentials} from "../redux/user/register/registerAction";
import {CustomerCredentials} from "../redux/dashboard/customers/customersAction";
import {getAllShops} from "../redux/dashboard/shopUsers/ShopUsersAction";
import {SearchContactCredentials} from "../redux/dashboard/contacts/contactsAction";
import {SearchUsersCredentials} from "../redux/dashboard/users/UsersAction";
import {UpdateUserBySuperAdminCredentialsInterface} from "../redux/user/updateUser/updateUserBySuperAdminAction";

class UserRequests {

    public static async getUserInfo({isReturnCache = false}): Promise<AxiosResponse> {
        return await API.authGet("users/get", {
            isReturnCache,
            isUseCache: true
        });
    }

    //login using mobileNumber and password or without password by send code to mobileNumber
    //if status code is 200 and data is null, it means that user has not set password yet and should redirect to verify page
    //if status code is 201 and data is not null, it means that user has set password and should redirect to dashboard
    public static async login(credentials: {
        mobileNumber: string, password?: string, isUsePassword?: boolean
    }): Promise<AxiosResponse> {
        return await API.post('users/login', {
            data: {
                mobile_number: credentials.mobileNumber,
                password: credentials.password,
                is_use_password: credentials.isUsePassword,
            },
            isUseLoading: true,
        });
    }

    //register
    public static async register(credentials: RegisterCredentials): Promise<any> {
        return await API.post("users/register", {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(credentials),
        });
    }

    //verify code
    public static async verifyCode(credentials: verifyCodeCredentials): Promise<AxiosResponse> {
        return await API.post("users/verifyCode", {
            data: {
                mobile_number: credentials.mobileNumber,
                verification_code: credentials.code,
            }
        });
    }

    static async updateProfile(data: UserUpdateCredentials, onUploadProgress?: (progressEvent: number) => void)
        : Promise<AxiosResponse> {
        // console.log('console.logop', data)
        return await API.authPut('users/updateProfile', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(data),
            // isUseLoading: true,
            // onProgress: onUploadProgress,
        });
    }

    static async updateUser(data: UpdateUserBySuperAdminCredentialsInterface)
        : Promise<AxiosResponse> {
        return await API.authPut('user/updateUserBySuperAdmin', {
            data: convertObjectOfCamelCaseToObjectOfKeyBySnakeCase(data),
        });
    }

    static async createUserByShop(credentials: CustomerCredentials)
        : Promise<AxiosResponse> {
        return await API.authPost('userByShop', {
            data: {
                mobile_number: credentials.mobile_number,
                email: credentials.email,
                f_name: credentials.f_name,
                l_name: credentials.l_name,
                avatar: credentials.avatar,
                description: credentials.description,
                is_reseller: credentials.is_reseller,
            }
        });
    }
    public static async getUsers(credentials: SearchUsersCredentials, i: number): Promise<AxiosResponse> {
        const queryParams: string[] = [];

        if (credentials.title) {
            queryParams.push(`username=${encodeURIComponent(credentials.title)}`);
        }
        if (credentials.mobilenumber) {
            queryParams.push(`mobilenumber=${encodeURIComponent(credentials.mobilenumber)}`);
        }
        if (credentials.minPrice) {
            queryParams.push(`minPayment=${encodeURIComponent(credentials.minPrice.toString())}`);
        }
        if (credentials.maxPrice) {
            queryParams.push(`maxPayment=${encodeURIComponent(credentials.maxPrice.toString())}`);
        }
        if (credentials.status) {
            queryParams.push(`status=${encodeURIComponent(credentials.status.toString())}`);
        }

        queryParams.push(`page=${i}`);

        const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

        const url = `allUsers${queryString}`;

        return await API.authGet(url, { isUseLoading: true });
    }

    public static async geteightUsers(i: number): Promise<AxiosResponse> {
        return await API.authGet(`allUsers`, {isUseLoading: true});
    }

    public static async getAllShops(i: number): Promise<AxiosResponse> {
        return await API.authGet(`shopUsers?page=${i  ?? ''}`, {isUseLoading: true});
    }
    public static async getUsersProductPage(): Promise<AxiosResponse> {
        return await API.authGet("allUsers", {isUseLoading: true});
    }

    static async deleteProfile(): Promise<AxiosResponse> {
        return await API.authDelete(`users/deleteAvatar`, {
            isUseLoading: true,
        });
    }
}

export default UserRequests;