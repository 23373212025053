import React, {useState} from "react";
import {useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {DefaultBooleanField, DefaultInput, DefaultTextArea} from "../../components/fieilds";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {AnswerCredentials, submit} from "../../redux/dashboard/answers/answersAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import localToken from "../../services/savedData/localToken";

export const AnswerForm: React.FC = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {question, validationErrors, loading} = useAppSelector((state: RootState) => state.answer);
    const [submitLoading, setSubmitLoading] = useState(false);

    // declare const Swal: any;

    const handleButtonClick = () => {
        Swal.fire({
            title: "پاسخ ثبت شد",
            text: "از پاسخ گویی شما متشکریم",
            icon: "success"
        });
    };


    return (
        loading || !question ? <div className="skeleton h-4 w-20"></div> :
            <Formik
                initialValues={{
                    question_id: question!.questions.map(question => question.id),
                    form_question_id: question.id,
                    answer: question!.questions.map(question => ''),
                }}
                //@ts-ignore
                onSubmit={
                    async (values: AnswerCredentials, {setSubmitting}: FormikHelpers<AnswerCredentials>) => {
                        if (!submitLoading) {
                            setSubmitLoading(true);
                            try {
                                await dispatch(submit(values));
                                setSubmitLoading(false);
                                // (document.querySelector("[for='addPlan']") as HTMLElement).click();
                            } catch (e) {
                                setSubmitLoading(false);
                            }
                        }
                    }
                }
            >
                <Form >
                    <section
                        className="flex items-center py-5 bg-gray-50 dark:bg-gray-800 md:py-20 px-10 font-poppins dark:bg-gray-720  rounded-2xl">
                        <div className="justify-center flex-1 max-w-6xl py-0 mx-auto bg-gray-50 dark:bg-gray-900">
                            <div
                                className="flex flex-wrap justify-between pb-12 mb-16 text-right border-b border-gray-200 dark:border-gray-700 lg:justify-between ">
                                <div className="mb-6 lg:mb-0">
                                    <h2 className="mb-1 text-lg font-semibold tracking-wide dark:text-gray-300">
                                        {question!.title}
                                    </h2>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">

                                        {
                                            //@ts-ignore
                                            question.created_at}
                                    </p>
                                </div>
                                <div className="px-4">
                                    <button
                                        onClick={validationErrors ? handleButtonClick : () => {}}
                                        className="btn hover:text-[#4192EF] btn-success btn-outline">
                                        ثبت
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-wrap items-center ">
                                {
                                    question.questions.map((value, i) => {
                                        return <>
                                            <Field type="hidden" name={`question_id[${i}]`} value={value.id} />

                                            {
                                                value.type == 'textarea' ?
                                                    <DefaultTextArea className=""
                                                                     parentClassName={`lg:w-1/3 w-full px-2 mt-5`}
                                                                     name={`answer[${i}]`} label={value.title} type={value.type}
                                                                     placeholder={value.title}
                                                    />
                                                    :
                                                    value.type == 'boolean' ?
                                                        <DefaultBooleanField
                                                            defaultSelect={false}
                                                            parentClassName={`lg:w-1/3 w-full px-2 mt-5`}
                                                            name={`answer[${i}]`}
                                                            placeholder={value.title}
                                                        />
                                                        :
                                                        <DefaultInput
                                                            className=""
                                                            parentClassName={`lg:w-1/3 w-full px-2 mt-5`}
                                                            name={`answer[${i}]`} label={value.title} type={value.type}
                                                            placeholder={value.title}/>
                                            }
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </Form>
            </Formik>
    )
}