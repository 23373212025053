import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {remove, update} from 'redux/dashboard/contacts/contactsAction';
import {DeleteModal} from "../../../components/modal";
import Contact from "../../../models/contact";
import {DefaultResponse} from "../../../redux/mainSlice";
import EditContactModal from './edit';
import {LoadingTableShimmer} from "../../../components/basincComponents";
import Filter from "../filters/filter";
import {getAll} from "../../../redux/dashboard/contacts/contactsAction";
import AddContactModal from "./add";
import {IconBoxMultiple, IconChevronDown, IconCirclePlus, IconFilter, IconSearch, IconX} from '@tabler/icons-react';
import {copyToClipboard, moneyFormat, pagination} from "../../../utils/funcions";
import logo from "../../../assets/img/No data-pana-min.png";
import {getOrderCart} from "../../../redux/dashboard/orders/orderAction";
import {setAllStatesToTrue, setPaginate, setPaginateToStart} from "../../../redux/dashboard/contacts/contactSlice";
import logoo from "../../../assets/img/coloop-logo-44-min.-copy.png";
// import {setMobileNumber} from "../../../redux/user/login/loginSlice";

const ContactsList = () => {

    const {contactsList, loading} = useAppSelector((state: RootState) => state.adminContacts);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Contact | null>(null);
    const [editingItem, setEditingItem] = useState<Contact | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const lastSearchTimeRef = useRef(Date.now());
    const [title, setTitle] = useState<string>('');
    const [mobilenumber, setMobilenumber] = useState<string>('');
    const [copy, setCopy] = useState<boolean>(true);


    const handleDeleteClick = (contact: Contact) => {
        setSelectedItem(contact);
        setIsModalOpen(true);
    };
    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201 && result.status !== 204) {
                setDeletedId(0);
            }
        }
    };
    const handleShowFilter = async () => {
        setCopy(!copy)
    };
    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());

        if (currentTime - lastSearchTimeRef.current >= 2000) {
            const data = { title, mobilenumber };

            try {
                dispatch(getAll(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime;
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };
    const handleDeleteSearch = () => {
        setTitle("");
    };
    const handleDeleteMobileNumber = () => {
        setMobilenumber("");
    };

    useEffect(() => {
        const data = { title, mobilenumber };

        const handlePagination = () => {
            if (!store.getState().adminContacts.loading &&
                !store.getState().adminContacts.loadingMore &&
                !store.getState().adminContacts.isEnd) {
                dispatch(setPaginate());
                dispatch(getAll(data));
            }
        };

        const cleanup = pagination(handlePagination);

        return () => cleanup();
    }, [title, mobilenumber]);

    useEffect(() => {
        setPaginateToStart()
    }, []);


    return (
        <div className="flex-auto lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3 pt-0 pb-2">
            <DeleteModal
                title="حذف مخاطب"
                message="آیا از حذف این مخاطب مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditContactModal
                contact={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditContactModal>
            <AddContactModal/>

            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>مخاطبین</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none ml-2 p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                        <label htmlFor="addContact"
                               className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl">
                            <span className={'text-[15px]'}>افزودن</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-plus" width="24"
                                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 5l0 14"/>
                                <path d="M5 12l14 0"/>
                            </svg>
                        </label>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full'}>
                <div
                    className={`w-full md:mt-0 mt-6 duration-300 overflow-hidden ${copy ? 'h-0' : 'md:h-16 h-32'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس نام و نام خانوادگی"
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px] active:outline-none border-none  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس شماره موبایل  "
                                value={mobilenumber}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMobilenumber(e.target.value)}
                                className="input placeholder:text-[14px] active:outline-none border-none  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            />
                            {mobilenumber ? (
                                <button
                                    onClick={handleDeleteMobileNumber}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-xl hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>


            <div className={'overflow-x-auto w-full'}>
                <table
                    className="items-center w-full mt-[60px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                            و نام خانوادگی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            موبایل
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            ویرایش / حذف
                        </th>
                    </tr>
                    </thead>
                            {

                                    <tbody className={''}>
                                    {
                                        contactsList.map(contacts => contacts.map((contact, i) => (
                                            <tr key={contact.id}
                                                className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} ${deletedId === contact.id ? '-translate-x-96 hidetr' : ''}`}>
                                                <td className="px-6 py-3 font-semibold text-start  align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                                    {contact.f_name + ' ' + contact.l_name}
                                                </td>
                                                <td className="px-6 py-3 pl-2 font-semibold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap">{contact.mobile_number}</td>


                                                <td className="px-6 py-4 flex font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                                    <div onClick={() => handleDeleteClick(contact)}
                                                         className={'p-2 cursor-pointer group bg-[#DE004633] hover:bg-[#DE0046] duration-300 hover:text-white text-[#DE0046] h-10 rounded-xl flex justify-center items-center'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             className="icon icon-tabler icon-tabler-trash" width="24"
                                                             height="24" viewBox="0 0 24 24" strokeWidth="1.5"
                                                             stroke="currentColor"
                                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M4 7l16 0"/>
                                                            <path d="M10 11l0 6"/>
                                                            <path d="M14 11l0 6"/>
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                                        </svg>
                                                        {/*<span className={'group-hover:w-8 w-0 text-transparent group-hover:text-[#DE0046] duration-300'}>حذف</span>*/}
                                                    </div>

                                                    <div className={'h-2 w-2'}>

                                                    </div>
                                                    <div onClick={() => setEditingItem(contact)}
                                                         className={'duration-300 cursor-pointer bg-[#00FF0033] hover:bg-[#00FF00]  hover:text-white text-[#00FF00] w-10 h-10 flex justify-center items-center rounded-xl'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             className="group-hover:flex  icon icon-tabler icon-tabler-edit"
                                                             width="33" height="33" viewBox="0 0 24 24"
                                                             strokeWidth="1.5"
                                                             stroke="currentColor" fill="none" strokeLinecap="round"
                                                             strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path
                                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"/>
                                                            <path
                                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"/>
                                                            <path d="M16 5l3 3"/>
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        )))}

                                    </tbody>
                            }

                </table>
                {
                    loading && <div className={'w-full justify-center items-center flex'}>
                        <img src={logoo} alt=""
                             className={'md:w-[20%] w-[20%] loading loading-infinity mb-6 ml-10 cursor-pointer'}/>
                    </div>
                }
                {
                    contactsList.every(e => e.length === 0) && !loading ?
                        <div className={'w-full flex flex-col justify-center items-center'}>
                            <img src={logo} alt="" className={'w-[30%]'}/>
                            <span className={'text-lg'}>مخاطبی وجود ندارد</span>
                        </div> : <div></div>
                }
            </div>

        </div>
    );
};

export default ContactsList;
