import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {
    IconBoxMultiple,
} from "@tabler/icons-react";


const DocsList = () => {

    const {docs} = useAppSelector((state: RootState) => state.docs);

    return (
        <div className="flex-auto w-full lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3 pb-16 overflow-x-hidden">
            <div className={'overflow-x-auto'}>
                <table className="items-center w-full mt-[36px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                            خانوادگی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            موبایل
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">ایمیل</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">کد
                            ملی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">تاریخ
                            تولد
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">استان</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شهر</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">کد
                            پستی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">آدرس</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            تلفن ثابت
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">کد
                            مالیاتی
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            شبا
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                            کارت
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نوع
                            حساب
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شرکت</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">توضیحات</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">دامنه</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        docs.filter(e => !e.national_card_photo && !e.national_card_back_photo && !e.identity_photo)
                            .map((e, i) => (
                                <tr key={e.id.toString()}
                                    className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"}`}>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.f_name}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.l_name}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.mobile_number}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.email}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.national_code}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.birth_day}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.ostan}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.city}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.postal_code}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.address}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.landing_phone}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.tax_code}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.shaba_number}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.card_number}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.account_type}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.company}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.description}</td>
                                    <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.domain}</td>

                                </tr>
                            ))
                    }
                    </tbody>
                </table>
            </div>
        </div>

    );
};

export default DocsList;
