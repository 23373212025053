import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {LaravelValidationErrorType} from "utils/types";
import {toast422, toastError, toastSuccess} from "utils/funcions";
import {DefaultResponse} from "../../mainSlice";
import ResellerRequests from "../../../requests/resellerRequests";


export const getAllResellersChart = createAsyncThunk(
    'referrall/getAll',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.getAllReferral()

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {
                
                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const getAllReferralCheckouts = createAsyncThunk(
    'referrall/checkouts',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.getAllReferralCheckouts()

            if (response.status === 200) {
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)

export const checkoutReferral = createAsyncThunk(
    'referrall/checkout',
    async (): Promise<DefaultResponse> => {
        try {
            const response: AxiosResponse = await ResellerRequests.checkoutReferral()

            if (response.status === 200) {
                toastSuccess('درخواست دریافت هدیه ارسال شد')
                return {status: response.status, data: response.data}
            }else if (response.status === 422) {

                const errors = await response.data as LaravelValidationErrorType;
                toast422(errors);
                return {
                    status: response.status,
                    data: errors
                }
            }else {
                toastError();
                return {
                    status: response.status,
                    data: null
                }
            }
        }catch (e) {
            // toastError();
            return {
                status: 500,
                data: null
            }
        }
    }
)
