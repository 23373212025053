import {Link, useLocation} from "react-router-dom";
import React, {useState} from 'react';
import {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import Product from "../../../models/product";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {DeleteModal} from "../../../components/modal";
import {DefaultResponse} from "../../../redux/mainSlice";
import {MdAddShoppingCart, MdOutlineMoreHoriz} from "react-icons/md";

import {remove} from "../../../redux/dashboard/products/productsAction";
import {IconArrowRight, IconEdit, IconTrash} from "@tabler/icons-react";
import EditPlan from "./edit";
import {checkoutCart} from "../../../redux/dashboard/cart/cartAction";
import {PlanCheckoutCredentials, buyPlan} from "../../../redux/dashboard/plans/buyPlanAction";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import Routes from "../../../utils/routing/routes";
import {DefaultInput} from "../../../components/fieilds";
import ShopPlan from "../../../models/shopPlan";
import {removePlan} from "../../../redux/dashboard/shopPlans/shopPlansAction";


const ShopPlansList = () => {
    const {plans, loading} = useAppSelector((state: RootState) => state.shopPlans);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ShopPlan | null>(null);
    const [manualAmount, setManualAmount] = useState<number | null>(null);
    const [editingItem, setEditingItem] = useState<ShopPlan | null>(null);
    // const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {is_expired, user} = useAppSelector((state: RootState) => state.user);
    const logo = require("../../../assets/img/wave.webp");
    const location = useLocation();

    const handleDeleteClick = (plans: ShopPlan) => {
        setSelectedItem(plans);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(removePlan(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
            }
        }
    };

    const checkoutCartValues = (id: number, manual_amount: number| null) => {
        buyPlan({id: id, price: manual_amount})
    }

    const handleManualAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(event.target.value);
        setManualAmount(isNaN(amount) ? null : amount);
    };

    return (
        <>
            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>تعرفه ها</span>
            </Link>
            {
                is_expired ? <div className={'mt-10 text-[#DE0046]'}>
                    پلن فروشگاه به پایان رسیده است اقدام به خرید مجدد کنید
                </div> : ''
            }
            <DeleteModal
                title="حذف محصول"
                message="آیا از حذف این محصول مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditPlan
                plan={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditPlan>
            {
                loading ?
                        <div className={'flex justify-center items-center w-full'}>
                        </div>
                :
                    <div className={'px-4 py-6 grid lg:gap-3 md:gap-2 mt-6 gap-1 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                    {
                        plans.map((plan) =>
                            <div className="card hover:shadow-none duration-300 hover:scale-105 card-compact w-full shadow-xl min-h-96 h-96 md:mt-0 mt-4">

                                <figure>
                                    <img
                                        alt="Winter Landscape"
                                        className="object-cover transform rotate-180 w-full h-96 cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto rounded-xl"
                                        src={logo}/>
                                </figure>

                                <div
                                    className="card-body w-full rounded-tl-[20px] rounded-b-xl absolute top-10 left-0 right-0">
                                    <h2 className="card-title text-white">{plan.name}</h2>
                                    <div className={'w-full flex justify-center'}>
                                        <h2 className="text-center text-white text-[14px] font-bold">{plan.description}</h2>
                                    </div>
                                </div>

                                <div className="card-actions absolute bottom-4 w-full px-4 justify-center">
                                    <div className={'flex justify-between w-full'}>
                                        {
                                            user!.is_super_admin ? <button onClick={() => setEditingItem(plan)}
                                                                            className=" py-2 px-3 ml-1 rounded-xl bg-[#66A586] w-max border-none text-white">
                                                <IconEdit/></button> : <div></div>
                                        }
                                        <button onClick={() => checkoutCartValues(plan.id, null)} className="btn hover:bg-white hover:text-[#4192EF] bg-[#4192EF] flex-auto
                                     border-none text-white">{plan.price} ریال
                                        </button>

                                        {
                                            user!.is_super_admin ? <button onClick={() => handleDeleteClick(plan)}
                                                                            className="py-2 px-3 mr-1 rounded-xl bg-[#DE0046] w-max border-none text-white">
                                                <IconTrash/></button> : <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </>
    );
};

export default ShopPlansList;