import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import formQuestion from "../../../models/formQuestion";
import {IconChevronDown, IconEye} from "@tabler/icons-react";
import DefaultModal from "../../../components/modal";
import {UserAnswerInfo} from "./show";
import Routes from "../../../utils/routing/routes";
import {Link, useLocation} from "react-router-dom";
import * as url from "node:url";
import {BiRightArrowAlt} from "react-icons/bi";

const FormQuestionAnswersList = () => {

    const {questionAnswers, loading} = useAppSelector((state: RootState) => state.formQuestionUsersSlice);

    const location = useLocation();


    const [urls, setUrl] = useState<string>('');
    const extractFormId = (): string => {
        const path = window.location.pathname;
        const segments = path.split('/');
        const formId = segments[2]; // فرض بر این است که UUID در موقعیت 2 در مسیر قرار دارد
        return formId;
    };

    // تنظیم url هنگام بارگذاری کامپوننت
    useEffect(() => {
        const formId = extractFormId();
        setUrl(formId);
    }, []);

    // const {pathname} = location;
    // const [isFilterOpen, setIsFilterOpen] = useState(false);
    // const [title, setTitle] = useState<string>('');
    // console.log('reloadest', loading ? 'hello' : questionAnswers)

    return (
        <div className="flex lg:pl-28 md:pl-16 pl-3 flex-col w-full px-0 rounded-xl h-full">
            {/*<UserAnswerInfo/>*/}
            <label className={'flex cursor-pointer w-max'} htmlFor={'products'}>
                <BiRightArrowAlt size={26}/>
                <span>بازگشت</span>
            </label>
            <div
                className="mt-[75px] bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">

                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>پاسخ ها</span>
                    <div className={'flex justify-end'}>

                    </div>
                </div>

            </div>
            <div
                className={`p-0 mt-[82px] md:w-[100%] space-x-auto lg:w-[100%] md:px-6 md:overflow-x-auto overflow-x-auto`}>
                <table
                    className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            IP
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            اطلاعات
                        </th>
                    </tr>
                    </thead>
                    {loading ? "" :
                        <tbody>

                        {questionAnswers.map((formQuestions, i) => (
                            <tr
                                className={`transition-all mt-1 hover:bg-neutral-100 `}>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{i + 1}</td>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{formQuestions.ip}</td>
                                <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                    <Link to={Routes.userAnswersWithQuestions(formQuestions.ip, urls).path}
                                          state={{previousLocation: location}}
                                          className="p-2 bottom-0 hover:bg-[#4192EF33] duration-300 bg-[#4192EF] hover:text-[#4192EF] text-white rounded-xl flex font-bold text-center items-center capitalize align-middle shadow-none tracking-none whitespace-nowrap w-max">
                                        <IconEye className={'ml-2'}/>
                                        مشاهده
                                    </Link>
                                </td>

                            </tr>
                        ))}

                        </tbody>
                    }
                </table>
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>
        </div>

    );
};

// export default FormQuestionAnswersList;
export default FormQuestionAnswersList;