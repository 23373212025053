import React, {ReactNode, useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import logo from "../../../assets/img/coloop-logo-44-min.png";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import RootComponents from "../../../components/root/rootComponents";
import {IPath} from "../../../utils/types";
import Routes, {analyticsRoutes} from "../../../utils/routing/routes";
import {Link, Routes as RRR, useLocation} from "react-router-dom";
import {setShow, setSideBar} from "../../../redux/dashboard/analytics/analyticsSidebarSlice";
import {BiHome} from "react-icons/bi";
import {NetworkImage} from "../../../utils/funcions";


export const AnalyticsMainPage = () => {

    const navigation: {
        name: string,
        svg: ReactNode,
        link?: IPath,
        onClick?: () => void,
    }[] =
        [
            {
                name: "داشبورد",
                svg: (
                    <svg width={'32'} height={'32'} className={'text-[#233047] group-hover:text-white'} viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.918 10.0005H7.082C6.66587 9.99708 6.26541 10.1591 5.96873 10.4509C5.67204 10.7427 5.50343 11.1404 5.5 11.5565V17.4455C5.5077 18.3117 6.21584 19.0078 7.082 19.0005H9.918C10.3341 19.004 10.7346 18.842 11.0313 18.5502C11.328 18.2584 11.4966 17.8607 11.5 17.4445V11.5565C11.4966 11.1404 11.328 10.7427 11.0313 10.4509C10.7346 10.1591 10.3341 9.99708 9.918 10.0005Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.918 4.0006H7.082C6.23326 3.97706 5.52559 4.64492 5.5 5.4936V6.5076C5.52559 7.35629 6.23326 8.02415 7.082 8.0006H9.918C10.7667 8.02415 11.4744 7.35629 11.5 6.5076V5.4936C11.4744 4.64492 10.7667 3.97706 9.918 4.0006Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.082 13.0007H17.917C18.3333 13.0044 18.734 12.8425 19.0309 12.5507C19.3278 12.2588 19.4966 11.861 19.5 11.4447V5.55666C19.4966 5.14054 19.328 4.74282 19.0313 4.45101C18.7346 4.1592 18.3341 3.9972 17.918 4.00066H15.082C14.6659 3.9972 14.2654 4.1592 13.9687 4.45101C13.672 4.74282 13.5034 5.14054 13.5 5.55666V11.4447C13.5034 11.8608 13.672 12.2585 13.9687 12.5503C14.2654 12.8421 14.6659 13.0041 15.082 13.0007Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.082 19.0006H17.917C18.7661 19.0247 19.4744 18.3567 19.5 17.5076V16.4936C19.4744 15.6449 18.7667 14.9771 17.918 15.0006H15.082C14.2333 14.9771 13.5256 15.6449 13.5 16.4936V17.5066C13.525 18.3557 14.2329 19.0241 15.082 19.0006Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                ),
                link: Routes.analyticsDashboard,
            },
            {
                name: "تحلیل پیشرفته",
                svg: (
                    <svg width={'28'} height={'28'} className={'text-[#233047] group-hover:text-white'}
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M21 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V3M6 15L10 11L14 15L20 9M20 9V13M20 9H16"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                ),
                link: Routes.advancedAnalytics,
            },
            {
                name: "تحلیل RFM",
                svg: (

                    <svg width={'28'} height={'28'} className={'text-[#233047] group-hover:text-white'}
                         viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M21 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V3M6 15L10 11L14 15L20 9M20 9V13M20 9H16"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </g>
                    </svg>
                ),
                link: Routes.RFMAnalytics,
            },
            {
                name: "تحلیل دسته بندیها",
                svg: (
                    <svg
                        width="19"
                        height="17"
                        viewBox="0 0 19 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="fill-[#233047] group-hover:fill-white"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.7333 15.0385V11.7692H18.278C18.4695 11.7692 18.6531 11.6907 18.7885 11.5509C18.9239 11.4111 19 11.2215 19 11.0238V5.97615C19 5.77847 18.9239 5.58887 18.7885 5.44909C18.6531 5.3093 18.4695 5.23077 18.278 5.23077H17.7333V1.96154C17.7333 1.44131 17.5332 0.942381 17.1768 0.574521C16.8205 0.206662 16.3372 0 15.8333 0H1.9C1.39609 0 0.912816 0.206662 0.556497 0.574521C0.200178 0.942381 0 1.44131 0 1.96154L0 15.0385C0 15.5587 0.200178 16.0576 0.556497 16.4255C0.912816 16.7933 1.39609 17 1.9 17H15.8333C16.3372 17 16.8205 16.7933 17.1768 16.4255C17.5332 16.0576 17.7333 15.5587 17.7333 15.0385ZM17.7333 6.53846V10.4615H12.0333C11.5294 10.4615 11.0461 10.2549 10.6898 9.88702C10.3335 9.51916 10.1333 9.02023 10.1333 8.5C10.1333 7.97977 10.3335 7.48084 10.6898 7.11298C11.0461 6.74512 11.5294 6.53846 12.0333 6.53846H17.7333Z"
                        />
                    </svg>
                ),
                link: Routes.categoriesAnalytics,
            },
            {
                name: "تحلیل محصولات",
                svg: (
                    <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        className="fill-[#233047] group-hover:fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.5 19C4.25315 19 0 14.7469 0 9.5C0 4.25315 4.25315 0 9.5 0C14.7469 0 19 4.25315 19 9.5C19 14.7469 14.7469 19 9.5 19ZM9.5 6.65H5.7V8.55H14.25L9.5 3.8V6.65ZM4.75 10.45L9.5 15.2V12.35H13.3V10.45H4.75Z"/>
                    </svg>
                ),
                link: Routes.productsAnalytics,
            },
            {
                name: "تحلیل کاربران",
                svg: (
                    <svg width={'28'} height={28} className={'text-[#233047] group-hover:text-white'} viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M14.875 7.375C14.875 8.68668 13.8117 9.75 12.5 9.75C11.1883 9.75 10.125 8.68668 10.125 7.375C10.125 6.06332 11.1883 5 12.5 5C13.8117 5 14.875 6.06332 14.875 7.375Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M17.25 15.775C17.25 17.575 15.123 19.042 12.5 19.042C9.877 19.042 7.75 17.579 7.75 15.775C7.75 13.971 9.877 12.509 12.5 12.509C15.123 12.509 17.25 13.971 17.25 15.775Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M19.9 9.55301C19.9101 10.1315 19.5695 10.6588 19.0379 10.8872C18.5063 11.1157 17.8893 11 17.4765 10.5945C17.0638 10.189 16.9372 9.57418 17.1562 9.03861C17.3753 8.50305 17.8964 8.1531 18.475 8.15301C19.255 8.14635 19.8928 8.77301 19.9 9.55301V9.55301Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.10001 9.55301C5.08986 10.1315 5.43054 10.6588 5.96214 10.8872C6.49375 11.1157 7.11072 11 7.52347 10.5945C7.93621 10.189 8.06278 9.57418 7.84376 9.03861C7.62475 8.50305 7.10363 8.1531 6.52501 8.15301C5.74501 8.14635 5.10716 8.77301 5.10001 9.55301Z"
                                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path
                                d="M19.2169 17.362C18.8043 17.325 18.4399 17.6295 18.403 18.0421C18.366 18.4547 18.6705 18.8191 19.0831 18.856L19.2169 17.362ZM22 15.775L22.7455 15.8567C22.7515 15.8023 22.7515 15.7474 22.7455 15.693L22 15.775ZM19.0831 12.695C18.6705 12.7319 18.366 13.0963 18.403 13.5089C18.4399 13.9215 18.8044 14.226 19.2169 14.189L19.0831 12.695ZM5.91689 18.856C6.32945 18.8191 6.63395 18.4547 6.59701 18.0421C6.56007 17.6295 6.19567 17.325 5.78311 17.362L5.91689 18.856ZM3 15.775L2.25449 15.693C2.24851 15.7474 2.2485 15.8023 2.25446 15.8567L3 15.775ZM5.78308 14.189C6.19564 14.226 6.56005 13.9215 6.59701 13.5089C6.63397 13.0963 6.32948 12.7319 5.91692 12.695L5.78308 14.189ZM19.0831 18.856C20.9169 19.0202 22.545 17.6869 22.7455 15.8567L21.2545 15.6933C21.1429 16.7115 20.2371 17.4533 19.2169 17.362L19.0831 18.856ZM22.7455 15.693C22.5444 13.8633 20.9165 12.5307 19.0831 12.695L19.2169 14.189C20.2369 14.0976 21.1426 14.839 21.2545 15.8569L22.7455 15.693ZM5.78311 17.362C4.76287 17.4533 3.85709 16.7115 3.74554 15.6933L2.25446 15.8567C2.45496 17.6869 4.08306 19.0202 5.91689 18.856L5.78311 17.362ZM3.74551 15.8569C3.85742 14.839 4.76309 14.0976 5.78308 14.189L5.91692 12.695C4.08354 12.5307 2.45564 13.8633 2.25449 15.693L3.74551 15.8569Z"
                                fill="currentColor"></path>
                        </g>
                    </svg>
                ),
                link: Routes.usersAnalytics,
            },
        ];


    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {openSideBar, show} = useAppSelector((state: RootState) => state.analyticsSidebarSlice);
    const {user, loading, shop} = useAppSelector((state: RootState) => state.user);

    return (
        <div
            className={`w-full min-h-[100vh] admin-analytics bg-[#F9F9F9] sm:overflow-auto ${
                show ? "overflow-hidden h-screen" : "overflow-auto"
            }`}
        >
            {
                loading && <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                    <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                         xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                {/*<stop offset="100%" stop-color="#e23838"/>*/}
                            </linearGradient>
                            <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                <stop offset="0%" stop-color="#5555ee"/>
                                <stop offset="49%" stop-color="#4192EF"/>
                                <stop offset="70%" stop-color="#77DD77"/>
                                {/*<stop offset="100%" stop-color="#5ebd3e"/>*/}
                            </linearGradient>
                        </defs>
                        <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                            <g className="ip__track" stroke="#AAA">
                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                            </g>
                            <g stroke-dasharray="180 656">
                                <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                      d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                      d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                            </g>
                        </g>
                    </svg>
                </div>
            }
            <RootComponents/>
            <div className="w-full flex ">
                <div
                    className={`
   bg-white z-20 sm:min-h-[851px] lg:px-5 py-7 transition-all duration-1000 ease-in-out sm:ease-in-out sm:flex flex-col gap-16 absolute top-0 sm:relative sm:left-0 h-max overflow-hidden ${
                        show ? "left-0 h-screen w-72 overflow-y-auto duration-300" : "-left-72 hidden duration-300 sm:left-0"
                    }
  ${openSideBar ? "sm:w-72 px-5" : "sm:w-28 px-3.5"}
  `}
                >
                    {openSideBar ? <div
                        className={`flex gap-2 items-center z-30 mb-5 sm:mb-0 relative ${
                            openSideBar ? "sm:justify-start pl-2" : "sm:justify-center pl-0"
                        }`}
                    >
                        <div className="w-32 cursor-pointer">
                            <Logo/>
                        </div>
                        <span
                            className={`font-inter text-xl font-semibold text-[#233047] block ${
                                openSideBar ? "block" : "sm:hidden "
                            } cursor-pointer`}
                        >
              {/*{getSettingByKey("siteName")}*/}
            </span>
                    </div> : <div className={`min-h-4`}></div>}


                    <div className="flex flex-col gap-4 sm:gap-40"
                         onMouseEnter={() => {
                             dispatch(setSideBar(true));
                         }}
                         onMouseLeave={() => {
                             dispatch(setSideBar(false));
                         }}
                    >
                        <div className="flex flex-col gap-2">
                            {navigation?.map((data, index) => (
                                data.link == null ? <button
                                        className={`rounded-[10px] group py-5 px-3.5 flex gap-3 text-lg font-medium hover:bg-[#7851BD] hover:text-white text-[#233047] items-center tracking-[1.5px] truncate group justify-start ${
                                            openSideBar ? "sm:justify-start" : "sm:justify-center"
                                        } `}
                                        key={index}
                                    >

                                        <div className="h-5 w-5 ml-2">{data?.svg}</div>
                                        <span
                                            className={`block ${openSideBar ? "block" : "sm:hidden "} `}
                                        >
                    {data?.name}
                  </span>
                                    </button> :
                                    <div
                                        onClick={
                                            () => {
                                                dispatch(setShow());
                                            }}>

                                        <Link to={data.link.path} key={index}
                                              className={`rounded-[10px] py-5 px-3.5 flex gap-3 text-lg font-medium hover:bg-[#7851BD] hover:text-white text-[#233047] items-center tracking-[1.5px] truncate group justify-start ${
                                                  openSideBar ? "sm:justify-start" : "sm:justify-center"
                                              } `}
                                        >
                                            <div className="h-5 w-5">{data?.svg}</div>
                                            <span
                                                className={`block ${openSideBar ? "block" : "sm:hidden "} text-sm`}
                                            >
                    {data?.name}
                    </span>
                                        </Link>
                                    </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`h-10 z-20 w-10 rounded-full bg-white absolute top-[26px] -left-3.5 sm:flex justify-center items-center cursor-pointer hidden ${
                            openSideBar ? "rotate-[0deg]" : "rotate-180"
                        }`}
                        onClick={() => {
                            dispatch(setSideBar(null));
                        }}
                    >
                        <svg
                            className="w-8 h-8 rotate-[180deg]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div
                        className="flex py-5 px-4 w-full justify-between">

                        <div className={'flex'}>
                            <div
                                className="cursor-pointer sm:hidden border border-[#E7E7E7] hover:border-blue-600 group rounded-xl flex justify-center items-center"
                                onClick={
                                    () => {
                                        dispatch(setShow());
                                    }
                                }
                            >
                                <svg
                                    className="group-hover:text-blue-600 text-[#637381] w-10 h-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>

                            </div>
                            <div
                                onClick={() => {
                                    window.location.href = '/'
                                }}
                                className="flex gap-2 mr-2 items-center btn">
                                <BiHome/>
                                <span>خانه</span>
                            </div>
                        </div>

                        <div className="flex gap-2 ml-10 items-center">
                            <div className="flex gap-2 items-center">

                                <NetworkImage
                                    url={shop!.avatar}
                                    alt="img"
                                    className="w-10 h-10 rounded-full"
                                />

                                {/*<NetworkImage url={shop?.avatar} alt="" className=""/>*/}
                                <span>{user?.f_name} {user?.l_name}</span>
                            </div>
                        </div>
                    </div>
                    <RRR>
                        {analyticsRoutes()}
                    </RRR>
                </div>
            </div>
        </div>
    )
}

const Logo = () => {
    return (
        <></>
    );
};
