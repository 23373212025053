import {OneLoadingTableShimmer} from "../../../components/basincComponents";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {NetworkImage} from "../../../utils/funcions";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {serverAsset} from "../../../services/connectionConfig";
import React from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {IconPlus} from "@tabler/icons-react";
import {AsyncImage} from "loadable-image";

export const Highlights = () => {
    const highLights = useAppSelector((state: RootState) => state.highlights);
    const {user, shop} = useAppSelector((state: RootState) => state.user);


    return <div className={'overflow-x-auto overflow-y-hidden w-full carousel h-max mt-4'}>
        {
            highLights.loading ? '' : <div className={'w-full carousel overflow-x-auto overflow-y-hidden h-full flex'}>
                {
                    highLights.highLights?.map((e) =>

                        <Link to={Routes.stories(e.id.valueOf()).path}
                              className='flex flex-col carousel-item justify-center items-cetner mr-2 cursor-pointer lg:w-[14%] md:w-[17%] w-1/5 h-auto'>
                                <div className='p-[2px] flex items-center rounded-full border-2 justify-center aspect-square h-auto'>
                                    {
                                        e.cover ? <AsyncImage
                                            className={'rounded-full h-full w-full object-cover'}
                                            src={serverAsset(e.cover)} alt={''}/> : <AsyncImage
                                            className={'rounded-full h-full w-full bg-green-500 object-cover'}
                                            error={<img
                                                src={dLoad}
                                                alt=""
                                                className="w-full object-cover justify-center items-center flex h-full bg-transparent" // No blur here
                                            />}
                                            src={serverAsset(shop!.avatar)} alt={''}/>
                                    }

                                </div>
                            <div className='mt-2 mr-[3px] text-xs font-normal flex justify-center'>
                                {e.name.length > 10 ? e.name.substring(0, 10) + '' : e.name}
                            </div>
                        </Link>
                    )
                }
                {

                    user!.id === shop!.id && <label htmlFor={'addProduct'}
                                                  className='flex group flex-col carousel-item justify-center items-cetner mr-2 cursor-pointer lg:w-[14%] md:w-[17%] min-w-1/5 w-[22%]  h-auto'>
                        <div className='p-[2px] flex w-full h-full items-center border-2 flex-col rounded-full justify-center'>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 7V17M7 12H17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className='mt-2 mr-[3px] text-xs font-normal flex justify-center'>
                            افزودن
                        </div>
                    </label>
                }
                {/*خبر دارم که تو رو یکی لم دادی*/}
            </div>
        }
    </div>
}

