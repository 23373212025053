import { createSlice } from "@reduxjs/toolkit";
import { DefaultStates } from "redux/mainSlice";
import { LaravelValidationErrorType } from "../../../utils/types";
// import { getAllDocs } from "redux/dashboard/lTV/lTVAction";
import Doc from "../../../models/docs";
import {getAllDocs} from "./docAction";

interface State extends DefaultStates {
    docs: Doc[];
    submitLoading: boolean;
}

const initialState: State = {
    docs: [],
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const docSlice = createSlice({
    name: 'docs',
    initialState,

    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getAllDocs.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAllDocs.fulfilled, (state, action) => {
            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                const docs: Doc[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    const user = response.data.data[i];
                    docs.push(new Doc({
                        id: user.id,
                        f_name: user.f_name,
                        l_name: user.l_name,
                        p_name: user.p_name,
                        mobile_number: user.mobile_number,
                        email: user.email,
                        national_code: user.national_code,
                        birth_day: user.birth_day,
                        ostan: user.ostan,
                        city: user.city,
                        postal_code: user.postal_code,
                        address: user.address,
                        landing_phone: user.landing_phone,
                        tax_code: user.tax_code,
                        shaba_number: user.shaba_number,
                        card_number: user.card_number,
                        account_type: user.account_type,
                        company: user.company,
                        description: user.description,
                        domain: user.domain,
                        national_card_photo: user.national_card_photo,
                        national_card_back_photo: user.national_card_back_photo,
                        identity_photo: user.identity_photo,
                        created_at: user.created_at,
                        updated_at: user.updated_at
                    }));
                }
                state.docs = docs;
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        });

        builder.addCase(getAllDocs.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export default docSlice.reducer;
