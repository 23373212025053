import React from 'react';
import {DefaultBooleanField, DefaultInput, DefaultSelect} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {
    QuestionCredentials,
    QuestionUpdateCredentials,
    update
} from "../../../redux/dashboard/questions/questionsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import Question from "../../../models/question";
import {DefaultResponse} from "../../../redux/mainSlice";
import {IconArrowRight} from "@tabler/icons-react";

const EditQuestionModal: React.FC<{ question: Question | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            question,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, questions} = useAppSelector((state: RootState) => state.questions);

    const categories = useAppSelector((state: RootState) => state.adminCategories.categories);
    const products = useAppSelector((state: RootState) => state.adminProducts.products);

    //edit
    const handleEditConfirm = async (question: QuestionUpdateCredentials) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(question))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }


    return (
            <DefaultModal
                label={'editQuestion'} id='editQuestion' isOpen={!!question} handleIsOpen={onClose} modalBoxClasses={'px-3'}>
                {question ? (
                    <Formik
                        initialValues={{
                            id: question.id,
                            title: question.title,
                            question_type: question.type,
                        }}
                        onSubmit={(values: QuestionUpdateCredentials, {setSubmitting}: FormikHelpers<QuestionUpdateCredentials>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm(values)
                                );

                                (document.querySelector("[for='editQuestion']") as HTMLElement).click();
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: QuestionUpdateCredentials) => {
                            const errors: any = {};

                            if (!values.title) {
                                errors.title = 'عنوان فرم را وارد کنید.';
                            }
                            if (!values.question_type) {
                                errors.question_type = 'تایپ فرم را وارد کنید.';
                            }
                            return errors;
                        }}>
                        <Form method="dialog">
                            <label htmlFor={'editQuestion'}
                                   className={'cursor-pointer fixed top-0 z-[40]  px-2 py-3 bg-white w-full md:hidden flex'}>
                                <span><IconArrowRight/></span>
                                <span className={'mr-2'}>ویرایش</span>
                            </label>
                            <div className='flex-col justify-start md:mt-4  mt-12 items-center w-full flex'>

                                <DefaultInput
                                    label='عنوان سوال'
                                    name="title"
                                    type="text"
                                    placeholder='عنوان سوال'
                                ></DefaultInput>
                                {
                                }
                                <DefaultSelect defaultValue={question.type} name="question_type" options={[
                                    {
                                        label: 'متن',
                                        value: "text"
                                    },
                                    {
                                        label: 'عدد',
                                        value: "number"
                                    },
                                    {
                                        label: 'تاریخ',
                                        value: "date"
                                    },
                                    {
                                        label: 'متن طولانی',
                                        value: "textarea"
                                    },
                                    {
                                        label: 'ایمیل',
                                        value: "email"
                                    },
                                    {
                                        label: 'بله یا خیر',
                                        value: "boolean"
                                    },
                                ]}
                                               label="نوع فیلد"
                                />
                            </div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editQuestion"
                                    className="btn  bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
            </DefaultModal>
    );
};

export default EditQuestionModal;
