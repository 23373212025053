import {useDispatch} from "react-redux";
import {NetworkImage} from "../../../utils/funcions";
import React, {useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {Link, useLocation} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../../redux/mainSlice";
import {paymentStatusMap, paymentUserStatus, paymentUserStatusMap} from "../../../models/payment";
import AddProductModal from "../products/add";
import ProductsList from "../products/list";
import ProductsBigList from "../products/bigList";
import {MediaView} from "../../../utils/media/media_select";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import {IconArrowRight} from "@tabler/icons-react";
import {changePaymentStatus} from "../../../redux/dashboard/orders/orderAction";
export const SettlementOfAccount: React.FC  = () => {

    const {order, loading, submitLoading, } = useAppSelector((state: RootState) => state.orderPage);
    const {shop, user} = useAppSelector((state: RootState) => state.user);

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const location = useLocation();

    const [changeStatusNumber, setChangeStatusNumber] = useState(0);
    const changeStatusNewNumber = (numberSide: number) => {
        setChangeStatusNumber(numberSide);

    }
    //edit

    //edit
    const handleEditConfirm = async (values: values) => {
        const result: DefaultResponse = (await dispatch(changePaymentStatus(values))).payload as DefaultResponse;
        return result;
    }
    interface values {
        statuses: string;
        id: any;
    }

    const [changeSideNumber, setChangeSideNumber] = useState(0);
    const changeSideNewNumber = (numberSide: number) => {
        setChangeSideNumber(numberSide);
    }


    return (
        <>
            <Link to={Routes.orders.path} className={'cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>سفارش</span>
            </Link>

            {
                loading || order == null ? (
                    <div className="w-full flex justify-center mt-16">
                    </div>
                ) : (


                    <div className={'mt-16 px-4 flex flex-col items-center w-full'}>


                        <div className="flex md:w-1/2 w-full">
                            {
                                order.paymentable.cart_items == null ? <img src={dLoad} className={'rounded-xl'} alt=""/> : order!.paymentable.cart_items.map((e) =>
                                    <NetworkImage url={e.product.media[0].thumbnail} alt={''} className={'rounded-xl'}/>)
                            }
                        </div>
                        <div className={'mt-4'}>
                            <span>{
                                order.paymentable.cart_items == null ? "مشکلی در گرفتن عنوان رخ داده است!" : order!.paymentable.cart_items.map((e) => e.product.title)
                            }</span>
                        </div>
                        <div className={'mt-4 md:w-1/2 w-full flex flex-col items-start'}>
                            <label htmlFor="my_modal_7" className={'cursor-pointer'}><span className={'text-[#4192EF]'}>مشخصات کاربر و سفارش</span> </label>

                            {/* Put this part before </body> tag */}
                            <input type="checkbox" id="my_modal_7" className="modal-toggle"/>
                            <div className="modal" role="dialog">
                                <div className="modal-box">
                                    <h3 className="text-lg font-bold">مشخصات :</h3>
                                    <p className="py-3"> شماره موبایل : {order!.mobile_number}</p>
                                    <p className="py-3"> نام کاربری : {order!.full_name}</p>
                                    <p className="py-3"> توضیحات(آدرس) : {order!.description}</p>
                                </div>
                                <label className="modal-backdrop" htmlFor="my_modal_7">Close</label>
                            </div>

                            <div><span className={'text-[#737373]'}>قیمت : </span>{order!.total_price}</div>
                            <div><span>وضعیت پرداخت : </span>{paymentStatusMap[order!.status]}</div>
                            {
                                user!.id != shop!.id ?
                                    <div
                                        className={'cursor-pointer'}

                                    ><span>وضعیت محصول : </span>{paymentUserStatusMap[order!.user_status]}</div> :
                                    <div className={'w-full mt-[12px]'}>
                                        <div

                                            onClick={() => {
                                                // @ts-ignore
                                                return user!.id === shop!.id ? document.getElementById('my_modal_3').showModal() : {};
                                            }}
                                            className={'w-full cursor-pointer rounded-xl bg-[#4192EF] flex justify-center py-[4px] text-[14px] font-normal text-white '}>
                                            <div
                                                className={'cursor-pointer'}

                                            ><span>وضعیت محصول : </span>{paymentUserStatusMap[order!.user_status]}</div>
                                        </div>
                                    </div>
                            }

                        </div>
                        <dialog id="my_modal_3"
                                onClick={() => document.getElementById('humburger')}
                                className="modal flex items-end">

                            <div className={'h-full flex items-end'}>

                                <div
                                    className='py-3 bg-white rounded-t-2xl w-full h-max absolute flex flex-col overflow-y-auto items-center px-6'>

                                    <div className={'w-8 h-1 bg-[#818181] fixed rounded-full'}>

                                    </div>
                                    {/*pending: "در حال بررسی",*/}
                                    {/*paid: "پرداخت شده",*/}
                                    {/*cancelled: "لغو شده",*/}
                                    {/*delivered: "تحویل داده شده",*/}
                                    {/*returned: "بازگشت داده شده",*/}
                                    {/*refunded: "بازپرداخت شده",*/}
                                    {/*settled: "پرداخت شده"*/}
                                    <div className="form-control flex justify-between w-full"
                                         onClick={() => changeStatusNewNumber(1)}>
                                        <label className="label cursor-pointer"
                                               onClick={() => handleEditConfirm({
                                                   statuses: 'delivered',
                                                   id: order!.id
                                               })}>
                                            <span className="label-text">تحویل داده شده</span>
                                            <input type="radio" name="delivered"
                                                   className={`radio bg-green-500`}/>
                                        </label>
                                    </div>
                                    <div className="form-control flex justify-between w-full"
                                         onClick={() => changeStatusNewNumber(2)}>
                                        <label className="label cursor-pointer"
                                               onClick={() => handleEditConfirm({
                                                   statuses: 'posted',
                                                   id: order!.id
                                               })}>
                                            <span className="label-text">ارسال شده</span>
                                            <input type="radio_2" name="posted"
                                                   className={`radio bg-indigo-600`}/>
                                        </label>
                                    </div>
                                    <div className="form-control flex justify-between w-full"
                                         onClick={() => changeStatusNewNumber(3)}>
                                        <label className="label cursor-pointer"
                                               onClick={() => handleEditConfirm({
                                                   statuses: 'pending',
                                                   id: order!.id
                                               })}>
                                            <span className="label-text">در حال بررسی</span>
                                            <input type="radio_3" name="pending"
                                                   className={`radio bg-[#4192EF]`}/>
                                        </label>
                                    </div>
                                    <div className="form-control flex justify-between w-full"
                                         onClick={() => changeStatusNewNumber(4)}>
                                        <label className="label cursor-pointer"
                                               onClick={() => handleEditConfirm({
                                                   statuses: 'failed',
                                                   id: order!.id
                                               })}>
                                            <span className="label-text">لغو شده</span>
                                            <input type="radio_4" name="failed"
                                                   className={`radio bg-[#DE0046]`}/>
                                        </label>
                                    </div>

                                    {/*< onClick={() => handleEditConfirm({statuses: 'pending', id: 102})}>بروزرسانی</>*/}
                                </div>

                            </div>

                            <form method="dialog" className="modal-backdrop w-full h-full">
                                <button>close</button>
                            </form>
                        </dialog>
                    </div>

                )
            }

        </>
    )
        ;
}