import AddProductModal from './add';
import ProductsList from "./list";
import ProductsBigList from "./bigList";
// import TabView from "../../tabview/TabView";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {checkUser, getSettingByKey, NetworkImage} from "../../../utils/funcions";
import {RootState} from "../../../redux/store";
import {Link, useLocation} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import AppBar from "../partials/appBar";
import dLoad from "../../../assets/img/cantLoadImage.webp";
import userAvata from "../../../assets/img/user_avatar.png";
import {serverAsset} from "../../../services/connectionConfig";
import {LoadingTableShimmer, OneLoadingTableShimmer} from "../../../components/basincComponents";
import Auth from "../../../services/savedData/auth";
import {signUpAsDefault} from "../../../redux/user/login/loginSlice";
import {FaAngleLeft} from "react-icons/fa6";
import {IconType} from "react-icons";
import {IPath} from "../../../utils/types";
import {EditProfileModal} from "../profile/edit";
import {
    IconBuildingStore,
    IconCategory2, IconChartCircles, IconChartDots2, IconDiscount2,
    IconHome,
    IconLogin, IconMailOpened, IconPhoneCall, IconShoppingCart,
    IconSquareRoundedPlus,
    IconTruckDelivery,
    IconUser
} from "@tabler/icons-react";
import {AiOutlineInfo} from "react-icons/ai";
import Highlight from "../../../models/highLightedProducts";
import StoryPageModal from "../story/index";
import AddPlan from "../plans/add";
import AddShopModal from './addShop';
import Product from "../../../models/product";
import User from "../../../models/user";
import {updateShop} from "../../../redux/dashboard/addShop/addShopAction";
import ShopList from './shopList';
import {EightUser} from "../partials/8user";
import {Highlights} from "../partials/highlights";
import {BiPhoneCall, BiSolidPhoneCall} from "react-icons/bi";
import {CgBorderAll} from "react-icons/cg";
import {FcConferenceCall} from "react-icons/fc";
import {MdCall} from "react-icons/md";
import {FiPhoneCall} from "react-icons/fi";


interface IItem {
    title: string,
    icon: IconType,
    link?: IPath,
    subItems?: IItem[],
    itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest',
}
interface NavigationIItem {
    title: string,
    icon: IconType,
    link?: IPath|string,
    type?: 'function'|'IPath'|'link',
    subItems?: IItem[],
    onClick?: () => void,
    itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest',
    isFor?: boolean
}

function Index() {

    const {user, shop, settings, isMerchantFilled} = useAppSelector((state: RootState) => state.user);
    const navigationItems: NavigationIItem[] = [
        {
            title: !Auth.get() || user!.id === 0 ? 'ورود' : 'پروفایل',
            icon: !Auth.get() || user!.id === 0 ? IconLogin : IconUser,
            link: !Auth.get() || user!.id === 0 ? 'loginModal' : 'profileModal',
            type: 'function',
            itemVisibilityType: 'all',
            isFor: true
        },
        {
            title: 'دسته‌بندی',
            icon: IconCategory2,
            link: Routes.categories,
            itemVisibilityType: 'all',
        },
        {
            title: 'افزودن',
            icon: IconSquareRoundedPlus,
            link: 'addProduct',
            itemVisibilityType: 'shop',
            isFor: true
        },
        {
            title: user!.is_super_admin || !isMerchantFilled ? "تماس":'سفارشات',
            icon: user!.is_super_admin || !isMerchantFilled ? FiPhoneCall : IconTruckDelivery,
            link: user!.is_super_admin || !isMerchantFilled ?
                `tel:` + settings.find(e => e.name === 'phone_number')?.value
                : Routes.orders,
            type: user!.is_super_admin || !isMerchantFilled ? "link" : undefined,
            itemVisibilityType: 'user',
        },
        {
            title: 'آمار',
            icon: IconChartDots2,
            itemVisibilityType: 'shop',
            link: '/analytics',
            onClick: () => {
                window.location.href = window.location.origin + '/analytics';
            },
        },
        {
            title: 'کد‌تخفیف',
            icon: IconDiscount2,
            link: Routes.discountCodes,
            itemVisibilityType: 'shop',
        },
        {
            title: 'تماس‌با‌ما',
            icon: IconPhoneCall,
            link: Routes.contactUs,
            itemVisibilityType: 'user',
        },
        {
            title: 'خانه',
            icon: IconHome,
            link: Routes.products,
            itemVisibilityType: 'all',
        },
        {
            title: 'تماس‌با‌ما',
            icon: IconPhoneCall,
            link: Routes.contactUs,
            itemVisibilityType: 'all',
        },
        {
            title: 'درباره‌ما',
            icon: AiOutlineInfo,
            link: Routes.aboutUs,
            itemVisibilityType: 'all',
        },
    ];

    const [showDropDown, setDropDown] = useState(false);
    const [changeSideNumber, setChangeSideNumber] = useState(0);

    const changeSideNewNumber = (numberSide: number) => {
        setChangeSideNumber(numberSide);
    }
    const DropDown = () => {
        setDropDown(!showDropDown);
    }
    const dispatch = useAppDispatch();

    const userType = checkUser();

    let index: number | undefined = undefined;

    const location = useLocation();
    const {pathname} = location;
    const highLights = useAppSelector((state: RootState) => state.highlights);
    const {mainUsersList} = useAppSelector((state: RootState) => state.mainUsers);


    function checkVisibilityNavigation(item: NavigationIItem): boolean {

        const userType = checkUser(item.link as IPath);
        if (item.itemVisibilityType === 'all') {
            return true;
        }
        if (user!.id == 0 && item.itemVisibilityType === 'guest') {
            return userType === 'user';
        }
        if (user!.id != 0 && item.itemVisibilityType === 'user') {
            return userType === 'user';
        }
        if (item.itemVisibilityType === 'shop') {
            return userType === 'shop';
        }
        return false;
    }

    let navigationIndex: number | undefined = undefined;
    // let navigationIndex: number|undefined = undefined;
    let subIndex: number | undefined = undefined;

    const [openItem, setOpenItem] = useState<number | null>(null);
    const [subMenuHeight, setSubMenuHeight] = useState<number | null>(null);

    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const handleOpenSubMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
        if (index === openItem) {
            setOpenItem(null);
            setSubMenuHeight(null);
            return;
        }
        e.preventDefault();
        setSubMenuHeight(e.currentTarget?.querySelector('.submenu')?.clientHeight ?
            (e.currentTarget?.querySelector('.submenu')?.clientHeight ?? 0) * e.currentTarget?.querySelectorAll('.submenu').length
            : null
        );
        setOpenItem(index);
    }

    navigationIndex = pathname === 'profile' ? navigationItems.length : navigationItems.findIndex(item => (item.link as IPath | null)?.path === pathname);

    navigationItems.forEach((item, i) => {
        if (item.subItems) {
            const subSelectedIndex: number = item.subItems?.findIndex(subItem => subItem.link?.path === pathname);
            if (subSelectedIndex !== -1) {
                subIndex = subSelectedIndex;
                navigationIndex = i;
            }
        }
    });
    // useEffect(() => {
    //     window.location.reload()
    // }, []);
    return (
        !user!.is_super_admin ?
            <div className={`flex flex-col items-center overflow-y-hidden lg:ml-64`}>
                <AddProductModal/>

                <div className={`flex h-full justify-between w-full `}>
                    <div
                        className={`flex flex-col mx-1 w-full lg:pl-4`}>
                        <AppBar/>
                        <div className='flex px-[2px] duration-300 mt-2'>

                            <button onClick={() => changeSideNewNumber(0)}
                                    className={`flex flex-1 py-2 duration-300 justify-center items-center ${changeSideNumber == 0 ? "border-b-[3px] border-black" : "border-none"}`}>
                                <div>
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.782654 17.4348V1H17.2174V17.4348H0.782654Z"
                                              stroke={changeSideNumber == 1 ? "#B8B8B8" : "#101010"}
                                              strokeWidth="1.5"/>
                                        <path d="M6.26086 1L6.26086 18.2174"
                                              stroke={changeSideNumber == 1 ? "#B8B8B8" : "#101010"}
                                              strokeWidth="1.5"/>
                                        <path d="M0 11.9565L17.2174 11.9565"
                                              stroke={changeSideNumber == 1 ? "#B8B8B8" : "#101010"}
                                              strokeWidth="1.5"/>
                                        <line x1="11.7065" y1="1" x2="11.7065" y2="18.2174"
                                              stroke={changeSideNumber == 1 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                                        <line x1="0.782654" y1="6.51086" x2="18" y2="6.51086"
                                              stroke={changeSideNumber == 1 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                                    </svg>

                                </div>
                                <span
                                    className={`text-sm mr-2 ${changeSideNumber == 1 ? "text-[#B8B8B8]" : "text-black"}`}>
                        نمایش گروهی
                    </span>
                            </button>
                            <button onClick={() => changeSideNewNumber(1)}
                                    className={`flex duration-300 flex-1 py-2 justify-center items-center ${changeSideNumber == 1 ? "border-b-[3px] border-black" : "border-none"}`}>
                                <div>
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 7.70787V1H19V7.70787H1Z"
                                              stroke={changeSideNumber == 0 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                                        <path d="M1 17.7696V11.0618H19V17.7696H1Z"
                                              stroke={changeSideNumber == 0 ? "#B8B8B8" : "#101010"} strokeWidth="1.5"/>
                                    </svg>
                                </div>
                                <span
                                    className={`text-sm mr-2 ${changeSideNumber == 0 ? "text-[#B8B8B8]" : "text-black"}`}>
                        نمایش تکی
                    </span>
                            </button>
                        </div>

                        <div className={''}>{changeSideNumber === 1 ? <ProductsList></ProductsList> :
                            <div></div>}</div>
                        <div className={''}>{changeSideNumber === 0 ?
                            <ProductsBigList></ProductsBigList> :
                            <div></div>}</div>
                    </div>
                    {
                        changeSideNumber == 1 ?
                            <EightUser/>
                            : ''
                    }

                </div>
                <EditProfileModal handleIsOpen={(e: boolean) => setIsProfileModalOpen(e)} isOpen={isProfileModalOpen}/>

                <div
                    className="md:hidden fixed z-41 w-full h-16 max-w-3xl -translate-x-1/2 bg-white border border-gray-200 bottom-0 left-1/2 dark:bg-gray-700 dark:border-gray-600">
                    <div className="grid h-full max-w-3xl grid-cols-5 mx-auto">

                        {
                            navigationItems.map((item, i) => (
                                checkVisibilityNavigation(item) &&
                                !item.onClick ? <div
                                    className={`${i === navigationIndex ? "bg-black bg-opacity-5" : ''} flex flex-col items-center w-full mt-1 hover:bg-black hover:bg-opacity-5 rounded-xl px-3`}
                                    onClick={(e) => item.onClick != null ? item.onClick() : handleOpenSubMenu(e, i)}
                                >
                                    {typeof item.link === 'string' ?
                                        <div>
                                            {
                                                item.type == 'function' ?
                                                    <label htmlFor={item.link?.toString() || '#'}
                                                           onClick={() => {
                                                               if (!Auth.get()) {
                                                                   return dispatch(signUpAsDefault());
                                                               }
                                                               setIsProfileModalOpen(true);
                                                           }}
                                                           className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                        <div
                                                            className="flex flex-col w-full justify-center items-center">
                                                            <item.icon
                                                                color={i === index ? "#121212" : '#121212'}
                                                                style={{strokeWidth: "1.5"}}
                                                                size={24}/>
                                                            <span
                                                                className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}>{item.title}</span>
                                                                {item.subItems && (
                                                                    <FaAngleLeft
                                                                        className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                            + (openItem === i ? " transform rotate-90" : "")
                                                                        }/>
                                                                )}
                                    </span>
                                                        </div>
                                                    </label>
                                                    :
                                                    item.type == 'link' ?
                                                        <a href={item.link as string}
                                                           className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                            <div
                                                                className="flex flex-col w-full justify-center items-center">
                                                                <item.icon
                                                                    color={i === index ? "#121212" : '#121212'}
                                                                    style={{strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </a>
                                                        :
                                                        <label htmlFor={item.link?.toString() || '#'}
                                                               className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                            <div className="flex flex-col w-full justify-center items-center">
                                                                <item.icon
                                                                    style={i === navigationIndex ? {strokeWidth: ".5"} : {}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm duration-300 opacity-100 pointer-events-none ease-soft flex justify-center w-full items-center">
                                        <span className='md:text-sm text-[12px]'>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </label>
                                            }

                                        </div> :

                                        <Link to={item.link?.path || '#'}
                                              className={`${i === navigationIndex ? "font-bold" : 'font-normal'} py-1.5 flex items-center justify-center w-full text-md`}>
                                            <div className="flex flex-col w-full justify-center items-center">
                                                <item.icon
                                                    style={i === navigationIndex ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                    size={24}/>
                                                <span
                                                    className="text-black text-sm duration-300 opacity-100 pointer-events-none ease-soft flex justify-center w-full items-center">
                                        <span className='md:text-sm text-[12px]'>{item.title}</span>
                                    </span>

                                            </div>
                                        </Link>
                                    }
                                </div> : checkVisibilityNavigation(item) && <a href={`analytics`}
                                    className={`${i === navigationIndex ? "bg-black bg-opacity-5" : ''} flex flex-col items-center w-full mt-1 hover:bg-black hover:bg-opacity-5 rounded-xl px-3`}
                                    // onClick={(e) => item.onClick != null ? item.onClick() : handleOpenSubMenu(e, i)}
                                >
                                    {typeof item.link === 'string' ?
                                        <div>
                                            {
                                                item.type == 'function' ?
                                                    <label htmlFor={item.link?.toString() || '#'}
                                                           onClick={() => {
                                                               if (!Auth.get()) {
                                                                   return dispatch(signUpAsDefault());
                                                               }
                                                               setIsProfileModalOpen(true);
                                                           }}
                                                           className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                        <div
                                                            className="flex flex-col w-full justify-center items-center">
                                                            <item.icon
                                                                color={i === index ? "#121212" : '#121212'}
                                                                style={{strokeWidth: "1.5"}}
                                                                size={24}/>
                                                            <span
                                                                className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}>{item.title}</span>
                                                                {item.subItems && (
                                                                    <FaAngleLeft
                                                                        className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                            + (openItem === i ? " transform rotate-90" : "")
                                                                        }/>
                                                                )}
                                    </span>
                                                        </div>
                                                    </label>
                                                    :
                                                    item.type == 'link' ?
                                                        <a href={item.link as string}
                                                           className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                            <div
                                                                className="flex flex-col w-full justify-center items-center">
                                                                <item.icon
                                                                    color={i === index ? "#121212" : '#121212'}
                                                                    style={{strokeWidth: "1.5"}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </a>
                                                        :
                                                        <label htmlFor={item.link?.toString() || '#'}
                                                               className={`${i === navigationIndex ? "font-bold" : 'font-normal'} cursor-pointer py-1.5 flex items-center justify-center w-full text-md`}>
                                                            <div className="flex flex-col w-full justify-center items-center">
                                                                <item.icon
                                                                    style={i === navigationIndex ? {strokeWidth: ".5"} : {}}
                                                                    size={24}/>
                                                                <span
                                                                    className="text-black text-sm duration-300 opacity-100 pointer-events-none ease-soft flex justify-center w-full items-center">
                                        <span className='md:text-sm text-[12px]'>{item.title}</span>
                                                                    {item.subItems && (
                                                                        <FaAngleLeft
                                                                            className={"mr-1 ease-nav-brand text-slate-400 transition-all duration-200"
                                                                                + (openItem === i ? " transform rotate-90" : "")
                                                                            }/>
                                                                    )}
                                    </span>
                                                            </div>
                                                        </label>
                                            }

                                        </div> :

                                        <Link to={item.link?.path || '#'}
                                              className={`${i === navigationIndex ? "font-bold" : 'font-normal'} py-1.5 flex items-center justify-center w-full text-md`}>
                                            <div className="flex flex-col w-full justify-center items-center">
                                                <item.icon
                                                    style={i === navigationIndex ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                    size={24}/>
                                                <span
                                                    className="text-black text-sm duration-300 opacity-100 pointer-events-none ease-soft flex justify-center w-full items-center">
                                        <span className='md:text-sm text-[12px]'>{item.title}</span>
                                    </span>

                                            </div>
                                        </Link>
                                    }
                                </a>
                    ))
                }
            </div>
        </div>
    </div> :
            <div>
                <ShopList/>
            </div>

    );
}

export default Index;