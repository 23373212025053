import modalReducer from "redux/components/modalSlice";
import tabsReducer from "redux/components/tabsSlice";
import loginSlice from "redux/user/login/loginSlice";
import routeSlice from "redux/tools/routeSlice";
import verifyCodeSlice from "redux/user/verifyCode/verifyCodeSlice";
import {configureStore} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import categoriesSlice from "./dashboard/categories/categoriesSlice";
import cropSlice from "redux/tools/cropSlice";
import progressSlice from "./tools/progressSlice";
import discountCodesSlice from "./dashboard/discountCodes/discountCodesSlice";
import contactSlice from "./dashboard/contacts/contactSlice";
import userSlice from "./user/user/userSlice";
import cartSlice from "./dashboard/cart/cartSlice";
import addCartSlice from "./dashboard/cart/addCartSlice";
import customerSlice from "./dashboard/customers/customerSlice";
import contactAndCustomerSlice from "./dashboard/contactsAndCustomers/contactsCustomersSlice";
import productsSlice from "redux/dashboard/products/productsSlice";
import productPageSlice from "./dashboard/productPage/productPageSlice";
import mediaViewSlice from "./tools/viewMediaSlice";
import formQuestionsSlice from "./dashboard/formQuestions/formQuestionsSlice";
import questions from "./dashboard/questions/questionsSlice";
import answersSlice from "./dashboard/answers/answersSlice";
import AdvancedCategorySlice from "./dashboard/advancedCategory/advancedCategorySlice";
import LTVSlice from "./dashboard/lTV/lTVSlice";
import MainUsersSlice from "./dashboard/users/UsersSlice";
import Setting from "../models/setting";
import HighLightsSlice from "./dashboard/highLights/highLightsSlice";
import payment from "../models/payment";
import paymentSlice from "./dashboard/payment/paymentSlice";
import OrderSlice from "./dashboard/orders/orderSlice";
import productsCategorySlice from "./dashboard/categories/productsCategorySlice";
import ResellerSlice from "./dashboard/reseller/resellerSlice";
import OrderPageSlice from "./dashboard/orderPage/orderPageSlice";
import ReferrallSlice from "./dashboard/referrall/referrallSlice";
import PlansSlice from "./dashboard/plans/plansSlice";
import HighlightedProducts from "./dashboard/stories/storiesSlice";
import ResellerChartSlice from "./dashboard/resellerChart/resellerChartSlice";
import RFMSlice from "./dashboard/RFM/RFMSlice";
import TBPSlice from "./dashboard/TBP/TBPSlice";
import ChatSlice from "./dashboard/messenger/chatSlice";
import UsersChatSlice from "./dashboard/messenger/usersChatSlice";
import SettlementOfAccountSlice from "./dashboard/settlementOfAccounts/settlementOfAccountSlice";
import QuestionAnswersSlice from "./dashboard/questionAnswers/questionAnswersSlice";
import FormQuestionUsersSlice from "./dashboard/formQuestionUsers/formQuestionUsersSlice";
import UserQuestionAnswersSlice from "./dashboard/userAnswersOnQuestions/userAnswersOnQuestionSlice";
import ShopUsersSlice from "./dashboard/shopUsers/ShopUsersSlice";
import LikeSlice from "./dashboard/like/likeSlice";
import CheckOnlineSlice from "./tools/checkOnline/checkOnlineSlice";
import ShopPlansSlice from "./dashboard/shopPlans/shopPlansSlice";
import AnalyticsSlice from "./dashboard/analytics/analyticsSlice";
import AnalyticsSidebarSlice from "./dashboard/analytics/analyticsSidebarSlice";
import UtmSlice from "./dashboard/utm/utmSlice";
// import PaymentStatusSlice from "./dashboard/paymentStatus/paymentStatusSlice";
import BuyedPlansSlice from "./dashboard/plans/buyedPlansSlice";
import CatForAddProdSlice from "./dashboard/catForAddProd/catForAddProdSlice";
import {EightUserAction} from "./dashboard/users/EightUserAction";
import EightUserSlice from "./dashboard/users/EightUserSlice";
import RegisterSlice from "./user/register/registerSlice";
import AddShopSlice from "./dashboard/addShop/addShopSlice";
import DiscountProductsSlice from "./dashboard/discountCodes/discountProductsSlice";
import InventoryProductsSlice from "./dashboard/products/inventoryProductsSlice";
import UnreadNotificationsSlice from "./dashboard/notifications/unreadNotificationsSlice";
import DocSlice from "./dashboard/docs/docSlice";

const rootReducer = {
    answer: answersSlice,
    questionAnswersSlice: QuestionAnswersSlice,
    userQuestionAnswersSlice: UserQuestionAnswersSlice,
    formQuestionUsersSlice: FormQuestionUsersSlice,
    modal: modalReducer,
    tabs: tabsReducer,
    login: loginSlice,
    registerSlice: RegisterSlice,
    route: routeSlice,
    verifyCode: verifyCodeSlice,
    adminCategories: categoriesSlice,
    adminProductsCategory: productsCategorySlice,
    discountProducts: DiscountProductsSlice,
    docs: DocSlice,
    adminProducts: productsSlice,
    highLightedProducts: HighlightedProducts,
    payment: paymentSlice,
    productPage: productPageSlice,
    adminDiscountCodes: discountCodesSlice,
    crop: cropSlice,
    mediaView: mediaViewSlice,
    progress: progressSlice,
    adminContacts: contactSlice,
    highlights: HighLightsSlice,
    formQuestions: formQuestionsSlice,
    // paymentStatusSlice: PaymentStatusSlice,
    questions: questions,
    user: userSlice,
    like: LikeSlice,
    mainUsers: MainUsersSlice,
    eightUserAction: EightUserSlice,
    shopUsers: ShopUsersSlice,
    buyedPlans: BuyedPlansSlice,
    catForAddProdSlice: CatForAddProdSlice,
    chat: ChatSlice,
    users: UsersChatSlice,
    reseller: ResellerSlice,
    resellerChart: ResellerChartSlice,
    referrall: ReferrallSlice,
    plans: PlansSlice,
    shopPlans: ShopPlansSlice,
    utms: UtmSlice,
    cart: cartSlice,
    inventoryProducts: InventoryProductsSlice,
    order: OrderSlice,
    checkouts: SettlementOfAccountSlice,
    orderPage: OrderPageSlice,
    unreadNotificationsSlice: UnreadNotificationsSlice,
    addCart: addCartSlice,
    adminCustomers: customerSlice,
    contactsAndCustomers: contactAndCustomerSlice,
    advancedCategory: AdvancedCategorySlice,
    lTV: LTVSlice,
    RFM: RFMSlice,
    TBP: TBPSlice,
    highLight: HighLightsSlice,
    checkOnline: CheckOnlineSlice,
    analytics: AnalyticsSlice,
    addShopSlice: AddShopSlice,
    analyticsSidebarSlice: AnalyticsSidebarSlice,
};


const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;