import React, { useEffect, useState } from "react";
import DefaultModal from "../../../components/modal";
import { Form, Formik, FormikHelpers } from "formik";
import { DefaultInput } from "../../../components/fieilds";
import { RegisterCredentials, submit } from "redux/user/register/registerAction";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../redux/hooks";
import { setMobileNumber } from "../../../redux/user/login/loginSlice";
import { IconArrowRight } from "@tabler/icons-react";
import { getQueryStringValue } from "../../../utils/funcions";
import {RootState} from "../../../redux/store";

function RegisterModal() {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [isUsePassword, setIsUsePassword] = useState(true);

    const { loading, mobileNumber, isReseller } = useAppSelector((state: RootState) => state.login);

    const handleChange = (e: number) => {
        const inputValue = e;
        setMobileNumber(inputValue); // مقدار واردشده توسط کاربر را ذخیره می‌کنیم
    };

    const handleRegister = async (data: RegisterCredentials) => {
        // console.log(data)
        dispatch(setMobileNumber(data.mobileNumber || mobileNumber)); // اگر mobileNumber خالی بود، مقدار جدید واردشده کاربر استفاده می‌شود

        try {
            dispatch(submit({ ...data, mobile_number: mobileNumber || data.mobileNumber, is_reseller: isReseller }));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (getQueryStringValue('reseller_invitation_id')) {
            (document.querySelector('#registerModal') as HTMLInputElement).checked = true;
        }
    }, []);

    return (
        <DefaultModal
            label="registerModal"
            id="registerModal"
            modalBoxClasses="overflow-x-hidden px-3"
        >
            <label htmlFor="registerModal">
                <div className="cursor-pointer fixed top-0 z-[40] py-3 bg-white w-full md:hidden flex">
                    <span><IconArrowRight /></span>
                    <span className="mr-2">ثبت نام</span>
                </div>
            </label>
            <Formik
                initialValues={{
                    mobileNumber: mobileNumber,
                    mobile_number: '',
                    password: '',
                    confirm_password: '',
                    f_name: '',
                    l_name: '',
                    username: 'shop',
                    invitaion_user_id: getQueryStringValue('reseller_invitation_id') ?? '',
                    is_reseller: isReseller
                }}
                //@ts-ignore
                onSubmit={(
                    values: RegisterCredentials,
                    { setSubmitting }: FormikHelpers<RegisterCredentials>
                ) => {
                    values.mobileNumber = values.mobileNumber || mobileNumber || values.mobile_number; // استفاده از مقدار واردشده کاربر در صورت نبودن mobileNumber
                    handleRegister(values);
                }}
                validate={(values: RegisterCredentials) => {
                    const errors: any = {};

                    if (isUsePassword) {
                        if (!values.password) {
                            errors.password = 'رمز عبور را وارد کنید';
                        } else if (values.password.length < 8) {
                            errors.password = 'رمز عبور باید حداقل 8 کاراکتر باشد';
                        }
                        if (!values.confirm_password) {
                            errors.confirmPassword = 'تکرار رمز عبور را وارد کنید';
                        }
                        if (values.password !== values.confirm_password) {
                            errors.confirmPassword = 'رمز عبور و تکرار آن یکسان نیست';
                        }
                    }

                    if (values.f_name && (values.f_name.length < 3 || values.f_name.length > 60)) {
                        errors.f_name = 'نام باید بین 3 تا 60 کاراکتر باشد';
                    }

                    if (!values.l_name || (values.l_name.length < 3 || values.l_name.length > 60)) {
                        errors.l_name = 'نام خانوادگی باید بین 3 تا 60 کاراکتر باشد';
                    }

                    return errors;
                }}
            >
                <Form method="dialog">
                    <div className="flex-col justify-start items-center w-full flex md:mt-6 mt-10">
                        <DefaultInput
                            name="mobile_number"
                            type="text"
                            //@ts-ignore
                            onChange={handleChange}

                            placeholder="شماره موبایل"
                            value={mobileNumber}
                            label="شماره موبایل"
                        />

                        <DefaultInput name="f_name" type="text" placeholder="نام" label="نام" />
                        <DefaultInput name="l_name" type="text" placeholder="نام خانوادگی" label="نام خانوادگی" />

                        <div className={`transition-max-height overflow-hidden w-full ${isUsePassword ? 'max-h-56' : 'max-h-0'}`}>
                            <DefaultInput name="password" type="password" placeholder="رمز عبور" label="رمز عبور" />
                            <DefaultInput name="confirm_password" type="password" placeholder="تکرار رمز عبور" label="تکرار رمز عبور" />
                        </div>

                        {!getQueryStringValue('reseller_invitation_id') && (
                            <DefaultInput
                                name="invitaion_user_id"
                                type="text"
                                placeholder="کد دعوت (اختیاری)"
                                label="کد دعوت"
                            />
                        )}
                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button
                            type="submit"
                            className={`btn hover:text-[#4192EF] text-white ${loading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}
                        >
                            {loading ? 'لحظه‌ای صبر کنید' : 'ثبت نام '}
                        </button>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
}

export default RegisterModal;