import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {remove, update, getAll} from 'redux/dashboard/questions/questionsAction';
import {DeleteModal} from "../../../components/modal";
import Question from "../../../models/question";
import question from "../../../models/question";
import {DefaultResponse} from "../../../redux/mainSlice";
import EditQuestionModal from './edit';
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight, IconChevronDown, IconFilter, IconSearch, IconX} from "@tabler/icons-react";
import AddQuestionModal from "./add";
import {copyToClipboard, pagination} from "../../../utils/funcions";
import {
    setAllStatesToTrue,
    setPaginate,
    setPaginateToStart
} from "../../../redux/dashboard/discountCodes/discountCodesSlice";
// import {getAll} from "../../../redux/dashboard/discountCodes/discountCodesAction";
import {setMobileNumber} from "../../../redux/user/login/loginSlice";
import logo from "../../../assets/img/No data-pana-min.png";
import logoo from "../../../assets/img/coloop-logo-44-min.-copy.png";

const types: Record<string, string> = {
    'date': 'تاریخ',
    'text': 'متن',
    'number': 'عدد',
    'boolean': 'بله یا خیر',
    'textarea': 'متن طولانی',
    'email': 'ایمیل'
};
const   QuestionsList = () => {

    const {questionsList, loading} = useAppSelector((state: RootState) => state.questions);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Question | null>(null);
    const [editingItem, setEditingItem] = useState<Question | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [title, setTitle] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };
    const lastSearchTimeRef = useRef(Date.now());

    const handleDeleteClick = (question: Question) => {
        setSelectedItem(question);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                setDeletedId(0);
            }
        }
    };

    const handleDeleteSearch = () => {
        setTitle(""); // Clear title
    };
    const handleDeleteType = () => {
        setType(""); // Clear title
    };

    const urlRef = useRef<string>('');

    const extractFormId = (): string => {
        const path = window.location.pathname;
        const segments = path.split('/');
        const formId = segments[2]; // فرض بر این است که UUID در موقعیت 2 در مسیر قرار دارد
        return formId;
    };

    // تنظیم url هنگام بارگذاری کامپوننت
    useEffect(() => {
        const formId = extractFormId();
        setUrl(formId);
    }, []);

    // console.log('idididid', url)
    useEffect(() => {
        const data = { title, type };

        const handlePagination = () => {
            if (!store.getState().questions.loading &&
                !store.getState().questions.loadingMore &&
                !store.getState().questions.isEnd) {
                dispatch(setPaginate());
                dispatch(getAll({url, credentials: data}));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [title]);
    useEffect(() => {
        setPaginateToStart();
    }, []);

    // console.log()
    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());

        if (currentTime - lastSearchTimeRef.current >= 2000) {
            const data = { title, type };

            try {
                dispatch(getAll({url, credentials: data}));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime;
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };
    useEffect(() => {
        setPaginateToStart();
    }, []);

    return (
        <div className="flex-auto pt-0 pb-2 lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3 ">
            <DeleteModal
                title="حذف فرم سوال"
                message="آیا از حذف این فرم سوال مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditQuestionModal
                question={editingItem}
                onClose={(isOpen: boolean) => {

                    setEditingItem(null);
                }}
            ></EditQuestionModal>
            <AddQuestionModal/>
            <div onClick={() => {
                window.history.back()
            }}
                  className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>سوال ها</span>
            </div>

            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>سوالات</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none ml-2 p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                        <label htmlFor="addQuestion"
                               className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className={'text-[15px]'}>افزودن</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-plus" width="24"
                                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 5l0 14"/>
                                <path d="M5 12l14 0"/>
                            </svg>
                        </label>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full'}>
                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-16 h-32'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس عنوان "
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            >
                                <option value="">جستجو براساس نوع</option>
                                {/*{products.map((e) => (*/}
                                <option value='date'>تاریخ</option>
                                <option value='text'>متن</option>
                                <option value='number'>عدد</option>
                                <option value='boolean'>بله یا خیر</option>
                                <option value='textarea'>متن طولانی</option>
                                <option value='email'>ایمیل</option>
                                {/*))}*/}
                            </select>

                            {
                                type ? <button
                                    onClick={handleDeleteType}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX size={'24'}/>
                                </button> : <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            }
                        </div>
                    </label>
                    {/*<label className="input input-bordered rounded-xl flex items-center px-0 p-1">*/}
                    {/*    <div className="relative w-full">*/}
                    {/*        <select*/}
                    {/*            value={category}*/}
                    {/*            onChange={(e) => setCategory(e.target.value)}*/}
                    {/*            className="input placeholder:text-[14px] text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"*/}
                    {/*        >*/}
                    {/*            <option className={'text-[14px]'} value="">جستجو براساس دسته بندی</option>*/}
                    {/*            {*/}
                    {/*                categories.map((e) => <option className={'text-[14px]'} key={e.id}*/}
                    {/*                                              value={e.id}>{e.title}</option>)*/}
                    {/*            }*/}
                    {/*        </select>*/}
                    {/*        {*/}
                    {/*            category ? <button*/}
                    {/*                onClick={handleDeleteCategory}*/}
                    {/*                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                    {/*            >*/}
                    {/*                <IconX size={'24'}/>*/}
                    {/*            </button> : <button*/}
                    {/*                onClick={handleSearch}*/}
                    {/*                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"*/}
                    {/*            >*/}
                    {/*                <IconSearch size={'24'}/>*/}
                    {/*            </button>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</label>*/}
                    {/* Add more labels here as needed */}
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-xl hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>


            <div className={`overflow-x-auto p-0 md:w-[100%] lg:w-[100%] mt-[36px]`}>
                <table
                    className="items-center overflow-x-auto w-full mb-0 align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">عنوان</th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نوع
                            فیلد
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                            ویرایش / حذف
                        </th>
                    </tr>
                    </thead>
                    {loading ? "" : (
                        <tbody>

                        {questionsList.map(formQuestions => formQuestions.map((formQuestion, i) => (
                            <tr key={formQuestion.id.toString()}
                                className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"} ${deletedId === formQuestion.id ? '-translate-x-96 hidetr' : ''}`}>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{formQuestion.title}</td>
                                <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                    {types[formQuestion.type]}
                                </td>
                                <td className="px-6 py-4 flex font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                    <div onClick={() => handleDeleteClick(formQuestion)}
                                         className={'p-2 cursor-pointer group hover:bg-[#DE0046] bg-[#DE004633] duration-300 text-[#DE0046] hover:text-white h-10 rounded-xl flex justify-center items-center'}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-trash" width="24"
                                             height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 7l16 0"/>
                                            <path d="M10 11l0 6"/>
                                            <path d="M14 11l0 6"/>
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                        </svg>
                                        {/*<span className={'group-hover:w-8 w-0 text-transparent group-hover:text-[#DE0046] duration-300'}>حذف</span>*/}
                                    </div>

                                    <div className={'h-2 w-2'}>

                                    </div>
                                    <div onClick={() => setEditingItem(formQuestion)}
                                         className={'duration-300 cursor-pointer hover:bg-[#00FF00] bg-[#00FF0033]  text-[#00FF00] hover:text-white w-10 h-10 flex justify-center items-center rounded-xl'}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="group-hover:flex  icon icon-tabler icon-tabler-edit"
                                             width="33" height="33" viewBox="0 0 24 24" strokeWidth="1.5"
                                             stroke="currentColor" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"/>
                                            <path
                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"/>
                                            <path d="M16 5l3 3"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        )))}

                        </tbody>
                    )}
                </table>
                {
                    loading && <div className={'w-full justify-center items-center flex'}>
                        <img src={logoo} alt=""
                             className={'md:w-[20%] w-[20%] loading loading-infinity mb-6 ml-10 cursor-pointer'}/>
                    </div>
                }
                <div className="ps__rail-x">
                    <div className="ps__thumb-x"></div>
                </div>
                <div className="ps__rail-y">
                    <div className="ps__thumb-y"></div>
                </div>
            </div>


            {/*<div className="p-0 overflow-x-auto ps mt-6">*/}
            {/*    <table*/}
            {/*        className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">*/}
            {/*        <thead className="align-bottom">*/}
            {/*        <tr>*/}
            {/*            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>*/}
            {/*            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام</th>*/}

            {/*            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">*/}
            {/*                ویرایش / حذف*/}
            {/*            </th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        {loading ? "" : (*/}
            {/*            <tbody>*/}

            {/*            {questions.map((question, i) => (*/}
            {/*                <tr key={question.id.toString()}*/}
            {/*                    className={`transition-all ${deletedId === question.id ? '-translate-x-96 hidetr' : ''}`}>*/}
            {/*                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{i + 1}</td>*/}
            {/*                    <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{question.title}</td>*/}

            {/*                    <td className="px-6 py-4 flex font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">*/}
            {/*                        <div onClick={() => handleDeleteClick(question)}*/}
            {/*                             className={'p-2 cursor-pointer group bg-[#DE0046] hover:bg-[#DE004633] duration-300 hover:text-[#DE0046] text-white h-10 rounded-xl flex justify-center items-center'}>*/}
            {/*                            <svg xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                 className="icon icon-tabler icon-tabler-trash" width="24"*/}
            {/*                                 height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"*/}
            {/*                                 fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
            {/*                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>*/}
            {/*                                <path d="M4 7l16 0"/>*/}
            {/*                                <path d="M10 11l0 6"/>*/}
            {/*                                <path d="M14 11l0 6"/>*/}
            {/*                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>*/}
            {/*                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>*/}
            {/*                            </svg>*/}
            {/*                            /!*<span className={'group-hover:w-8 w-0 text-transparent group-hover:text-[#DE0046] duration-300'}>حذف</span>*!/*/}
            {/*                        </div>*/}

            {/*                        <div className={'h-2 w-2'}>*/}

            {/*                        </div>*/}
            {/*                        <div onClick={() => setEditingItem(question)}*/}
            {/*                             className={'duration-300 cursor-pointer bg-[#00FF00] hover:bg-[#00FF0033]  hover:text-[#00FF00] text-white w-10 h-10 flex justify-center items-center rounded-xl'}>*/}
            {/*                            <svg xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                 className="group-hover:flex  icon icon-tabler icon-tabler-edit"*/}
            {/*                                 width="33" height="33" viewBox="0 0 24 24" strokeWidth="1.5"*/}
            {/*                                 stroke="currentColor" fill="none" strokeLinecap="round"*/}
            {/*                                 strokeLinejoin="round">*/}
            {/*                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>*/}
            {/*                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"/>*/}
            {/*                                <path*/}
            {/*                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"/>*/}
            {/*                                <path d="M16 5l3 3"/>*/}
            {/*                            </svg>*/}
            {/*                        </div>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*            ))}*/}

            {/*            </tbody>*/}
            {/*        )}*/}
            {/*    </table>*/}
            {/*    <div className="ps__rail-x">*/}
            {/*        <div className="ps__thumb-x"></div>*/}
            {/*    </div>*/}
            {/*    <div className="ps__rail-y">*/}
            {/*        <div className="ps__thumb-y"></div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default QuestionsList;
