import React, { useState, useEffect } from 'react';
import DefaultModal from "../../../components/modal";
import { RootState } from "../../../redux/store";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Story } from "react-insta-stories/dist/interfaces";
import Stories, {WithSeeMore} from "react-insta-stories";
import { serverAsset } from "../../../services/connectionConfig";
import { closeModal } from "../../../redux/components/modalSlice";
import { color } from "chart.js/helpers";
import { Link } from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {IconArrowRight, IconChevronDown} from "@tabler/icons-react";
import ProductPageModal from "../productPage";
import Product from "../../../models/product";
import dLoad from "../../../assets/img/prof.webp";
import {moneyFormat} from "../../../utils/funcions";

export const StoryPageModal: React.FC = () => {
    const {highLightedProducts , loading } = useAppSelector((state: RootState) => state.highLightedProducts);
    const {shop } = useAppSelector((state: RootState) => state.user);

    const [showItem, setShowItem] = useState<Product | null>(null);


    return (
        <div className="w-full flex justify-center overflow-y-hidden h-full overflow-x-hidden">

            <div className="max-w-3xl w-full overflow-y-hidden h-full overflow-x-hidden">

                <div className='w-full h-full flex overflow-y-hidden justify-center overflow-x-hidden items-center {/*md:hidden*/}' dir={'ltr'}>
                    <div id='id' className={'md:max-w-md w-full overflow-y-hidden h-full overflow-x-hidden'}>
                        <label
                            className='flex flex-col w-full justify-end h-full overflow-y-hidden items-cetner overflow-x-hidden cursor-pointer min-w-4xl max-w-4xl z-[9999]'>

                            {loading || highLightedProducts == undefined || highLightedProducts == null || highLightedProducts.length == 0 ?
                                <div></div> : <Stories

                                    onAllStoriesEnd={() =>
                                        window.history.back()}
                                    keyboardNavigation={true}
                                    defaultInterval={500}
                                    // height={'1'}
                                    isPaused={true}
                                    width={`100%`}
                                    // @ts-ignore
                                    stories={highLightedProducts.map((e) => {
                                        return {
                                            // seeMore: CustomStoryContent , // when expanded
                                            seeMoreCollapsed: ({ toggleMore, action }) =>
                                                <div
                                                    className={'bottom-0 py-2 px-4 md:mr-64 justify-center fixed md:max-w-md bg-white w-full h-max flex flex-col'}>
                                                    <div className={'flex flex-col items-end mt-2'}>
                                                        <div className={'text-[14px]'}>
                                                            {
                                                                e.title
                                                            }
                                                        </div>
                                                        <div className={'h-0.5'}></div>
                                                        <div className={'text-[12px] font-bold text-[#737373]'}>
                                                            {
                                                                moneyFormat(e.price)
                                                            }
                                                        </div>

                                                    </div>
                                                    <div
                                                        className={'bottom-0 bg-white max-w-2xl w-full flex justify-center mt-[4px]'}>


                                                        {/*<div*/}
                                                        {/*    className={'px-6 py-2 text-[#4192EF] bottom-2 mt-2 cursor-pointer font-bold rounded-xl'}>*/}
                                                        {/*    {moneyFormat(e.price)}*/}
                                                        {/*</div>*/}
                                                        {/*<div className={'w-2'}></div>*/}
                                                        <Link
                                                            to={Routes.product(e.id).path}
                                                            className={'px-6 w-full py-2 bg-[#4192EF] flex justify-center bottom-2 mt-2 cursor-pointer text-white rounded-xl'}>
                                                            مشاهده محصول
                                                        </Link>
                                                    </div>
                                                </div>
                                            ,

                                            seeMore: () => (
                                                <div></div>
                                            ),
                                            url: e.media == null || e.media == undefined || e.media.length == 0 ? dLoad : serverAsset(e.media[0].url),
                                            duration:
                                                7000,

                                            styles:
                                                {
                                                    display: "flex",
                                                    align_items:
                                                        "start",
                                                    justify_content:
                                                        "center",
                                                    object:
                                                        "contain",
                                                    height:
                                                        "100%",
                                                    width: "500px"
                                                }
                                            ,
                                            type: "image",
                                            header:
                                                {
                                                    heading: e.high_light!.name,
                                                    subheading:
                                                        '',
                                                    profileImage
                                                        :
                                                        shop!.avatar == null || shop!.avatar == undefined ? dLoad : serverAsset(shop!.avatar)
                                                }
                                            ,


                                        }
                                    })}/>}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoryPageModal;
