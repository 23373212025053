import {updateShop} from "../../../redux/dashboard/addShop/addShopAction";
import {useAppSelector} from "../../../redux/hooks";
import store, {RootState} from "../../../redux/store";
import AddShopModal from "./addShop";
import {useEffect, useState} from "react";
import {pagination} from "../../../utils/funcions";
import {getAll} from "../../../redux/dashboard/products/productsAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import moment from "jalali-moment";
import {getAllPlans} from "../../../redux/dashboard/plans/plansAction";
import {getAllShops} from "../../../redux/dashboard/shopUsers/ShopUsersAction";
import EditUserBySuperAdminModal from "./changePhoneNumber";

const ShopList = () => {
    const handleStatusClick = (id: number) => {
        // @ts-ignore
        dispatch(updateShop(id))
        // setIsModalOpen(true);
    };
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    // const {mainUsersList} = useAppSelector((state: RootState) => state.mainUsers);
    const {shops} = useAppSelector((state: RootState) => state.shopUsers);
    const {user, loading} = useAppSelector((state: RootState) => state.user);

    const adminProductsState = store.getState().shopUsers;

    useEffect(() => {
        pagination(() => {
            if (!adminProductsState.loading && !adminProductsState.loadingMore && !store.getState().shopUsers.isEnd) {
                dispatch(getAll({isReturnCache: false}));
            }
        })
    })

    useEffect(() => {
        dispatch(getAllPlans());
        dispatch(getAllShops());
    }, [dispatch]);

    const [id, setId] = useState<number>(0);
    const handleSetId = (newId: number) => {
        setId(newId);
    };

    return (
        <div className={'mt-16 w-full overflow-x-auto'}>
            <EditUserBySuperAdminModal id={id}/>
            <div className={'flex'}>
                {
                    user!.is_super_admin ?
                        <div className={'flex w-full justify-end px-4  pt-6'}>
                            <label htmlFor="addShop"
                                   className="btn block bg-[#4192EF] w-max border-none p-3 rounded-xl text-xl text-white">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="icon icon-tabler icon-tabler-plus" width="24"
                                     height="24" viewBox="0 0 24 24" strokeWidth="2"
                                     stroke="currentColor"
                                     fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 5l0 14"/>
                                    <path d="M5 12l14 0"/>
                                </svg>
                            </label>

                            {/*add post modal*/}

                            <AddShopModal/>
                        </div> : <div></div>
                }
            </div>
            <table className="items-center overflow-x-scroll w-full mb-0 align-top border-gray-200 text-slate-500">
                <thead className="align-bottom">
                <tr>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام</th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام یونیک</th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        شماره موبایل
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        زمان پایان پلن
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        زمان  شروع پلن
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        وضعیت
                    </th>
                    <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                        ادیت
                    </th>
                </tr>
                </thead>
                {
                    loading || shops[0] == undefined ? <div></div> :
                        //@ts-ignore
                    shops[0].map((e, i) => (
                    e.domain ?

                        <tbody>

                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400">{i + 1}</td>
                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.f_name}</td>
                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.unique_name}</td>
                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.mobile_number}</td>
                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{e.shop_plan?.end_time}</td>
                        <td className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-400">{/*{e.buying_date}*/}{e.buying_date}</td>
                        <td className="px-6 py-3 font-bold text-start capitalize align-middle border-solid shadow-none tracking-none whitespace-nowrap text-slate-400">
                            <div onClick={() =>
                                handleStatusClick(e.id)
                            }
                                 className={`inline-block px-4 py-3 mb-0 font-bold text-center uppercase align-middle transition-all border-0 rounded-xl shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 active:opacity-85 bg-x-25  ${e.is_blocked == true ? "bg-[#4191EF33] text-[#4192EF]" : "bg-[#DE004633] text-[#DE0046]"}  `}
                            >
                                {e.is_blocked == true ?
                                    'آزاد کردن'
                                    :
                                    'مسدود کردن'
                                }
                            </div>
                        </td>
                        <td className="px-6 py-3 font-bold text-start capitalize align-middle border-solid shadow-none tracking-none whitespace-nowrap text-slate-400">
                            <label htmlFor={'editUserBySuperAdminModal'}
                                   // onChange={() => handleSetId(e.id)}
                                   onClick={() => handleSetId(e.id)}
                                 className={`inline-block px-4 py-3 mb-0 font-bold text-center uppercase align-middle transition-all border-0 rounded-xl shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:bg-[#4192EF] bg-[#4192EF33] text-[#4192EF] hover:text-white  active:opacity-85 bg-x-25 `}
                            >
                                ویرایش
                            </label>
                        </td>

                        </tbody>
                        : <div></div>
                ))}
            </table>
        </div>
    )
}
export default ShopList;