import React from 'react';
import {DefaultInput, DefaultSelect} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {BiRightArrowAlt} from "react-icons/bi";
import {ShopCreateCredentials, submitShop} from "../../../redux/dashboard/addShop/addShopAction";
import {
    updateUserBySuperAdmin,
    UpdateUserBySuperAdminCredentialsInterface
} from "../../../redux/user/updateUser/updateUserBySuperAdminAction";

interface valuesShop {
    mobile_number: string,
    id: number,
    // password?: string,
}

interface EditUserBySuperAdminModalProps {
    id: number;
}

const EditUserBySuperAdminModal: React.FC<EditUserBySuperAdminModalProps> = ({ id }) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminProducts);
    return (
        <DefaultModal
            label={'editUserBySuperAdminModal'}  id='editUserBySuperAdminModal' modalBoxClasses={`md:!max-w-3xl md:!w-max px-3 lg:min-w-31/100 md:h-max h-full overflow-x-hidden`}>
            <label className={'flex cursor-pointer w-max'} htmlFor={'editUserBySuperAdminModal'}>
                <BiRightArrowAlt size={26}/>
                <span>بازگشت</span>
            </label>
            <Formik
                initialValues={{
                    mobile_number: '',
                    id: 0,
                }}
                // @ts-ignore
                onSubmit={

                    (valuesShop: valuesShop, {setSubmitting}: FormikHelpers<valuesShop>) => {
                        valuesShop.id = id
                        try {
                            const credentials: UpdateUserBySuperAdminCredentialsInterface = {
                                ...valuesShop,
                            };
                            dispatch(updateUserBySuperAdmin(credentials));
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: valuesShop) => {
                    const errors: any = {};
                    if (!values.mobile_number) {
                        errors.mobile_number = 'شماره موبایل را وارد کنید';
                    }
                    return errors;
                }}>
                <div className={'md:h-max h-full w-full items-center flex'}>

                    <Form method="dialog" className={'w-full h-full'}>
                        <h3 className="font-bold text-lg flex justify-center">ادیت</h3>
                        <div className="flex md:flex-row justify-around flex-col">

                            <div className="md:w-1/2">
                                <div className='flex-wrap justify-start items-center w-full flex'>
                                    <DefaultInput
                                        label='شماره موبایل'
                                        name="mobile_number"
                                        type="text"
                                        placeholder='شماره موبایل'
                                    ></DefaultInput>

                                </div>
                                <div className="modal-action w-full flex justify-start">

                                    <label
                                        htmlFor="editUserBySuperAdminModal"
                                        className="btn bg-[#DE0046] rounded-xl text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                    </label>
                                    <div className={'w-0.5 h-2'}></div>
                                    <button type="submit"
                                            className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                        {
                                            submitLoading ? 'صبر کنید' : 'ویرایش '
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Formik>
        </DefaultModal>
    );
};

export default EditUserBySuperAdminModal;
