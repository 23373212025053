interface IDoc {
    id: number;
    f_name: string;
    l_name: string;
    p_name: string;
    mobile_number: string;
    email: string;
    national_code: string;
    birth_day: string;
    ostan: string;
    city: string;
    postal_code: string;
    address: string;
    landing_phone: string;
    tax_code: string;
    shaba_number: string;
    card_number: string;
    account_type: string;
    company?: string | null;
    description?: string | null;
    domain: string;
    national_card_photo?: string | null;
    national_card_back_photo?: string | null;
    identity_photo?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
}

class Doc {
    public id: number;
    public f_name: string;
    public l_name: string;
    public p_name: string;
    public mobile_number: string;
    public email: string;
    public national_code: string;
    public birth_day: string;
    public ostan: string;
    public city: string;
    public postal_code: string;
    public address: string;
    public landing_phone: string;
    public tax_code: string;
    public shaba_number: string;
    public card_number: string;
    public account_type: string;
    public company?: string | null;
    public description?: string | null;
    public domain: string;
    public national_card_photo?: string | null;
    public national_card_back_photo?: string | null;
    public identity_photo?: string | null;
    public created_at?: string | null;
    public updated_at?: string | null;

    constructor(data: IDoc) {
        this.id = data.id;
        this.f_name = data.f_name;
        this.l_name = data.l_name;
        this.p_name = data.p_name;
        this.mobile_number = data.mobile_number;
        this.email = data.email;
        this.national_code = data.national_code;
        this.birth_day = data.birth_day;
        this.ostan = data.ostan;
        this.city = data.city;
        this.postal_code = data.postal_code;
        this.address = data.address;
        this.landing_phone = data.landing_phone;
        this.tax_code = data.tax_code;
        this.shaba_number = data.shaba_number;
        this.card_number = data.card_number;
        this.account_type = data.account_type;
        this.company = data.company;
        this.description = data.description;
        this.domain = data.domain;
        this.national_card_photo = data.national_card_photo;
        this.national_card_back_photo = data.national_card_back_photo;
        this.identity_photo = data.identity_photo;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
    }
}

export default Doc;
