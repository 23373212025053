import {useDispatch, useSelector} from "react-redux";
import {moneyFormat, NetworkImage, pagination} from "../../../utils/funcions";
import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import store, {RootState} from "../../../redux/store";
import {Link} from "react-router-dom";
import Routes from "../../../utils/routing/routes";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {DefaultResponse} from "../../../redux/mainSlice";
import order from "../../../models/order";
import empty from "../../../assets/img/No data-pana-min.png"
import {IconArrowRight, IconChevronDown, IconEye, IconFilter, IconSearch, IconX} from "@tabler/icons-react";
import {
    changeCheckoutStatus,
    getSettlementOfAccountCart
} from "../../../redux/dashboard/settlementOfAccounts/settlementOfAccountAction";
import {setPaginate, setPaginateToStart, setAllStatesToTrue} from "../../../redux/dashboard/settlementOfAccounts/settlementOfAccountSlice";
import logo from "../../../assets/img/No data-pana-min.png";
import {isBoolean} from "react-advanced-cropper";
import logoo from "../../../assets/img/coloop-logo-44-min.-copy.png";
function SettlementOfAccounts() {

    // const {checkouts} = useAppSelector((state: RootState) => state.checkouts);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { checkoutsList, loading } = useAppSelector((state: RootState) => state.checkouts);
    interface values {
        status: string;
        id: any;
    }

    const handleEditConfirm = async (values: values) => {
        const result: DefaultResponse = (await dispatch(changeCheckoutStatus(values))).payload as DefaultResponse;
        return result;
    }

        const [title, setTitle] = useState<string>('');
        const lastSearchTimeRef = useRef(Date.now());

        const [mobilenumber, setMobile] = useState<string>('');
        const [minPrice, setMinPrice] = useState<string>('');
        const [maxPrice, setMaxPrice] = useState<string>('');
        const [status, setStatus] = useState<string>('');

        const [copy, setCopy] = useState<boolean>(true);

        const handleShowFilter = async () => {
            setCopy(!copy)
        };

    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart())
        dispatch(setAllStatesToTrue());
        if (currentTime - lastSearchTimeRef.current >= 2000) { // 2 seconds
            const data = { title, mobilenumber, minPrice, maxPrice, status };
            try {
                dispatch(getSettlementOfAccountCart(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime; // Update the last search time
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

        const handleDeleteSearch = () => {
            setTitle("");
        };

        const handleDeleteSearchByMobileNumber = () => {
            setMobile("");
        };
        const handleDeleteSearchByMinPayment = () => {
            setMinPrice("");
        };
        const handleDeleteSearchByMaxPayment = () => {
            setMaxPrice("");
        };



    useEffect(() => {
        const data = { title, mobilenumber, minPrice, maxPrice, status };

        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());
        const handlePagination = () => {
            if (!store.getState().checkouts.loading &&
                !store.getState().checkouts.loadingMore &&
                !store.getState().checkouts.isEnd) {
                dispatch(setPaginate());
                dispatch(getSettlementOfAccountCart(data));
            }
        };

        const cleanup = pagination(handlePagination);
        return () => cleanup();
    }, [title, mobilenumber, minPrice, maxPrice, status]);


    return (
        <div className="items-center w-full h-full   lg:pl-28 md:pl-16 pl-3">

            <Link to={Routes.products.path}
                  className={'cursor-pointer fixed top-0 px-4 z-50 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>درخواست های تسویه حساب</span>
            </Link>
            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>درخواست های تسویه حساب</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                    </div>
                </div>

            </div>


            <div className={'flex flex-col items-start w-full'}>


                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-32 h-72'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس نام و نام خانوادگی "
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="حداقل قیمت"
                                value={minPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMinPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />

                            {
                                minPrice ? <button
                                        onClick={handleDeleteSearchByMinPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="number"
                                placeholder="حداکثر قیمت"
                                value={maxPrice}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMaxPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />

                            {
                                maxPrice ? <button
                                        onClick={handleDeleteSearchByMaxPayment}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconX size={'24'}/>
                                    </button> :
                                    <button
                                        onClick={handleSearch}
                                        className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                    >
                                        <IconSearch size={'24'}/>
                                    </button>
                            }
                        </div>
                    </label>

                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس شماره موبایل"
                                value={mobilenumber}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setMobile(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />
                            {mobilenumber ? (
                                <button
                                    onClick={handleDeleteSearchByMobileNumber}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>

                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            >
                                <option value="">جستجو براساس وضعیت</option>
                                {/*{products.map((e) => (*/}
                                <option value='pending'>پرداخت نشده</option>
                                <option value='paid'>پرداخت شده</option>
                                {/*))}*/}
                            </select>
                            <button
                                onClick={handleSearch}
                                className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                            >
                                <IconSearch size={'24'}/>
                            </button>
                        </div>
                    </label>
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-xl hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>

            </div>
            {
                <div className="flex flex-col items-center mt-[36px] h-full w-full">
                    <div className={`p-0 overflow-x-auto ps w-full`}>

                        <table
                            className="items-center w-full overflow-x- mb-0 align-top border-gray-200 text-slate-500">
                            <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام و نام خانوادگی</th>
                                            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">شماره
                                                موبایل
                                            </th>
                                            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">هزینه
                                                پرداخت
                                            </th>
                                            <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">وضعیت
                                                پرداخت
                                            </th>
                                        </tr>
                                        </thead>
                                        {
                                            <tbody>
                                            {
                                                checkoutsList.map(contacts => contacts.map((checkout, i) => (
                                                    <tr key={checkout.id}
                                                        className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"}`}>
                                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{checkout.user.f_name} {checkout.user.l_name}</td>
                                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{checkout.user.mobile_number}</td>
                                                        <td className="px-6 py-3 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{moneyFormat(checkout.reseller_price)}</td>
                                                        <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">
                                                            <select className="py-2.5 border cursor-pointer border-[#C7C7C7] bg-white rounded-xl px-2 w-36 min-w-[11rem] max-w-xs"
                                                                    value={checkout.status}
                                                                    onChange={(e) => handleEditConfirm({
                                                                        status: e.target.value,
                                                                        id: checkout.id
                                                                    })}>
                                                                <option value="paid">پرداخت شده</option>
                                                                <option value="pending">درحال بررسی</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )))
                                            }
                                            </tbody>
                                        }
                                    </table>



                        <div className="ps__rail-x">
                            <div className="ps__thumb-x"></div>
                        </div>
                        <div className="ps__rail-y">
                            <div className="ps__thumb-y"></div>
                        </div>
                    </div>

                    {
                        loading ? (
                            <div className={'w-full justify-center items-center flex'}>
                                <img src={logoo} alt=""
                                     className={'md:w-[20%] w-[20%] loading loading-infinity mb-6 ml-10 cursor-pointer'}/>
                            </div>

                        ) : !loading && checkoutsList.every(e => e.length === 0) ? (
                            <div className={'w-full flex flex-col justify-center items-center'}>
                                <img src={logo} alt="" className={'w-[30%]'} />
                                <span className={'text-lg'}>درخواستی وجود ندارد</span>
                            </div>
                        ) : (
                            <div></div>
                        )
                    }



                    {/*{*/}
                    {/*    checkoutsList.length !== 0 && !loading  ?*/}
                    {/*        <div className={'w-full flex flex-col justify-center items-center'}>*/}
                    {/*            <img src={logo} alt="" className={'w-[30%]'}/>*/}
                    {/*            <span className={'text-lg'}>تعرفه خریداری شده ای وجود ندارد</span>*/}
                    {/*        </div> : <div></div>*/}

                    {/*}*/}
                    {/*{*/}
                    {/**/}
                    {/*checkoutsList.map(contacts => contacts.length == 0 ) && !loading ?*/}
                    {/*    <div className={'w-full flex flex-col justify-center items-center'}>*/}
                    {/*        <img src={logo} alt="" className={'w-[30%]'}/>*/}
                    {/*        <span className={'text-lg'}>تسویه حسابی وجود ندارد</span>*/}
                    {/*    </div> : <div></div>*/}
                {/*}*/}

                </div>

            }

        </div>
    )
        ;
}

export default SettlementOfAccounts