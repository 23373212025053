import React, {useEffect} from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {DefaultResponse} from "../../../redux/mainSlice";
import ShopPlan, {ShopPlanInterface, ShopPlanProperty} from "../../../models/shopPlan";
import { update } from 'redux/dashboard/shopPlans/shopPlansAction';



const ShopEditPlan: React.FC<{ plan: ShopPlan | null, onClose: (isOpen: boolean) => void }> = ({
                                                                                                            plan,
                                                                                                            onClose
}) => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading, plans} = useAppSelector((state: RootState) => state.shopPlans);

    //editing plan by template of add shop plan
    //attributes
    const [attributes, setAttributes] = React.useState<{title: string, is_read_more: boolean}[]>([]);
    const [isAddingMoreOptions, setIsAddingMoreOptions] = React.useState<boolean>(false);
    //adding

    //delete attribute
    const handleDeleteAttribute = (index: number) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes);
    }
    //add attribute
    const handleAddAttribute = (is_read_more: boolean) => {
        setAttributes([...attributes, {title: '', is_read_more}]);
    }
    //change attribute
    const handleChangeAttribute = (index: number, value: string) => {
        const newAttributes = [...attributes];
        newAttributes[index].title = value;
        setAttributes(newAttributes);
    }



    //edit
    const handleEditConfirm = async (plan: ShopPlan) => {
        // Todo: fix this error in the future
        const result: DefaultResponse = (await dispatch(update(
            {
                id: plan.id,
                name: plan.name,
                description: plan.description,
                price: plan.price,
                end_time: plan.end_time,
                properties: attributes,
                read_more_title: plan.read_more_title,
                plan_name: plan.plan_name,
            }
        ))).payload as DefaultResponse;
        if (result.status === 200 || result.status === 201) {
            onClose(true);
        }
    }

    useEffect(() => {
        if (plan) {
            const newAttributes: ShopPlanProperty[] = [];
            for (const property of plan.properties) {
                if (property.is_read_more) {
                    setIsAddingMoreOptions(true);
                }
                newAttributes.push({
                    title: property.title,
                    is_read_more: property.is_read_more
                });
            }
            setAttributes(newAttributes);
        }
    }, [plan]);


    return (
                <DefaultModal
                    label={'editPlan'}  id='editPlan' modalBoxClasses={'px-3'} isOpen={!!plan} handleIsOpen={onClose}>
                {plan ? (
                    <Formik
                        initialValues={{
                            name: plan.name,
                            id: plan.id,
                            plan_name: plan.plan_name,
                            description: plan.description,
                            price: plan.price,
                            end_time: plan.end_time,
                            properties: plan.properties,
                            read_more_title: plan.read_more_title,
                        }}
                        onSubmit={(values: ShopPlanInterface, {setSubmitting}: FormikHelpers<ShopPlanInterface>) => {
                            
                            try {
                                dispatch(
                                    handleEditConfirm({
                                        name: values.name,
                                        id: plan!.id,
                                        description: values.description,
                                        price: values.price,
                                        end_time: values.end_time,
                                        properties: values.properties,
                                        read_more_title: values.read_more_title,
                                        plan_name: values.plan_name,
                                    })
                                );
                            } catch (error) {
                            } finally {
                                setSubmitting(false); // Make sure to set submitting to false
                            }
                        }}
                        //validation
                        validate={(values: ShopPlanInterface) => {
                            const errors: any = {};
                            if (!values.name) {
                                errors.title = 'نام پلن را وارد کنید';
                            }
                            if (!values.description
                            ) {
                                errors.description = 'توضیحات را وارد کنید';
                            }
                            if (!values.price) {
                                errors.price = 'قیمت پلن را وارد کنید';
                            }
                            return errors;
                        }}>
                        <Form method="dialog">
                            <h3 className="font-bold text-lg">ویرایش {plan.name}</h3>

                            <div className='flex-col justify-start items-center mt-4 w-full flex lg:grid lg:grid-cols-2 field-container'>

                                <DefaultInput
                                    label='نام پلن'
                                    name="plan_name"
                                    type="text"
                                    placeholder='نام پلن'
                                ></DefaultInput>
                                <DefaultInput
                                    label='عنوان'
                                    name="name"
                                    type="text"
                                    placeholder='عنوان'
                                ></DefaultInput>
                                <DefaultInput
                                    label='زمان پلن(ماه)'
                                    name="end_time"
                                    type="text"
                                    placeholder='زمان پلن(ماه)'
                                ></DefaultInput>

                                <DefaultInput
                                    label='توضیحات'
                                    name="description"
                                    type="text"
                                    placeholder='توضیحات'
                                ></DefaultInput>
                                <DefaultInput
                                    label='قیمت'
                                    name="price"
                                    type="number"
                                    placeholder='قیمت'
                                ></DefaultInput>

                                {/*    attributes*/}
                                {/*    divider*/}
                                {/*    <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>*/}


                            </div>

                            <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                            <div className="w-full ">
                                <h3 className="font-bold text-lg">ویژگی ها</h3>
                                <div className="flex flex-col w-full lg:grid lg:grid-cols-2 field-container items-end">
                                    {
                                        attributes.map((attribute, index) =>
                                            attribute.is_read_more ? '' :
                                                <div key={index}
                                                     className="join">
                                                    <div className={`join-item w-full`}>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                value={attribute.title}
                                                                onChange={(e) => handleChangeAttribute(index, e.target.value)}
                                                                className="input input-bordered join-item max-w-unset w-full" placeholder="ویژگی"/>
                                                        </div>
                                                    </div>
                                                    <div className="indicator"
                                                         onClick={() => handleDeleteAttribute(index)}>
                                                        <div className="btn join-item text-white bg-[#DE0046]
                                            ">حذف</div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                    <div onClick={() => handleAddAttribute(false)}
                                         className="btn text-white bg-[#4192EF] mt-4">افزودن ویژگی
                                    </div>
                                </div>
                            </div>
                            {/*add an button to switch isAddingMoreOptionsTrue if isAddingMoreOptionsTrue is true user add more attributes and more title*/}
                            {/*button is like a link button by an svg icon cupertino arrow*/}
                            <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                            <div className="w-full flex justify-start">
                                <div className="flex items-center text-[#4192EF] cursor-pointer">
                                    <div onClick={() => setIsAddingMoreOptions(!isAddingMoreOptions)}
                                            className="">افزودن اطلاعات بیشتر
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className={`transition icon icon-tabler icon-tabler-chevron-down ml-2 ${isAddingMoreOptions ? 'transform rotate-180' : ''}`}
                                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                         fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <polyline points="6 9 12 15 18 9"/>
                                    </svg>
                                </div>
                            </div>
                            {
                                isAddingMoreOptions &&
                                <div className="w-full flex justify-start">
                                    <DefaultInput
                                        label='عنوان خواندن بیشتر'
                                        name="read_more_title"
                                        type="text"
                                        placeholder='عنوان خواندن بیشتر'
                                    ></DefaultInput>
                                </div>
                            }
                            {
                                isAddingMoreOptions &&
                                <div className="w-full ">
                                    <h3 className="font-bold text-lg">ویژگی ها</h3>
                                    <div className="flex flex-col w-full lg:grid lg:grid-cols-2 field-container items-end">
                                        {
                                            attributes.map((attribute, index) =>
                                                attribute.is_read_more ? <div key={index}
                                                                              className="join">
                                                    <div className={`join-item w-full`}>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                value={attribute.title}
                                                                onChange={(e) => handleChangeAttribute(index, e.target.value)}
                                                                className="input input-bordered join-item max-w-unset w-full" placeholder="ویژگی"/>
                                                        </div>
                                                    </div>
                                                    <div className="indicator"
                                                         onClick={() => handleDeleteAttribute(index)}>
                                                        <div className="btn join-item text-white bg-[#DE0046]
                                            ">حذف</div>
                                                    </div>
                                                </div> : ''
                                            )
                                        }
                                        <div onClick={() => handleAddAttribute(true)}
                                             className="btn text-white bg-[#4192EF] mt-4">افزودن ویژگی
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*divider*/}
                            <div className="w-full h-[1px] bg-[#e3e3e3] my-4"></div>
                            <div className="modal-action w-full flex justify-start">
                                <button type="submit"
                                        className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                    {
                                        submitLoading ? 'درحال ویرایش' : 'ویرایش'
                                    }
                                </button>
                                <div className={'w-0.5 h-2'}></div>
                                <label
                                    htmlFor="editPlan"
                                    className="btn hover:text-[#DE0046] bg-[#DE0046]  hover:bg-[#DE004633] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                </label>
                            </div>
                        </Form>
                    </Formik>
                ) : (<div></div>)}
                </DefaultModal>
    );
};

export default ShopEditPlan;
