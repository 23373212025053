import React from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {submit} from "../../../redux/dashboard/customers/customersAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {defaultModal} from "../../../utils/funcions";
import {logout} from "../../../services/connectionConfig";



interface values {
    username: string;
    f_name: string;
    l_name: string;
    description: string;
    mobile_number: string;
    email: string;
    avatar: string;
    is_from_shop: true;
}


const AddCustomerModal = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminCustomers);

    return (
        <DefaultModal id='addCustomer'>
            <Formik
                initialValues={{
                    description: '',
                    f_name: '',
                    l_name: '',
                    username: 'shop',
                    mobile_number: '',
                    avatar: '',
                    email: '',
                    is_from_shop: true,
                }}
                onSubmit={(values: values, {setSubmitting}: FormikHelpers<values>) => {

                    try {
                        dispatch(
                            submit({
                                avatar: values.avatar,
                                email: values.email,
                                mobile_number: values.mobile_number,
                                description: values.description,
                                f_name: values.f_name,
                                l_name: values.l_name,
                                username: values.username,
                                is_from_shop: values.is_from_shop,
                                is_reseller: false
                            })
                        );
                    } catch (error) {
                    } finally {
                        setSubmitting(false); // Make sure to set submitting to false
                    }
                }}
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    //only f name and l name required and f name l name username must be string and min is 3 max 60

                    if (values.f_name) {
                        if (values.f_name.length < 3) {
                            errors.f_name = 'حداقل 3 کاراکتر';
                        }
                        if (values.f_name.length > 60) {
                            errors.f_name = 'حداکثر 60 کاراکتر';
                        }
                    } else {
                        errors.f_name = 'نام الزامی است';
                    }
                    if (values.email) {
                        if (values.email == null ) {
                            errors.f_name = 'ایمیل را وارد کنید';
                        }
                    }
                    if (values.l_name) {
                        if (values.l_name.length < 3) {
                            errors.l_name = 'حداقل 3 کاراکتر';
                        }
                        if (values.l_name.length > 60) {
                            errors.l_name = 'حداکثر 60 کاراکتر';
                        }
                    } else {
                        errors.l_name = 'نام خانوادگی الزامی است';
                    }
                    // if (values.username) {
                    //     if (values.username.length < 3) {
                    //         errors.username = 'حداقل 3 کاراکتر';
                    //     }
                    //     if (values.username.length > 60) {
                    //         errors.username = 'حداکثر 60 کاراکتر';
                    //     }
                    // } else {
                    //     errors.username = 'نام کاربری الزامی است';
                    // }
                    if (values.description) {
                        if (values.description.length > 500) {
                            errors.description = 'حداکثر 500 کاراکتر';
                        }
                    }

                    return errors;
                }}>
                <Form>
                    <div className="modal-header">
                        <div className="flex flex-1">
                            <div className="flex cursor-pointer"
                                 onClick={() => {
                                     defaultModal(
                                         (
                                             <div className="flex flex-col items-center">
                                                 <h2 className="text-lg mr-4 font-bold -mt-1 text-gray-500">آیا
                                                     مطمئن هستید؟</h2>
                                                 <h2 className="text-lg mr-4 font-bold -mt-1 text-gray-500">آیا
                                                     میخواهید از حساب کاربری خود خارج شوید؟</h2>
                                             </div>
                                         ),
                                         () => logout()
                                     )
                                 }}>
                                <h2 className="text-lg mr-2 -mt-1 font-bold text-gray-500 text-[#DE0046]">ساخت کاربر
                                    جدید</h2>
                            </div>

                        </div>
                    </div>
                    <div className="modal-body mt-4">
                        <div className="flex flex-row">
                            <div>

                            </div>
                            {/*<AvatarSelect></AvatarSelect>*/}
                            <div className="px-5 w-full">
                                <div className="flex">
                                    <DefaultInput
                                        type='string'
                                        className="" name='f_name' placeholder='نام'/>
                                    <div className="w-5"></div>
                                    <DefaultInput
                                        type='string'
                                        className="" name='l_name' placeholder='نام خوانوادگی'/>
                                </div>
                                {/*<DefaultInput*/}
                                {/*    type='string'*/}
                                {/*    className="" name='username' placeholder='نام کاربری'/>*/}
                                <DefaultInput
                                    type='string'
                                    className="" name='mobile_number' placeholder='شماره تلفن'/>
                                <DefaultInput
                                    type='string'
                                    className="" name='email' placeholder='ایمیل'/>
                                <DefaultTextArea
                                    type='string'
                                    className="mb-4" name='description' placeholder='توضیحات'/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button type="submit"
                                className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            افزودن
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addCustomer"
                            className="btn  bg-[#DE0046] text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddCustomerModal;
