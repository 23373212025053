// From https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob, needed for Safari:
if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function(callback, type, quality) {
            var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], {type: type || 'image/png'}));
        }
    });
}

window.URL = window.URL || window.webkitURL;



// Helper function to get EXIF orientation (unchanged)
function getExifOrientation(file, callback) {
    if (file.slice) {
        file = file.slice(0, 131072);
    } else if (file.webkitSlice) {
        file = file.webkitSlice(0, 131072);
    }

    var reader = new FileReader();
    reader.onload = function(e) {
        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8) {
            callback(-2); // Not a JPEG
            return;
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) { // Start of EXIF data
                if (view.getUint32(offset += 2, false) != 0x45786966) {
                    callback(-1); // Not EXIF
                    return;
                }
                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                    if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                        callback(view.getUint16(offset + (i * 12) + 8, little));
                        return;
                    }
            } else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
        }
        callback(-1); // No EXIF data
    };
    reader.readAsArrayBuffer(file);
}

// Image to Canvas with Orientation and Aspect Ratio Check
function imgToCanvasWithOrientation(img, rawWidth, rawHeight, orientation) {
    var canvas = document.createElement('canvas');

    // Check if we need to swap width and height based on EXIF orientation
    if (orientation > 4) {
        canvas.width = rawHeight;
        canvas.height = rawWidth;
    } else {
        canvas.width = rawWidth;
        canvas.height = rawHeight;
    }

    var ctx = canvas.getContext('2d');
    console.log("EXIF orientation:", orientation); // Debug log to check orientation

    // Apply EXIF-based transformations
// Reverse the EXIF-based transformations
    switch (orientation) {
        case 2:
            // Flip horizontally (same operation to reverse flip)
            ctx.transform(-1, 0, 0, 1, rawWidth, 0);
            break;
        case 3:
            // Rotate 180 degrees (same operation to reverse)
            ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);
            break;
        case 4:
            // Flip vertically (same operation to reverse flip)
            ctx.transform(1, 0, 0, -1, 0, rawHeight);
            break;
        case 5:
            // Reverse Rotate 90 and flip horizontally (rotate -90 and flip horizontally)
            ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);
            break;
        case 6:
            // Reverse Rotate 90 degrees clockwise (rotate -90 degrees)
            ctx.transform(0, -1, 1, 0, 0, rawWidth);
            break;
        case 7:
            // Reverse Rotate 90 and flip vertically (rotate +90 and flip vertically)
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
        case 8:
            // Reverse Rotate 90 degrees counterclockwise (rotate +90 degrees)
            ctx.transform(0, 1, -1, 0, rawHeight, 0);
            break;
        case 1:
        default:
            // No transformation needed
            console.log("No transformation needed");
            break;
    }


    // Draw the image on the canvas
    ctx.drawImage(img, 0, 0, rawWidth, rawHeight);

    // Aspect Ratio Check: Determine if the image appears incorrectly rotated
    var finalWidth = canvas.width;
    var finalHeight = canvas.height;
    var originalRatio = img.width / img.height;
    var finalRatio = finalWidth / finalHeight;

    // If the aspect ratio of the resulting canvas is not what we expect, rotate it again
    if ((originalRatio > 1 && finalRatio < 1) || (originalRatio < 1 && finalRatio > 1)) {
        console.log("Aspect ratio mismatch detected. Rotating 90 degrees for correction.");

        // Recreate canvas with swapped dimensions
        var rotatedCanvas = document.createElement('canvas');
        rotatedCanvas.width = finalHeight;
        rotatedCanvas.height = finalWidth;
        var rotatedCtx = rotatedCanvas.getContext('2d');

        // Apply 90-degree rotation to fix the aspect ratio
        rotatedCtx.translate(finalHeight / 2, finalWidth / 2);
        rotatedCtx.rotate(Math.PI / 2);
        rotatedCtx.drawImage(canvas, -finalWidth / 2, -finalHeight / 2);

        return rotatedCanvas;
    }

    return canvas; // Return canvas as is if no correction is needed
}

// Main function to resize image with EXIF handling
export function reduceFileSize(file, acceptFileSize, maxWidth, maxHeight, quality) {
    return new Promise((resolve, reject) => {
        if (file.size <= acceptFileSize) {
            resolve(file); // If file size is already acceptable
            return;
        }

        const img = new Image();
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            reject(new Error("Image load error"));
        };

        img.onload = function () {
            URL.revokeObjectURL(this.src);

            getExifOrientation(file, (orientation) => {
                const w = img.width;
                const h = img.height;

                // Scale according to orientation
                const scale = (orientation > 4 ?
                    Math.min(maxHeight / w, maxWidth / h, 1) :
                    Math.min(maxWidth / w, maxHeight / h, 1));

                const newHeight = Math.round(h * scale);
                const newWidth = Math.round(w * scale);

                const canvas = imgToCanvasWithOrientation(img, newWidth, newHeight, orientation);

                // Convert canvas to Blob and return
                canvas.toBlob((blob) => {
                    if (blob) {
                        console.log("Resized image to " + newWidth + "x" + newHeight + ", " + (blob.size >> 10) + "kB");
                        resolve(blob);
                    } else {
                        reject(new Error("Blob creation failed"));
                    }
                }, 'image/jpeg', quality);
            });
        };

        img.src = URL.createObjectURL(file);
    });
}
