import React from "react";

export function LoadingTableShimmer() {
    return (
        <div
            className="animate-pulse text-xl font-medium text-neutral-900 dark:text-white">
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-xl bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-xl bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-xl bg-gray-300"></span>
            <div className="divider h-0"></div>
            <span
                className="inline-block min-h-[100px] w-full flex-auto cursor-wait bg-current align-middle opacity-50 rounded-xl bg-gray-300"></span>
            <div className="divider h-0"></div>
        </div>);
}
export function OneLoadingTableShimmer() {
    return (

            <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
            <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                 xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stop-color="#5555ee"/>
                        <stop offset="49%" stop-color="#4192EF"/>
                        <stop offset="70%" stop-color="#77DD77"/>
                    </linearGradient>
                    <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="0%" stop-color="#5555ee"/>
                        <stop offset="49%" stop-color="#4192EF"/>
                        <stop offset="70%" stop-color="#77DD77"/>
                    </linearGradient>
                </defs>
                <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                    <g className="ip__track" stroke="#AAA">
                        <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                        <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                    <g stroke-dasharray="180 656">
                        <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                              d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                        <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                              d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                    </g>
                </g>
            </svg>
        </div>
);
}