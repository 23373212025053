import React, {ReactNode, useState} from 'react';
import {closeModal, openModal} from 'redux/components/modalSlice';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import Header from "./header";
import {RootState} from "../../../redux/store";
import {
    AiFillSetting, AiOutlineBell,
    AiOutlineConsoleSql,
    AiOutlineContacts, AiOutlineGift,
    AiOutlineInfo, AiOutlineNotification,
    AiOutlineSetting,
    AiTwotoneContacts
} from "react-icons/ai";
import Routes from "../../../utils/routing/routes";
import {IPath} from "../../../utils/types";
import {checkUser, getSettingByKey, NetworkImage} from "../../../utils/funcions";
import Auth from "../../../services/savedData/auth";
import {signUpAsDefault} from "../../../redux/user/login/loginSlice";
import {
    IconAdjustments, IconBell,
    IconBox, IconBuildingStore, IconCategory,
    IconChartDots2, IconChartTreemap, IconCreditCardPay,
    IconDiscount2, IconDots, IconHeartHandshake, IconMailOpened, IconNotification, IconPhoneCall,
    IconSettings, IconShoppingCart,
    IconSpeakerphone,
    IconTextPlus, IconTruckDelivery, IconUser,
    IconUsers, IconWallet
} from "@tabler/icons-react";
import {Link, useLocation} from "react-router-dom";
import {IconType} from "react-icons";
import {FaAngleLeft} from "react-icons/fa";
import logo from "../../../assets/img/coloop-logo-44-min.-copy.png";
import {SiAircall} from "react-icons/si";
import {useSelector} from "react-redux";

function AppBar() {

    const {user, shop, isMerchantFilled} = useAppSelector((state: RootState) => state.user);

    const dispatch = useAppDispatch();

    const navigationItems: NavigationIItem[] = [
        /*{
            title: 'داشبورد',
            icon: AiFillAppstore,
            link: Routes.dashboard,
            itemVisibilityType: 'shop',
        },*/

        {

            title: 'اعلان ها',
            // planId: 1,
            icon: IconBell,
            link: Routes.notifications,
            spicial: true,
            itemVisibilityType: 'shop',
        },
        {
            title: 'سفارشات من',
            //
            icon: IconTruckDelivery,
            link: Routes.orders,
            itemVisibilityType: 'user',
        },
        {
            title: 'کد های تخفیف',
            icon: IconDiscount2,
            link: Routes.discountCodes,
            itemVisibilityType: 'shop',
        },
        {
            title: 'کاربران',
            //
            icon: IconUsers,
            link: Routes.shopUsers,
            itemVisibilityType: 'shop',
        },
        {
            title: 'مخاطبین',

            icon: AiOutlineContacts,
            link: Routes.contacts,
            itemVisibilityType: 'shop',
        },
        {
            title: 'ریسلر',
            icon: IconHeartHandshake,
            link: Routes.resellerInfo,
            itemVisibilityType: 'shop',
        },
        {
            title: 'تسویه حساب ها',
            //
            icon: IconWallet,
            link: Routes.settlementOfAccounts,
            itemVisibilityType: 'shop',
        },
        {
            title: 'ریفرال',
            //
            icon: IconMailOpened,
            link: Routes.referralInfo,
            itemVisibilityType: 'shop',
        },
        {
            title: 'پلن های فروشگاهی',
            //
            icon: IconMailOpened,
            link: Routes.referralInfo,
            itemVisibilityType: 'super_admin',
        },
        {
            title: 'ریسلر',
            //
            icon: IconHeartHandshake,
            link: Routes.resellerInfoUser,
            itemVisibilityType: 'user',
        },
        {
            title: 'ریفرال',

            icon: IconMailOpened,
            link: Routes.referralInfoUser,
            itemVisibilityType: 'user',
        },

        {
            title: 'تعرفه ها',

            icon: IconBox,
            link: Routes.plans,
            itemVisibilityType: 'all',
        },
        {
            title: 'تعرفه ها',

            icon: IconBox,
            link: Routes.plans,
            itemVisibilityType: 'guest',
        },

        {
            title: 'تعرفه های خریداری شده',

            icon: IconCreditCardPay,
            link: Routes.buyedPlans,
            itemVisibilityType: 'shop',
        },
        {
            title: 'پیشرفته',

            icon: IconDots,
            itemVisibilityType: 'shop',
            subItems: [
                {
                    title: 'فرم ساز',

                    icon: IconTextPlus,
                    link: Routes.formQuestions,
                    itemVisibilityType: 'shop',
                },
                {
                    title: 'افزودن کمپین',

                    icon: IconSpeakerphone,
                    link: Routes.contactsAndCustomersRoute,
                    itemVisibilityType: 'shop',
                },
            ]
        },
        {
            title: 'تماس با ما',

            icon: IconPhoneCall,
            link: Routes.contactUs,
            itemVisibilityType: 'all',
        },
        {
            title: 'درباره ما',

            icon: AiOutlineInfo,
            link: Routes.aboutUs,
            itemVisibilityType: 'all',
        },
        {
            title: 'تنظیمات',

            icon: IconSettings,
            link: Routes.editMainSettings,
            itemVisibilityType: 'shop',
        },
    ];

    const location = useLocation();
    const {pathname} = location;

    interface IItem {
        title: string,
        icon: IconType,
        link?: IPath,
        subItems?: IItem[],
        itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest' | 'super_admin',
    }
    interface NavigationIItem {
        title: string,
        icon: IconType,
        link?: IPath|string,
        type?: 'function'|'IPath'|'link',
        subItems?: IItem[],
        onClick?: () => void,
        spicial?: boolean,
        // itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest',
        isFor?: boolean
        itemVisibilityType: 'all' | 'shop' | 'user' | 'auth' | 'guest'| 'super_admin',
    }


    const [openItem, setOpenItem] = useState<number | null>(null);
    const [subMenuHeight, setSubMenuHeight] = useState<number | null>(null);

    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const handleOpenSubMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
        if (index === openItem) {
            setOpenItem(null);
            setSubMenuHeight(null);
            return;
        }
        e.preventDefault();
        setSubMenuHeight(e.currentTarget?.querySelector('.submenu')?.clientHeight ?
            (e.currentTarget?.querySelector('.submenu')?.clientHeight ?? 0) * e.currentTarget?.querySelectorAll('.submenu').length
            : null
        );
        setOpenItem(index);
    }

    let index: number|undefined = undefined;
    let subIndex: number|undefined = undefined
    // let navigationIndex: number|undefined = undefined;
    function openUserModal(bottomSheetContent: ReactNode) {
        dispatch(openModal({content: bottomSheetContent}));
    }
    // بستن مدال و ریسپانس سفارشات مونده
    function closeUserModal() {
        dispatch(closeModal);
    }

    // const userType = checkUser();

    function checkVisibility(item: IItem): boolean {

        const userType = checkUser(item.link);

        if (item.itemVisibilityType === 'all') {
            return true;
        }
        if (user!.id == 0 && item.itemVisibilityType === 'guest') {
            return userType === 'user';
        }
        // @ts-ignore
        if (user!.is_super_admin && item.itemVisibilityType === 'super_admin') {
            return userType === 'super_admin';
        }
        if (user!.id != 0 && item.itemVisibilityType === 'user') {
            return userType === 'user';
        }
        if (item.itemVisibilityType === 'shop' ) {
            return userType === 'shop';
        }
        return false;
    }
    const clode = () => {}
    const {unreadNotifications} = useSelector((state: RootState) => state.unreadNotificationsSlice);

    return (
        <div className='w-full flex justify-center transition-all'>
            <div className='w-full flex flex-col justify-center md:max-w-full'>
                <div className='w-full flex justify-center md:hidden px-8'>
                    <div className='z-[39] w-full py-1 fixed md:max-w-full px-4 bg-white'>
                        <div className='flex justify-between'>
                            <div className='flex'>
                                <label htmlFor={'my-drawer-2'}
                                       className="md:block hidden btn btn-ghost drawer-button lg:hidden px-4">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-baseline-density-medium text-black"
                                         width="24" height="24"
                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M2 16h20"></path>
                                        <path d="M2 10h20"></path>
                                        <path d="M2 4h20"></path>
                                    </svg>
                                </label>


                                <div id={'humburger'} onClick={() => {
                                    // @ts-ignore
                                    document.getElementById('my_modal_2').showModal();
                                }}
                                     className="md:hidden flex items-cetner duration-300 pt-3 px-2 drawer-button lg:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-baseline-density-medium text-black"
                                         width="24" height="24"
                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M2 16h20"></path>
                                        <path d="M2 10h20"></path>
                                        <path d="M2 4h20"></path>
                                    </svg>
                                </div>
                                <dialog id="my_modal_2"
                                        className="modal flex items-end">
                                    <div
                                        className='py-3 bg-white rounded-t-2xl w-full absolute flex flex-col items-start'>
                                        <div className={'w-full flex justify-center'}>
                                            <div className={'w-8 h-1 bg-[#818181] fixed rounded-full'}></div>
                                        </div>

                                        {
                                            navigationItems.map((item, i) => (
                                                // @ts-ignore
                                                checkVisibility(item) &&
                                                <div className={'w-full'} onClick={() => {
                                                    //@ts-ignore
                                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                                    item.subItems ? null : document.getElementById('my_modal_2')?.close();
                                                }}>
                                                    <div
                                                        className={`${i === index ? "bg-black bg-opacity-5" : ''}  border-none outline-none active:outline-none active:border-none  duration-300 flex  w-full mt-1 items-center hover:bg-black hover:bg-opacity-5 rounded-xl px-3`}
                                                        onClick={
                                                            (e) => {
                                                                clode()
                                                                handleOpenSubMenu(e, i)
                                                                if (item.onClick != null) {
                                                                    item.onClick()
                                                                }
                                                            }
                                                        }
                                                    >
                                                        {
                                                            typeof item.link === 'string' ?
                                                                <label htmlFor={item.link?.toString() || '#'}
                                                                       className={`${i === index ? "font-bold" : 'font-normal'} cursor-pointer flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                                    <div className="flex w-full ">
                                                                        <item.icon
                                                                            color={i === index ? "white" : '#121212'}
                                                                            style={i === index ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                            size={24}/>
                                                                        <span
                                                                            className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                            {item.subItems && (
                                                                                <FaAngleLeft
                                                                                    className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                                        + (openItem === i ? " transform rotate-90" : "")
                                                                                    }/>
                                                                            )}
                                    </span>
                                                                    </div>
                                                                </label>
                                                                :
                                                                <Link to={item.link?.path || '#'}
                                                                      className={`${i === index ? "font-bold" : 'font-normal'} flex flex-col !items-start py-1.5 flex items-center w-full text-md`}>
                                                                    <div className="flex w-full ">
                                                                        <item.icon
                                                                            color={i === index ? "white" : '#121212'}
                                                                            style={i === index ? {strokeWidth: "1.5"} : {strokeWidth: "1.5"}}
                                                                            size={24}/>
                                                                        <span
                                                                            className="text-black text-sm mr-4 duration-300 opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center">
                                        <span
                                            className={`${i === index ? "text-white" : 'text-[#121212]'}  hover:z-[9999]`}>{item.title}</span>
                                                                            {item.subItems && (
                                                                                <FaAngleLeft
                                                                                    className={`mr-1 ease-nav-brand text-slate-400 transition-all duration-200 ${i === index ? "text-white" : 'text-[#121212]'}`
                                                                                        + (openItem === i ? " transform rotate-90" : "")
                                                                                    }/>
                                                                            )}
                                                                            {
                                                                                item.spicial && <div className={`px-2 ${i === index ? 'bg-white text-[#4192EF]' : 'text-white bg-[#4192EF]'} w-max h-max rounded-full`}>{unreadNotifications.length}</div>
                                                                            }
                                    </span>
                                                                    </div>
                                                                    {item.subItems && (
                                                                        <ul className={`flex flex-col pl-0 mb-0 overflow-hidden w-full transition-all duration-300 ease-soft mr-4 mt-1`}
                                                                            style={{height: openItem === i ? subMenuHeight + 'px' : "2px"}}>
                                                                            {item.subItems.map((subItem, is) => (
                                                                                <li
                                                                                    key={is + item.title}
                                                                                    className="submenu flex w-full items-center">
                                                                                    {/*@ts-ignore*/}
                                                                                    <Link to={subItem.link?.path || '#'}
                                                                                          className={`${i === subIndex ? "font-semibold" : 'font-medium'} hover:bg-[#4192EF33] w-full rounded-xl flex !items-center py-1.5 flex items-center`}>
                                                                                        <subItem.icon
                                                                                            className={`${i === index ? "text-white" : 'text-[#121212]'}`}
                                                                                            style={i === subIndex ? {strokeWidth: ".5"} : {}}
                                                                                            size={24}
                                                                                        />
                                                                                        <span
                                                                                            className={`text-black text-sm mr-4 duration-300 ${i === index ? "text-white" : 'text-[#121212]'} opacity-100 pointer-events-none ease-soft flex justify-between w-full items-center`}>
                                                        {subItem.title}
                                                    </span>
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </Link>
                                                        }

                                                    </div>
                                                </div>
                                            ))
                                        }

                                        <a href={'https://coloop.ir'} target="_blank"
                                           className={'w-[98%] items-center justify-start mt-4 mr-2 flex px-2'}>

                                            <img src={logo} alt="" className={'w-[30px] cursor-pointer'}/>
                                            <div className={'flex flex-col items-start justify-center mr-1'}>
                                                <span className={'text-[13px]'}><a href="https://coloop.ir">coloop.ir</a></span>
                                                <span className={'text-[13px]'}> گروه نرم افزاری کلوپ </span>
                                            </div>
                                        </a>
                                    </div>

                                    <form method="dialog" className="modal-backdrop w-full h-full">
                                        <button>close</button>
                                    </form>
                                </dialog>

                                <span className='font-normal text-[#191919] text-[18px] mr-2 mt-2 '>
                                    {
                                        <div
                                            className={' text-[16px] mt-0.5'}>{user!.id == 0 || user!.id == undefined || user!.id == null ?
                                            <div>
                                                <label
                                                    className={'cursor-pointer text-[#4192ef] text-[15px] font-medium'} htmlFor="loginModal">ورود / ثبت نام</label>
                                        </div> : (user!.f_name + ' ' + user!.l_name)}</div>
                                    }
                                </span>
                            </div>


                            <div className='flex mt-3 ml-1'>

                                {isMerchantFilled && user!.id !== shop!.id ? <Link
                                    state={{previousLocation: location}}
                                    to={Routes.cart.path} className={'flex'}
                                >
                                    <span className={'ml-2 text-[#4192EF]'}>سبد خرید</span>
                                    <div>
                                        <IconShoppingCart strokeWidth={'1.5'} color={'#4192EF'}/>
                                    </div>
                                </Link> : ''
                                }

                                <label
                                    htmlFor={!Auth.get() ? 'loginModal' : 'profileModal'}

                                    onClick={() => {
                                        if (!Auth.get()) {
                                            return dispatch(signUpAsDefault());
                                        }
                                        setIsProfileModalOpen(true);
                                    }}
                                >
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
                <Header/>
            </div>
        </div>
    );
}

export default AppBar;