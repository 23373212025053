import API from "../services/api";
import {AxiosResponse} from "axios";

class DocRequests {

    public static async getAllDocs(): Promise<AxiosResponse> {
        return await API.authGet("uploadDocument", {isUseLoading: true});
    }

}



export default DocRequests;