import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import {CgArrowLongLeft} from "react-icons/cg";
import {calculatePercentageDifference} from "../../../utils/funcions";
import  { useLayoutEffect} from 'react';
import {ChartDataInterface} from "./AnalyticsDashboard";
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
export const ChartModalData: React.FC<{ data: ChartDataInterface, isUseDate?: boolean }> = ({data, isUseDate}) => {

    const [width, height] = useWindowSize();
    return (
        <div className="bg-[#FDFDFF] rounded-xl  w-full">
            <div
                className="p-5 flex flex-col overflow-hidden overflow-x-auto gap-5 scrollbar-thin !scrollbar-thumb-[#7851BD] scrollbar-track-[#EDEDED] rounded-xl w-full">
                  <span className="text-[#212B36] text-lg font-semibold">
                    آمار چارت
                  </span>
                <div className="flex flex-col sm:flex-row gap-5 w-full">
                    {/*{chartData.map((data, index) => (*/}
                    <div
                        className="flex flex-col gap-5 border border-[#7851BD33] p-5 rounded-xl w-full "
                        // key={index}
                    >
                        <div
                            className="flex justify-between w-full sm:min-w-[200px] items-center">
                          <span className="text-sm font-medium text-[#212B36] !capitalize">
                            {data?.name}
                          </span>

                        </div>
                        <div>
                            <ReactApexChart options={
                                {
                                    //     disabling all labels and titles only showing chart
                                    //     and its data
                                    chart: {
                                        /*toolbar: {
                                            show: false,
                                        },
                                        sparkline: {
                                            enabled: true,
                                        },*/
                                        zoom: {
                                            enabled: false,
                                        },

                                    },
                                    labels: data.labels,
                                    dataLabels: {
                                        // enabled: false,
                                    },
                                    stroke: {
                                        curve: "smooth",
                                    },
                                    colors: [data.color],
                                    yaxis: {
                                        labels: {
                                            show: width > 720,
                                            offsetX: -(data.data.reduce((a, b) => `${a}`.length > `${b}`.length ? `${a}`.length : `${b}`.length) * 7),
                                        },
                                    },
                                    grid: {
                                        // show: false,
                                    },
                                    tooltip: {
                                        // enabled: false,
                                    },
                                    legend: {
                                        // show: false,
                                    },
                                    xaxis: {

                                            // categories: [data.name],
                                            labels: {
                                                style: {
                                                    fontFamily: "iranyekan"
                                                },
                                                rotate: 0,
                                                maxHeight: 60,
                                            },

                                    },

                                    fill: {
                                        type: "gradient",
                                        gradient: {
                                            shadeIntensity: 1,
                                            opacityFrom: 0.7,
                                            opacityTo: 0.9,
                                            stops: [0, 100],
                                        },

                                    },
                                }
                            } series={
                                [
                                    {
                                        name: data.name,
                                        data: data.data,
                                    }
                                ]
                            } type="area" height={data.labels == null ? "200%" : "200%"}/>
                        </div>
                        <div className="flex justify-between flex-wrap sm:flex-nowrap">
                            <div className="flex gap-2.5">
                                <div className="p-2 rounded-xl" style={{
                                    backgroundColor: data.color,
                                }}>
                                    <data.icon
                                        color="white"
                                        className="h-5 w-5 "
                                    />
                                </div>
                                <div className="flex flex-col">
                              <span className="text-sm font-medium text-[#212B36]">
                                {data["data"][0]} این ماه
                              </span>
                                    <span className="text-xs font-medium text-[#637381]">
                                {data["data"][1]} ماه پیش
                              </span>
                                </div>
                            </div>
                            <div className="flex items-center gap-1 self-end">
                                <CgArrowLongLeft
                                    className={`text-[#45CB85] text-2xl transform rotate-[30deg] ${
                                        data["data"][0] <
                                        data["data"][1]
                                            ? "rotate-[320deg] text-red-600" : ""}`}
                                />
                                <span
                                    className={`font-inter text-xs font-medium ${
                                        data["data"][0] >
                                        data["data"][1]
                                            ? "text-[#45CB85]"
                                            : "text-[#DC3545]"
                                    }`}
                                >
                              {/*                              percentage of increase or decrease*/}
                                    {
                                        calculatePercentageDifference(
                                            data["data"][0],
                                            data["data"][1]
                                        )
                                    } %
                            </span>
                            </div>
                        </div>
                    </div>
                    {/*))}*/}
                </div>
            </div>
        </div>
    )
}