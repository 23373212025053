import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {RootState} from "redux/store";
import {useAppSelector} from "redux/hooks";
import Product from "../../../models/product";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {DeleteModal} from "../../../components/modal";
import {DefaultResponse} from "../../../redux/mainSlice";
import {MdAddShoppingCart, MdOutlineMoreHoriz} from "react-icons/md";

import {remove} from "../../../redux/dashboard/products/productsAction";
import {IconArrowRight, IconEdit, IconTrash} from "@tabler/icons-react";
import Plan from "../../../models/plan";
import {getAllPlans, removePlan} from "../../../redux/dashboard/plans/plansAction";
import EditPlan from "./edit";
import {checkoutCart} from "../../../redux/dashboard/cart/cartAction";
import {PlanCheckoutCredentials, buyPlan} from "../../../redux/dashboard/plans/buyPlanAction";
import {LoadingTableShimmer} from "../../../components/basincComponents";
import Routes from "../../../utils/routing/routes";
import {DefaultInput} from "../../../components/fieilds";
import {getAllBuyed} from "../../../redux/dashboard/plans/buyedPlansAction";
import {moneyFormat} from "../../../utils/funcions";


const PlansList = () => {
    const {plans, loading} = useAppSelector((state: RootState) => state.plans);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Plan | null>(null);
    const [manualAmount, setManualAmount] = useState<number | null>(null);
    const [editingItem, setEditingItem] = useState<Plan | null>(null);
    // const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {shop, settings, isMerchantFilled, user} = useAppSelector((state: RootState) => state.user);
    const logo = require("../../../assets/img/wave.webp");
    const location = useLocation();
    // const {pathname} = location;

    const handleDeleteClick = (plans: Plan) => {
        setSelectedItem(plans);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            // setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(removePlan(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201) {
                // setDeletedId(0);
            }
        }
    };
    // const [id, setId] = useState<number>(plans.map((e) => e.id));
    // const [description, setDescription] = useState<string>('');

    const checkoutCartValues = (id: number, manual_amount: number| null) => {
        buyPlan({id: id, price: manual_amount})
    }

    useEffect(() => {
        dispatch(getAllPlans());
    }, [dispatch]);

    // console.log('concole.log', isMerchantFilled)
    const convertPersianToEnglish = (input: string) => {
        const persianDigits = '۰۱۲۳۴۵۶۷۸۹';
        const englishDigits = '0123456789';

        return input.replace(/[۰-۹]/g, (char) => {
            return englishDigits[persianDigits.indexOf(char)];
        });
    };
    // const [manualAmount, setManualAmount] = useState('');

    const handleManualAmountChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        const englishValue = convertPersianToEnglish(value);
        //@ts-ignore
        setManualAmount(englishValue);
    };

    return (
        <>
            <Link to={Routes.products.path} className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight/></span>
                <span className={'mr-2'}>تعرفه ها</span>
            </Link>
        <DeleteModal
            title="حذف محصول"
            message="آیا از حذف این تعرفه مطمئن هستید؟"
            onSubmit={handleDeleteConfirm}
            isOpen={isModalOpen}
            handleIsOpen={setIsModalOpen}
        ></DeleteModal>
        <EditPlan
            plan={editingItem}
            onClose={(isOpen: boolean) => {
                setEditingItem(null);
            }}
        ></EditPlan>
        {
            loading ?
                    <div className={'flex justify-center items-center w-full'}>
                       <div></div>
                    </div>
            :
                <div className={'py-6 md:pr-0 pr-3 gap-y-4 mt-[60px] grid lg:pl-28 md:pl-16 pl-3 md lg:gap-3 md:gap-2 gap-1 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'}>
                    <div className="card card-compact w-full shadow-xl min-h-80 h-80 md:mt-0 mt-4">
                        <figure className={''}>
                            <img
                                alt="Winter Landscape"
                                className="object-cover transform rotate-180 w-full h-96 cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto rounded-xl"
                                src={logo}/>
                        </figure>

                        {/*<input*/}
                        {/*    value={plan.id}*/}
                        {/*    onChange={(value) => setId(parseInt(value.target.value))}*/}
                        {/*    placeholder=""*/}
                        {/*    className="input input-bordered join-item w-[10px]"/>*/}

                        <div
                            className="card-body w-full rounded-tl-[20px] rounded-b-xl absolute top-10 left-0 right-0">
                            <h2 className="card-title text-white">قیمت دستی</h2>
                            <div className={'w-full flex justify-center'}>
                                <h2 className="text-center text-white text-[14px] font-bold">خرید پلن با قیمت دستی</h2>
                                {/*<input*/}
                                {/*    value={plan.description} onChange={(value) => setDescription(value.target.value)} className={'text-center text-white text-[14px] font-bold'}>{plan.description}</input>*/}
                            </div>
                        </div>

                        <div className="card-actions absolute bottom-4 w-full px-4 justify-center">
                            <div className={'flex justify-between w-full'}>


                                <input className={'w-1/2 rounded-xl px-2'} name={'manual_amount'} type={'text'}
                                       //@ts-ignore
                                       value={manualAmount}
                                       onChange={handleManualAmountChange}
                                              placeholder={'قیمت دستی'}
                                              />
<div className={'w-1 h-1'}></div>
                                {

                                    user!.is_super_admin || !isMerchantFilled ?
                                        <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`} className="btn hover:bg-white hover:text-[#4192EF] bg-[#4192EF] flex-auto
                                 border-none text-white">تماس با ما
                                        </a> :

                                        <button onClick={() => checkoutCartValues(0, manualAmount)} className="btn hover:bg-white hover:text-[#4192EF] bg-[#4192EF] flex-auto
                                 border-none text-white">خرید
                                        </button>
                                }

                            </div>
                        </div>
                    </div>
                    {/*<Link to={Routes.products.path} className={'cursor-pointer md:hidden flex'}>*/}
                    {/*    <span><IconArrowRight/></span>*/}
                    {/*    <span className={'mr-2'}>تعرفه ها</span>*/}
                    {/*</Link>*/}
                {
                    plans.map((plan) =>
                        <div className="card card-compact w-full shadow-xl min-h-80 h-80">
                            <figure className={''}>
                                <img
                                    alt="Winter Landscape"
                                    className="object-cover transform rotate-180 w-full h-80 cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto rounded-xl"
                                    src={logo}/>
                            </figure>
                            <div
                                className="card-body w-full rounded-tl-[20px] rounded-b-xl absolute top-10 left-0 right-0">
                                <h2 className="card-title text-white">{plan.title}</h2>
                                <div className={'w-full flex justify-center'}>
                                    <h2 className="text-center text-white text-[14px] font-bold">{plan.description}</h2>
                                    {/*<input*/}
                                    {/*    value={plan.description} onChange={(value) => setDescription(value.target.value)} className={'text-center text-white text-[14px] font-bold'}>{plan.description}</input>*/}
                                </div>
                            </div>

                            <div className="card-actions absolute bottom-4 w-full px-4 justify-center">
                                <div className={'flex justify-between w-full'}>
                                    {
                                        user!.id === shop!.id ? <button onClick={() => setEditingItem(plan)}
                                                                        className=" py-2 px-3 ml-1 rounded-xl bg-[#00FF00] hover:bg-[#00FF0033] w-max border-none text-white duration-300 hover:text-[#00FF00]">
                                            <IconEdit/></button> : <div></div>
                                    }
                                    {
                                        user!.is_super_admin || !isMerchantFilled ?

                                            <a href={`tel:${settings.find(e => e.name === 'phone_number')?.value}`}
                                               className={'btn hover:bg-white hover:text-[#4192EF] bg-[#4192EF] flex-auto\n' +
                                                   '                                 border-none text-white'}>تماس با ما
                                            </a> :
                                            <button onClick={() => checkoutCartValues(plan.id, null)} className="btn hover:bg-white hover:text-[#4192EF] bg-[#4192EF] flex-auto
                                 border-none text-white">{moneyFormat(plan.price)} ریال
                                            </button>
                                    }

                                    {
                                        user!.id === shop!.id ? <button onClick={() => handleDeleteClick(plan)}
                                                                        className="py-2 px-3 mr-1 rounded-xl bg-[#DE0042] hover:bg-[#DE004233] w-max border-none text-white duration-300 hover:text-[#DE0042]">
                                            <IconTrash/></button> : <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                    {
                        loading && <div className={'main lg:pl-32 md:pl-72 mt-10 flex justify-center'}>
                            <svg className="ip" viewBox="0 0 256 128" width="256px" height="128px"
                                 xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="0%" stop-color="#5ebd3e"/>
                                        <stop offset="33%" stop-color="#ffb900"/>
                                        <stop offset="67%" stop-color="#f78200"/>
                                        <stop offset="100%" stop-color="#e23838"/>
                                    </linearGradient>
                                    <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                                        <stop offset="0%" stop-color="#e23838"/>
                                        <stop offset="33%" stop-color="#973999"/>
                                        <stop offset="67%" stop-color="#009cdf"/>
                                        <stop offset="100%" stop-color="#5ebd3e"/>
                                    </linearGradient>
                                </defs>
                                <g fill="none" stroke={'#DDD'} stroke-linecap="round" stroke-width="16">
                                    <g className="ip__track" stroke="#AAA">
                                        <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                        <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                    </g>
                                    <g stroke-dasharray="180 656">
                                        <path className="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
                                              d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                                        <path className="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
                                              d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    }
            </div>
}

{/*<div className={'px-4 py-6 grid lg:gap-3 md:gap-2 gap-1 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'}>*/}
            {/*    */}
            {/*</div>*/}
        </>
    );
};

export default PlansList;