import React, { useEffect, useRef, useState } from 'react';
import { DefaultBooleanField, DefaultInput, DefaultSelect, DefaultTextArea } from "../../../components/fieilds";
import { Field, Form, Formik, FormikHelpers } from "formik";
import DefaultModal from "../../../components/modal";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import MediaSelect from "../../../utils/media/media_select";
import { getMediaTypes, MediaType } from "../../../models/media";
import { getSettingByKey, toastError } from "../../../utils/funcions";
import {
    AttributesProperty,
    getAll as getAllProducts,
    ProductCreateCredentials,
    submit
} from "../../../redux/dashboard/products/productsAction";
import { IconArrowRight, IconMinus, IconPlus } from "@tabler/icons-react";
import { FormikProps } from "formik/dist/types";

interface values {
    title: string;
    description: string;
    price: number;
    old_price: number;
    buy_price: number;
    categoryId: number;
    highLightId: string;
    quantity: number;
    key: string;
    value: string;
    is_product_active: boolean;
    inventory_warning: boolean;
    zero_inventory?: boolean;
    // attributes: AttributesProperty[];
}

const AddProductModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { submitLoading } = useAppSelector((state: RootState) => state.adminProducts);
    const { highLights } = useAppSelector((state: RootState) => state.highlights);
    const [media, setMedia] = React.useState<File[] | null>(null);
    const [cover, setCover] = React.useState<File[] | null>(null);
    const cat = useAppSelector((state: RootState) => state.catForAddProdSlice.cat);
    const formikRef = useRef<FormikProps<any>>(null);
    const { isMerchantFilled } = useAppSelector((state: RootState) => state.user);
    const [highlightShow, setHighlightShow] = useState<boolean>(true);
    const handleChangeHighlightShow = () => {
        setHighlightShow(!highlightShow);
    };
    // let [attributes, setAttributes] = React.useState<AttributesProperty[]>([]);
    //
    // const handleAddAttribute = (e: React.MouseEvent) => {
    //     e.preventDefault(); // Prevent form submission
    //     setAttributes([...attributes, { name: '', key: '', value: '', quantity: '' }]);
    // };
    //
    // const handleDeleteAttribute = (index: number) => {
    //     const newAttributes = attributes.filter((_, i) => i !== index);
    //     setAttributes(newAttributes);
    // };
    //
    // const handleChangeAttribute = (index: number, value: string, key: string) => {
    //     const newAttributes = [...attributes];
    //     if (key === 'value') {
    //         newAttributes[index].value = value;
    //     } else if (key === 'name') {
    //         newAttributes[index].name = value;
    //     } else if (key === 'key') {
    //         newAttributes[index].key = value;
    //     }else if (key === 'quantity') {
    //         newAttributes[index].quantity = value;
    //     }
    //     setAttributes(newAttributes);
    // };

    return (
        <DefaultModal id='addProduct'
                      label={'addProduct'}
                      modalBoxClasses={`md:!max-w-3xl md:!w-max lg:min-w-31/100 md:h-max h-full overflow-x-hidden px-3`}>
            <label htmlFor={'addProduct'} className={'cursor-pointer sticky -top-6 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                <span><IconArrowRight /></span>
                <span className={'mr-2'}>افزودن</span>
            </label>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    title: '',
                    description: '',
                    price: 0,
                    old_price: 0,
                    buy_price: 0,
                    categoryId: 0,
                    quantity: 0,
                    highLightId: '',
                    key: '',
                    name: '',
                    value: '',
                    is_product_active: false,
                    inventory_warning: false,
                    zero_inventory: false,
                    // attributes: [],
                }}
                onSubmit={async (values: values) => {
                    //@ts-ignore
                    // values.attributes = JSON.stringify(attributes);
                    try {
                        const credentials: ProductCreateCredentials = {
                            mediaUrls: media!,
                            mediaTypes: getMediaTypes(media!),
                            coverUrls: cover,
                            coverTypes: 'image',
                            ...values,
                        };
                        await dispatch(submit(credentials));
                        (document.querySelector("[for='addProduct']") as HTMLElement).click();
                    } catch (error) {
                        console.error(error);
                    }
                }}
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.title) {
                        errors.title = 'عنوان محصول را وارد کنید';
                    }
                    if (!values.description) {
                        errors.description = 'توضیحات محصول را وارد کنید';
                    }
                    if (!values.quantity) {
                        errors.quantity = 'تعداد محصول را وارد کنید';
                    }
                    if (!media) {
                        toastError('لطفا یک رسانه انتخاب کنید');
                    }
                    if (media && media[0].size > 15000000) {
                        toastError('حجم عکس نباید بالای 15 مگابایت باشد');
                    }
                    return errors;
                }}>
                <div className={`md:h-max h-full w-full items-center ${media == null ? 'md:mt-0 mt-12' : 'mt-4'} flex`}>

                    <Form method="dialog" className={'w-full h-full'}>
                        <div className="flex md:flex-row justify-around flex-col">
                            <div className={`${media ? 'md:w-1/2' : 'w-full'}`}>
                                <MediaSelect mediaTypes={[MediaType.image, MediaType.video]}
                                             onFileSelect={(files: File[] | null) => {
                                                 setMedia(files);
                                             }}></MediaSelect>
                            </div>
                            {media &&
                                <div className="md:w-5"></div>}
                            {media &&
                                <div className="md:w-1/2">
                                    <div className='flex-wrap justify-start md:mt-0 mt-4 items-center w-full flex'>

                                        <DefaultInput
                                            label='عنوان محصول'
                                            name="title"
                                            type="text"
                                            placeholder='محصول'
                                        ></DefaultInput>

                                        <DefaultInput
                                            label='قیمت محصول(ریال)'
                                            name="price"
                                            type="number"
                                            placeholder='123456'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='قیمت خرید'
                                            name="buy_price"
                                            type="number"
                                            placeholder='123456'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='قیمت قبل تخفیف'
                                            name="old_price"
                                            type="number"
                                            placeholder='123456'
                                        ></DefaultInput>
                                        <DefaultInput
                                            label='تعداد محصول'
                                            name="quantity"
                                            type="number"
                                            placeholder='10'
                                        ></DefaultInput>
                                        {/*<div className={'w-full'}>*/}
                                        {/*    {attributes.map((attribute, index) => (*/}
                                        {/*        <div key={index} className="join flex w-full flex-col">*/}
                                        {/*            <div className="join-item w-full">*/}
                                        {/*                <input*/}
                                        {/*                    type="text"*/}
                                        {/*                    value={attribute.name}*/}
                                        {/*                    onChange={(e) => handleChangeAttribute(index, e.target.value, "name")}*/}
                                        {/*                    className="input input-bordered join-item max-w-unset w-full"*/}
                                        {/*                    placeholder="ویژگی" />*/}
                                        {/*                <input*/}
                                        {/*                    type="text"*/}
                                        {/*                    value={attribute.key}*/}
                                        {/*                    onChange={(e) => handleChangeAttribute(index, e.target.value, "key")}*/}
                                        {/*                    className="input input-bordered join-item max-w-unset w-full"*/}
                                        {/*                    placeholder="نوع ویژگی" />*/}
                                        {/*                <input*/}
                                        {/*                    type="number"*/}
                                        {/*                    value={attribute.quantity}*/}
                                        {/*                    onChange={(e) => handleChangeAttribute(index, e.target.value, "quantity")}*/}
                                        {/*                    className="input input-bordered join-item max-w-unset w-full"*/}
                                        {/*                    placeholder="تعداد" />*/}
                                        {/*                <input*/}
                                        {/*                    type="number"*/}
                                        {/*                    value={attribute.value}*/}
                                        {/*                    onChange={(e) => handleChangeAttribute(index, e.target.value, "value")}*/}
                                        {/*                    className="input input-bordered join-item max-w-unset w-full"*/}
                                        {/*                    placeholder="قیمت" />*/}
                                        {/*            </div>*/}
                                        {/*            <div className="rounded-xl w-full"*/}
                                        {/*                 onClick={() => handleDeleteAttribute(index)}>*/}
                                        {/*                <div className="btn w-full text-white bg-[#DE0000]">حذف</div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    ))}*/}
                                        {/*    <button onClick={handleAddAttribute}*/}
                                        {/*            className={'btn flex justify-center w-full bg-[#4192EF] py-2 hover:text-[#4192EF] text-white rounded-xl'}>*/}
                                        {/*        + مورد جدید*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}

                                        <div className={'flex w-full items-start justify-end'}>
                                            {
                                                highlightShow ? <DefaultSelect
                                                    label='برچسب های موجود'
                                                    addDiv={<div
                                                        className={'flex rounded-full cursor-pointer text-[#4192EF]'}
                                                        onClick={handleChangeHighlightShow}>
                                                        افزودن برچسب
                                                        <IconPlus color={'#4192EF'} />
                                                    </div>}
                                                    name="highLightId"
                                                    placeholder='انتخاب کنید'
                                                    className={'w-full'}
                                                    options={
                                                        highLights == null ? <div>
                                                            <div></div>
                                                        </div> : highLights.map((category) => {
                                                            return {
                                                                label: category.name,
                                                                value: category.name
                                                            }
                                                        }) as any
                                                    }
                                                ></DefaultSelect> : ''
                                            }

                                        </div>

                                        {!highlightShow ? <DefaultInput
                                            addDiv={<div
                                                className={'flex rounded-full cursor-pointer text-[#DE0046]'}
                                                onClick={handleChangeHighlightShow}>
                                                برچسب های موجود
                                                <IconMinus color={'#DE0046'} />
                                            </div>}
                                            label='عنوان برچسب'
                                            name="highLightId"
                                            type="text"
                                            placeholder='عنوان برچسب'
                                        ></DefaultInput> : ''}

                                        {!highlightShow ? <div className={`w-full flex justify-center mb-4 flex-col`}>
                                            <label htmlFor="">کاور برچسب</label>
                                            <MediaSelect mediaTypes={[MediaType.image]}
                                                         onFileSelect={(files: File[] | null) => {
                                                             setCover(files);
                                                         }}></MediaSelect>
                                        </div> : ''}

                                        <DefaultTextArea
                                            name="description"
                                            label="توضیحات محصول"
                                            placeholder="توضیحات محصول را وارد کنید"
                                        ></DefaultTextArea>

                                        <DefaultSelect
                                            label='دسته بندی'
                                            name="categoryId"
                                            placeholder='بدون دسته بندی'
                                            options={
                                                cat == null ? <div>
                                                    <div></div>
                                                </div> : cat.map((category) => {
                                                    return {
                                                        label: category.title,
                                                        value: category.id
                                                    }
                                                }) as any
                                            }
                                        ></DefaultSelect>

                                        <DefaultBooleanField defaultSelect={false} name={'is_product_active'}
                                                             placeholder={'محصول قابل فروش است'}
                                                             bool={{ yesTitle: 'بله', noTitle: 'خیر' }} />
                                        <DefaultBooleanField defaultSelect={false} name={'zero_inventory'}
                                                             placeholder={'نمایش موجودی صفر'}
                                                             bool={{ yesTitle: 'بله', noTitle: 'خیر' }} />

                                    </div>

                                    <div className="modal-action w-full flex justify-start">
                                        <button type="submit"
                                                className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                                            {
                                                submitLoading ? 'صبر کنید' : 'افزودن '
                                            }
                                        </button>
                                        <div className={'w-0.5 h-2'}></div>
                                        <label
                                            htmlFor="addProduct"
                                            className="btn bg-[#DE0046] rounded-xl text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                                        </label>
                                    </div>
                                </div>}
                        </div>
                    </Form>
                </div>
            </Formik>
        </DefaultModal>
    );
};

export default AddProductModal;
