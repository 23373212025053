import React from 'react';
import {DefaultInput, DefaultSelect, DefaultTextArea} from "../../../components/fieilds";
import {Form, Formik, FormikHelpers} from "formik";
import DefaultModal from "../../../components/modal";
import {submit} from "../../../redux/dashboard/discountCodes/discountCodesAction";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../redux/hooks";
import {RootState} from "../../../redux/store";
import {DiscountType} from "../../../models/discountCode";
import DiscountCode from "../../../models/discountCode";
import Product from "../../../models/product";
import {IconArrowRight} from "@tabler/icons-react";



interface values {
    name: string,
    code: string,
    type: DiscountType,
    amount: number,
    discountCode?: DiscountCode,
    productId?: number,
    categoryId?: number
}


const AddDiscountCodeModal = () => {

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {submitLoading} = useAppSelector((state: RootState) => state.adminDiscountCodes);
    const categories = useAppSelector((state: RootState) => state.adminCategories.categories);
    const {discountProducts} = useAppSelector((state: RootState) => state.discountProducts);
    return (
        <DefaultModal
            label={'addDiscountCode'}  id='addDiscountCode' modalBoxClasses={'px-3'}>
            <Formik
                initialValues={{
                    name: '',
                    code: '',
                    type: DiscountType.PERCENT,
                    amount: 0,
                }}
                onSubmit={
                    (values: values, {resetForm, setSubmitting}: FormikHelpers<values>) => {
                        
                        try {
                            dispatch(submit(values));
                            (document.querySelector("[for='addDiscountCode']") as HTMLElement).click();

                            resetForm();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
                //validation
                validate={(values: values) => {
                    const errors: any = {};
                    if (!values.name) {
                        errors.name = 'نام دسته بندی را وارد کنید';
                    }
                    if (!values.code) {
                        errors.code = 'کد تخفیف را وارد کنید';
                    }
                    if (!values.amount) {
                        errors.amount = 'مقدار تخفیف را وارد کنید';
                    }
                    if (!values.type) {
                        errors.type = 'نوع تخفیف را انتخاب کنید';
                    }
                    if (values.categoryId && values.productId) {
                        errors.categoryId = 'لطفا محصول یا دسته بندی را انتخاب کنید';
                        errors.productId = 'لطفا محصول یا دسته بندی را انتخاب کنید';
                    }
                    return errors;
                }}>
                <Form method="dialog">
                    <label htmlFor={'addDiscountCode'}
                           className={'cursor-pointer fixed top-0 z-[40] px-2 py-3 bg-white w-full md:hidden flex'}>
                        <span><IconArrowRight/></span>
                        <span className={'mr-2'}>افزودن</span>
                    </label>
                    <div className='flex-col justify-start items-center md:mt-4  mt-12 w-full flex'>

                        <DefaultInput
                            label='نام کد تخفیف'
                            name="name"
                            type="text"
                            placeholder='نام کد تخقیف'
                        ></DefaultInput>

                        <DefaultInput
                            label='کد تخفیف'
                            name="code"
                            type="text"
                            placeholder='کد تخفیف'
                        ></DefaultInput>

                        <DefaultSelect
                            label='نوع تخفیف'
                            name="type"
                            options={[
                                {label: 'درصدی', value: DiscountType.PERCENT},
                                {label: 'مبلغ ثابت', value: DiscountType.FIXED},
                            ]}
                        ></DefaultSelect>

                        <DefaultInput
                            label='مقدار تخفیف'
                            name="amount"
                            type="number"
                            placeholder='مقدار تخفیف'
                        ></DefaultInput>

                        <DefaultSelect
                            label='محصول'
                            name="productId"
                            options={discountProducts.map((product) => {
                                return {
                                    label: product.title,
                                    value: product.id
                                }
                            }) as any}
                        ></DefaultSelect>


                        <DefaultSelect
                            label='دسته بندی'
                            name="categoryId"
                            options={categories.map((category) => {
                                return {
                                    label: category.title,
                                    value: category.id
                                }
                            }) as any}
                        ></DefaultSelect>


                    </div>
                    <div className="modal-action w-full flex justify-start">
                        <button type="submit"
                                className={`btn text-white rounded-xl ${submitLoading ? 'bg-gray-400' : "bg-[#4192EF] hover:scale-102 hover:shadow-soft-xs active:opacity-85"}`}>
                            {
                                submitLoading ? 'صبر کنید' : 'افزودن '
                            }
                        </button>
                        <div className={'w-0.5 h-2'}></div>
                        <label
                            htmlFor="addDiscountCode"
                            className="btn bg-[#DE0046] rounded-xl text-white hover:scale-102 hover:shadow-soft-xs active:opacity-85">بستن
                        </label>
                    </div>
                </Form>
            </Formik>
        </DefaultModal>
    );
};

export default AddDiscountCodeModal;
