import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DefaultResponse, DefaultStates} from "redux/mainSlice";
import Product from "../../../models/product";
import {LaravelValidationErrorType} from "../../../utils/types";
import {changeAPaymentStatus, getOrderById} from "./orderPageAction";
import Payment from "../../../models/payment";
import {changePaymentStatus} from "../orders/orderAction";
import {toastSuccess} from "../../../utils/funcions";

interface State extends DefaultStates {
    order: Payment | null;
    submitLoading: boolean;
}

const initialState: State = {
    order: null,
    submitLoading: false,
    loading: false,
    validationErrors: null,
};

const OrderPageSlice = createSlice({
    name: 'orderPage',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getOrderById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getOrderById.fulfilled, (state, action) => {

            state.loading = false;
            const response = action.payload;
            if (response.status === 200) {
                state.order = new Payment(response.data);
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })
        builder.addCase(getOrderById.rejected, (state, action) => {

                state.loading = false;
            }
        )

        builder.addCase(changeAPaymentStatus.pending, (state) => {
            state.submitLoading = true
        })
        builder.addCase(changeAPaymentStatus.fulfilled, (state, action: PayloadAction<any>) => {
            state.submitLoading = false;
            const response = action.payload;

            if (response.status === 200) {
                state.order!.user_status = response.data.data.user_status;
                toastSuccess('وضعیت با موفقیت ویرایش شد')
            } else if (response.status === 422) {
                state.validationErrors = response.data as LaravelValidationErrorType;
            }
        })

        builder.addCase(changeAPaymentStatus.rejected, (state) => {
            state.submitLoading = false
        })
    }
});

export default OrderPageSlice.reducer;