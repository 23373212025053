import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "redux/hooks";
import store, {RootState} from "redux/store";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {remove, update} from 'redux/dashboard/discountCodes/discountCodesAction';
import {DeleteModal} from "../../../components/modal";
import DiscountCode from "../../../models/discountCode";
import {DefaultResponse} from "../../../redux/mainSlice";
import EditDiscountCodeModal from './edit';
import {copyToClipboard, emptyComponent, moneyFormat, pagination} from "../../../utils/funcions";
import AddDiscountCodeModal from "./add";
import Filter from "../filters/filter";
import {getAll} from "../../../redux/dashboard/discountCodes/discountCodesAction";
import {
    IconBoxMultiple,
    IconChevronDown, IconChevronUp,
    IconCirclePlus,
    IconDiscount2, IconFilter,
    IconPlus,
    IconSearch,
    IconX
} from "@tabler/icons-react";
import logo from "../../../assets/img/No data-pana-min.png";
import logoo from "../../../assets/img/coloop-logo-44-min.-copy.png";
import {
    setAllStatesToTrue,
    setPaginate,
    setPaginateToStart
} from "../../../redux/dashboard/discountCodes/discountCodesSlice";


const DiscountCodesList = () => {

    // const {products} = useAppSelector((state: RootState) => state.adminProducts);
    const {discountProducts} = useAppSelector((state: RootState) => state.discountProducts);

    const {categories} = useAppSelector((state: RootState) => state.adminCategories);

    const {discountCodesList, loading} = useAppSelector((state: RootState) => state.adminDiscountCodes);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DiscountCode | null>(null);
    const [editingItem, setEditingItem] = useState<DiscountCode | null>(null);
    const [deletedId, setDeletedId] = useState<number>(0);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const adminProductsState = store.getState().adminDiscountCodes;




    const handleDeleteClick = (discountCode: DiscountCode) => {
        setSelectedItem(discountCode);
        setIsModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            setDeletedId(selectedItem.id);
            setSelectedItem(null);
            setIsModalOpen(false);
            const result: DefaultResponse = (await dispatch(remove(selectedItem.id))).payload as DefaultResponse;
            if (result.status !== 200 && result.status !== 201 && result.status !== 204) {
                setDeletedId(0);
            }
        }
    };


    // const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [copy, setCopy] = useState<boolean>(true);

    const handleShowFilter = async () => {
        setCopy(!copy)
    };
    const lastSearchTimeRef = useRef(Date.now());

    const handleSearch = async () => {
        const currentTime = Date.now();
        dispatch(setPaginateToStart());
        dispatch(setAllStatesToTrue());

        if (currentTime - lastSearchTimeRef.current >= 2000) {
            const data = { title, price, product, category };

            try {
                dispatch(getAll(data));
            } catch (error) {
                alert('مشکلی پیش آمد.');
            }

            lastSearchTimeRef.current = currentTime;
        } else {
            alert('2 ثانیه یکبار اقدام به جستجو کنید.');
        }
    };

    const handleDeleteSearch = () => {
        setTitle(""); // Clear title
    };

    const handleDeleteProduct = () => {
        setProduct(""); // Clear title
    };

    const handleDeleteCategory = () => {
        setCategory(""); // Clear title
    };


    const handleDeleteSearchByPrice = () => {
        setPrice("");
    };
    const [product, setProduct] = useState('');

    const [category, setCategory] = useState('');


    useEffect(() => {
        const data = { title, price, product, category };

        const handlePagination = () => {
            if (!store.getState().adminDiscountCodes.loading &&
                !store.getState().adminDiscountCodes.loadingMore &&
                !store.getState().adminDiscountCodes.isEnd) {
                dispatch(setPaginate());
                dispatch(getAll(data));
            }
        };

        const cleanup = pagination(handlePagination);

        return () => cleanup();
    }, [title, price, product, category]);

    useEffect(() => {
        setPaginateToStart();
    }, []);

    return (
        <div className="flex-auto w-full lg:pl-28 md:pl-16 pl-3 md:pr-0 pr-3 pb-16 overflow-x-hidden">
            <DeleteModal
                title="حذف کد تخفیف"
                message="آیا از حذف این کد تخفیف مطمئن هستید؟"
                onSubmit={handleDeleteConfirm}
                isOpen={isModalOpen}
                handleIsOpen={setIsModalOpen}
            ></DeleteModal>
            <EditDiscountCodeModal
                discountCode={editingItem}
                onClose={(isOpen: boolean) => {
                    setEditingItem(null);
                }}
            ></EditDiscountCodeModal>
            <AddDiscountCodeModal/>

            <div
                className="py-4 pb-0 mb-2 mt-12 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent flex justify-between items-center">


                <div className={'flex w-full justify-between'}>
                    <span className={'flex items-center md:px-0 px-4'}>کد های تخفیف</span>
                    <div className={'flex justify-end'}>
                        <div onClick={() => handleShowFilter()}
                             className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none ml-2 p-3 rounded-xl text-white">
                            <span className='text-[15px]'>فیلتر ها</span>
                            {
                                <IconChevronDown className={`duration-300 ${copy ? 'rotate-0' : 'rotate-180'}`}/>
                            }
                        </div>
                        <label htmlFor="addDiscountCode"
                               className="btn flex w-max hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF] border-none p-3 rounded-xl text-white">
                            <span className={'text-[15px]'}>افزودن</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="icon icon-tabler icon-tabler-plus" width="24"
                                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                 fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 5l0 14"/>
                                <path d="M5 12l14 0"/>
                            </svg>
                        </label>
                    </div>
                </div>

            </div>

            <div className={'flex flex-col items-start w-full'}>
                <div
                    className={`w-full duration-300 mt-6 overflow-hidden ${copy ? 'h-0' : 'md:h-32 h-64'} grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-3`}>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder=" جستجو براساس نام و کد تخفیف "
                                value={title}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setTitle(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />
                            {title ? (
                                <button
                                    onClick={handleDeleteSearch}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <input
                                type="search"
                                placeholder="جستجو براساس مقدار تخفیف"
                                value={price}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                onChange={(e) => setPrice(e.target.value)}
                                className="input placeholder:text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none rounded-xl"
                            />
                            {price ? (
                                <button
                                    onClick={handleDeleteSearchByPrice}
                                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX/>
                                </button>
                            ) : (
                                <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            )}
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={product}
                                onChange={(e) => setProduct(e.target.value)}
                                className="input placeholder:text-[14px] text-[14px] border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            >
                                <option className={'text-[14px]'} value="">جستجو براساس محصول</option>
                                {discountProducts.map((e) => (
                                    <option className={'text-[14px]'} key={e.id} value={e.id}>{e.title}</option>
                                ))}
                            </select>
                            {
                                product ? <button
                                    onClick={handleDeleteProduct}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX size={'24'}/>
                                </button> : <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            }
                        </div>
                    </label>
                    <label className="input input-bordered rounded-xl flex items-center px-0 p-1">
                        <div className="relative w-full">
                            <select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="input placeholder:text-[14px] text-[14px]  border-none input-primary  max-h-[20px] min-h-[20px] w-full outline-none focus:outline-none"
                            >
                                <option className={'text-[14px]'} value="">جستجو براساس دسته بندی</option>
                                {
                                    categories.map((e) => <option className={'text-[14px]'} key={e.id} value={e.id}>{e.title}</option>)
                                }
                            </select>
                            {
                                category ? <button
                                    onClick={handleDeleteCategory}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconX size={'24'}/>
                                </button> : <button
                                    onClick={handleSearch}
                                    className="absolute left-2 top-1/2 bg-white transform -translate-y-1/2 text-gray-500"
                                >
                                    <IconSearch size={'24'}/>
                                </button>
                            }
                        </div>
                    </label>
                    {/* Add more labels here as needed */}
                </div>

                <label className={`${copy ? 'h-0' : 'h-16'} flex items-center w-max`}>
                    <button onClick={handleSearch}
                            className={`w-full ${copy ? 'h-0 px-20' : 'py-3 h-12'} w-max px-12 group flex justify-center duration-300 rounded-xl hover:bg-[#4192EF33] bg-[#4192EF] text-white hover:text-[#4192EF]`}>
                        <IconFilter
                            className={'group-hover:w-6 duration-300 w-0'}/>
                        <span className={`mr-2 ${copy ? 'duration-300 hidden' : 'block'}`}>اعمال فیلتر</span>

                    </button>
                </label>
            </div>


            <div className={'overflow-x-auto'}>
                <table
                    className="items-center w-full mt-[36px] align-top border-gray-200 text-slate-500">
                    <thead className="align-bottom">
                    <tr>
                        {/*<th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">#</th>*/}
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نام
                            کد تخفیف
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">کد
                            تخفیف
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">نوع
                            تخفیف
                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">مقدار
                            تخفیف

                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">محصول

                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">دسته
                            بندی

                        </th>
                        <th className="px-6 py-3 pl-2 font-bold text-start uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">وضعیت</th>
                    </tr>
                    </thead>
                    {(
                            <tbody className={''}>
                            {
                                discountCodesList.map(discountCodes => discountCodes.map((e, i) =>

                                        <tr key={e.id.toString()}
                                            className={`transition-all mt-1 hover:bg-neutral-100 ${i % 2 === 1 ? "bg-[#4192EF15]" : "bg-white"}`}>
                                            {/*<td className="px-6 py-4 pl-2 font-bold text-start align-middle  bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{i + 1}</td>*/}
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.name}</td>
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.code}</td>
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.type === 'percent' ? 'درصدی' : 'مبلغی'}</td>
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.type === 'percent' ? (
                                                <span className="mr-2">{e.amount}%</span>
                                            ) : (
                                                <span>
                                    {moneyFormat(e.amount)}
                                                    <span className="text-[12px] mr-2">تومان</span>
                                </span>
                                            )}</td>
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.product?.title}</td>
                                            <td className="px-6 py-4 pl-2 font-bold text-start align-middle bg-transparent border-b border-gray-200 shadow-none text-xs border-b-solid tracking-none whitespace-nowrap text-slate-500 opacity-80">{e.category?.title}</td>


                                            <td className="px-6 py-4 flex font-bold text-start capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap">
                                                <div onClick={() => handleDeleteClick(e)}
                                                     className={'p-2 cursor-pointer group bg-[#DE004633] hover:bg-[#DE0046] duration-300 hover:text-white text-[#DE0046] h-10 rounded-xl flex justify-center items-center'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon icon-tabler icon-tabler-trash" width="24"
                                                         height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M4 7l16 0"/>
                                                        <path d="M10 11l0 6"/>
                                                        <path d="M14 11l0 6"/>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                                    </svg>
                                                    {/*<span className={'group-hover:w-8 w-0 text-transparent group-hover:text-[#DE0046] duration-300'}>حذف</span>*/}
                                                </div>

                                                <div className={'h-2 w-2'}>

                                                </div>
                                                <div onClick={(r) => {
                                                    r.stopPropagation();
                                                    // alert('متن کپی شد.')
                                                    copyToClipboard(e.code);
                                                }}
                                                     className={'duration-300 cursor-pointer bg-[#4192EF33] hover:bg-[#4192EF]  hover:text-white text-[#4192EF] w-10 h-10 flex justify-center items-center rounded-xl'}>
                                                    <IconBoxMultiple/>
                                                </div>
                                                <div className={'h-2 w-2'}>

                                                </div>
                                                <div onClick={() => setEditingItem(e)}
                                                     className={'duration-300 cursor-pointer bg-[#00FF0033] hover:bg-[#00FF00]  hover:text-white text-[#00FF00] w-10 h-10 flex justify-center items-center rounded-xl'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="group-hover:flex  icon icon-tabler icon-tabler-edit"
                                                         width="33" height="33" viewBox="0 0 24 24" strokeWidth="1.5"
                                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"/>
                                                        <path
                                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"/>
                                                        <path d="M16 5l3 3"/>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                ))
                            }


                            </tbody>
                        )

                    }
                </table>
                {
                    loading && <div className={'w-full justify-center items-center flex'}>
                        <img src={logoo} alt=""
                             className={'md:w-[20%] w-[20%] loading loading-infinity mb-6 ml-10 cursor-pointer'}/>
                    </div>
                }
                {
                    discountCodesList.every(e => e.length === 0) && !loading ?
                        <div className={'w-full flex flex-col justify-center items-center'}>
                            <img src={logo} alt="" className={'w-[30%]'}/>
                            <span className={'text-lg'}>کد تخفیفی وجود ندارد</span>
                        </div> : <div></div>
                }
            </div>
        </div>
    );
};

export default DiscountCodesList;
